import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import {default as MuiAppBar} from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import {default as MuiDrawer} from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {styled} from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import React, {useMemo, useState} from 'react';
import {withRouter} from 'react-router';
import {NavLink} from 'react-router-dom';
import TokenStorage from 'util/TokenStorage';
import NavbarAdminPortal from '../../pages/AdminV2/Navbar';
import {APP_STORAGE, ORG_TYPE_CONSTANTS} from '../../util/app-constants';
import {
  AdminMenuItems,
  CollegeMenuItems,
  RecruiterMenuItems,
} from './sideNavMenuItems';
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const drawerWidth = 330;

const LightTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const SubMenuItem = styled(ListItem)(({theme}) => ({
  padding: '0 16px',
}));

const BoldListItemText = styled(ListItemText)(({theme}) => ({
  '& .MuiTypography-root': {
    fontWeight: 600,
  },
}));

const MainListItemText = styled(ListItemText)(({theme}) => ({
  '& .MuiTypography-root': {
    fontSize: 16,
    fontFamily: 'Open Sans',
    fontWeight: 'inherit',
    lineHeight: '140%'
  },
}));

const SubMenuItemText = styled(ListItemText)(({theme}) => ({
  '& .MuiTypography-root': {
    fontSize: 12,
    fontFamily: 'Open Sans',
    fontWeight: 'inherit',
    lineHeight: '120%'
  },
}));

const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const ORG_TYPE_ROUTE_MAP = {
  [ORG_TYPE_CONSTANTS.COLLEGE]: CollegeMenuItems,
  [ORG_TYPE_CONSTANTS.COMPANY]: RecruiterMenuItems,
  'ADMIN': AdminMenuItems
};

const SideNavBar = ({children}) => {
  const [open, setOpen] = React.useState(false);
  const orgType = TokenStorage.getItem(APP_STORAGE.ORG_TYPE) || 'ADMIN';

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const menuItems = useMemo(() => ORG_TYPE_ROUTE_MAP[orgType] || [], [orgType]);

  return (
      <Box sx={{display: 'flex'}}>
        <CssBaseline/>
        <AppBar position="fixed" open={open} style={{width: '100%'}}>
          <Toolbar style={{backgroundColor: 'white', color: 'black'}}>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(open && {display: 'none'}),
                }}
            >
              <MenuIcon/>
            </IconButton>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(!open && {display: 'none'}),
                }}
                onClick={handleDrawerClose}
            >
              <CloseIcon/>
            </IconButton>
            <NavbarAdminPortal/>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader/>
          <List>
            {menuItems.map((item = {}) => {
              const {subItems = [], path} = item;
              return (
                  <div key={path}>
                    <ListMenuItem item={item} subItems={subItems} open={open}/>
                  </div>
              );
            })}
          </List>
        </Drawer>
        <Box
            component="main"
            sx={{flexGrow: 1, p: 0}}
            style={{background: '#FAFCFE'}}
        >
          <DrawerHeader/>
          {children}
        </Box>
      </Box>
  );
};

const ListMenuItem = ({item, subItems = [], open}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleClick = (e) => {
    if (open && subItems && subItems?.length > 0) {
      e.preventDefault();
    }
    setMenuOpen(!menuOpen);
  };

  return <>
    <ListItem
        component={NavLink}
        onClick={handleClick}
        to={item.path}
        style={(isActive) => {
          return {
            background: 'transparent',
            color: isActive ? '#4AA571' : '#4A4A68',
            fontWeight: isActive? 700 : 400
          };
        }}
    >
      <>
        <LightTooltip title={item.text} placement="right">
          <ListItemIcon
              style={{
                background: 'transparent',
                color: 'inherit',
              }}
          >
            {item.icon}
          </ListItemIcon>
        </LightTooltip>
        <MainListItemText primary={item.text}/>
        {Array.isArray(subItems) && subItems.length > 0 && <>{menuOpen ? <ExpandLess/> : <ExpandMore/>}</>}
      </>
    </ListItem>
    {open && subItems?.map((subItem) => <ListSubMenuItem subItem={subItem} key={subItem.path} open={menuOpen}/>)}
  </>;
};

const ListSubMenuItem = ({subItem, open}) => {
  return <Collapse in={open} timeout="auto" unmountOnExit>
    <SubMenuItem
        disablePadding
        component={NavLink}
        to={subItem.path}
        key={subItem.text}
        style={(isActive) => {
          return {
            background: 'transparent',
            color: isActive ? '#4AA571' : '#4A4A68',
            fontWeight: isActive? 700 : 400,
            marginLeft: 40,
            marginBottom: 8,
          };
        }}
    >
      <SubMenuItemText primary={subItem.text} sx={{pl: 4}}/>
    </SubMenuItem>
  </Collapse>;
};

export default withRouter(SideNavBar);
