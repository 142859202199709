import React from 'react';
import Loader from 'react-loader-spinner';
import Resources from 'assets/Resources';
import StyleConstant from 'util/style-constants';
import { IoIosRefresh, IoMdArrowBack } from 'react-icons/io';

import ButtonStyle, {
  SecondaryButtonStyle,
  IconButtonWrapper,
  LoaderButtonStyle,
  BackBtnStyleWrapper,
} from './style';

const PrimaryButton = ({
  text,
  onClickHandler,
  isDisabled,
  isLoading,
  padding,
  width,
  height,
  color,
  noBorder,
  margin,
  bgColor,
  border,
  type,
  loaderColor,
  ...otherProps
}) => {
  return (
    <ButtonStyle
      padding={padding}
      margin={margin}
      width={width}
      height={height}
      disabled={isDisabled || isLoading}
      onClick={onClickHandler}
      color={color}
      noBorder={noBorder}
      bgColor={bgColor}
      border={border}
      type={type || 'button'}
      {...otherProps}
    >
      {!isLoading && <span>{text}</span>}
      {isLoading && (
        <Loader
          type="ThreeDots"
          height={10}
          width={30}
          color={loaderColor || StyleConstant.WHITE}
        />
      )}
    </ButtonStyle>
  );
};

export const SecondaryButton = ({
  text,
  padding,
  onClickHandler,
  width,
  height,
  backgroundColor,
  color,
  border,
  isDisabled,
  isLoading,
  fontSize,
  fontWeight,
  loaderColor,
  margin,
}) => {
  return (
    <SecondaryButtonStyle
      padding={padding}
      width={width}
      height={height}
      backgroundColor={backgroundColor}
      color={color}
      border={border}
      onClick={onClickHandler}
      fontSize={fontSize}
      fontWeight={fontWeight}
      disabled={isDisabled || isLoading}
      margin={margin}
    >
      {!isLoading && <span>{text}</span>}
      {isLoading && (
        <Loader
          type="ThreeDots"
          height={20}
          width={30}
          color={loaderColor || StyleConstant.WHITE}
        />
      )}
    </SecondaryButtonStyle>
  );
};
export const LoaderButton = ({
  text,
  padding,
  onClickHandler,
  width,
  height,
  backgroundColor,
  color,
  border,
  isDisabled,
  isLoading,
  fontSize,
  fontWeight,
  lineHeight,
}) => {
  return (
    <LoaderButtonStyle
      padding={padding}
      width={width}
      height={height}
      backgroundColor={backgroundColor}
      color={color}
      border={border}
      onClick={onClickHandler}
      disabled={isDisabled}
      isLoading={isLoading}
      fontSize={fontSize}
      fontWeight={fontWeight}
      lineHeight={lineHeight}
    >
      <span className="loader-btn-text">{text}</span>{' '}
      {isLoading && (
        <span>
          <Loader type="ThreeDots" height={20} width={20} color={StyleConstant.WHITE} />
        </span>
      )}
    </LoaderButtonStyle>
  );
};
export const IconButton = ({
  text,
  padding,
  onClickHandler,
  width,
  height,
  backgroundColor,
  color,
  border,
  isDisabled,
  type,
}) => {
  return (
    <IconButtonWrapper
      padding={padding}
      width={width}
      height={height}
      backgroundColor={backgroundColor}
      color={color}
      border={border}
      onClick={onClickHandler}
      disabled={isDisabled}
    >
      <div className="layout-row layout-align-center-center btn_gap">
        {type ? (
          <span className="custom-icon">
            <IoIosRefresh />
          </span>
        ) : (
          <span>
            <img src={Resources.images.sfl} className="plus-img" alt="" />
          </span>
        )}
        <span className="text">{text}</span>
      </div>
    </IconButtonWrapper>
  );
};

export const BackButton = ({ onClickHandler }) => {
  return (
    <BackBtnStyleWrapper type="button" onClick={onClickHandler}>
      <IoMdArrowBack />
    </BackBtnStyleWrapper>
  );
};

export default PrimaryButton;
