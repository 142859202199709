import styled from "styled-components";

const PageLoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${props => (props.pageHeight ? props.pageHeight : "100%")};
`;

export default PageLoaderWrapper;
