import {
  CANDIDATE_VIDEO_RESPONSE,
  CANDIDATES_LIST_REQUEST,
  CANDIDATES_LIST_SUCCESS,
  UPDATE_APPLICANT_STATUS_REQUEST,
  UPDATE_APPLICANT_STATUS_SUCCESS,
} from "./actions";

const initialState = {
  isFetching: false,
  isLoadingMore: false,
  candidates: [],
  pageNumber: 1,
  pageSize: 20,
  totalItems: 0,
  totalPages: 0,
  didInvalidate: false,
  showVideoResponse: null,
  applicantsToReject: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload = {} } = action;
  switch (type) {
    case CANDIDATE_VIDEO_RESPONSE:
      return {
        ...state,
        isFetching: false,
        showVideoResponse: payload,
      };

    case CANDIDATES_LIST_REQUEST:
      return {
        ...state,
        params: action.params || {},
        isFetching: !action.isLoadingMore,
        isLoadingMore: action.isLoadingMore,
        didInvalidate: false,
      };
    case CANDIDATES_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isLoadingMore: action.isLoadingMore,
        candidates: action.isLoadingMore
          ? state.candidates.concat(action.payload.data || [])
          : payload.data || [],
        pageNumber: payload.pageNumber,
        pageSize: payload.pageSize,
        totalItems: payload.totalItems,
        totalPages: payload.totalPages,
        didInvalidate: false,
      };

    case UPDATE_APPLICANT_STATUS_REQUEST:
      const applicantId = action.payload;
      let applicantsToReject = [...state.applicantsToReject];

      const idx = applicantsToReject.indexOf(applicantId);

      if (idx !== -1) {
        applicantsToReject.splice(idx, 1);
      } else {
        applicantsToReject.push(applicantId);
      }

      return {
        ...state,
        applicantsToReject,
      };

    case UPDATE_APPLICANT_STATUS_SUCCESS:
      const {
        applicantsToReject: applicantIds = [],
        newStatus,
        feedback = "",
        rejectionReason = "",
      } = action.payload;

      let candidates = [...state.candidates];
      let rejectionReasonArray = rejectionReason.split("_");
      let rejectionReasonText = rejectionReasonArray.join(" ");

      candidates = candidates.map((candidate) =>
        applicantIds.includes(candidate.userDTO.userId)
          ? {
              ...candidate,
              status: newStatus,
              statusText: newStatus.replaceAll("_", " ").toLowerCase(),
              feedback,
              rejectionReasonText: rejectionReasonText,
            }
          : candidate
      );

      return {
        ...state,
        candidates,
        applicantsToReject: [],
      };

    default:
      return state;
  }
};

export default reducer;
