import USER_FIELDS from './action-types';

const initState = {
  loadingJobRoles: false,
  loadingJobFunctions: false,
  loadingCities: false,
  loadingMicroCv: false,
  loadingLmsUserProfile: false,
  loadingBatchDetail: false,
  jobRolesArr: [],
  jobFunctionsArr: [],
  citiesArr: [],
  microCvData: {},
  lmsUserProfile: {},
  batchDetail: {},
  profileStrength: {
    totalWorkExp: null,
  },
  isButtonLoading: false,
};

const userFieldsReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case USER_FIELDS.USER_FIELDS_CITIES_LOADING:
    case USER_FIELDS.USER_FIELDS_JOB_FUNCTIONS_LOADING:
    case USER_FIELDS.USER_FIELDS_JOB_ROLE_LOADING:
    case USER_FIELDS.USER_FIELDS_LOADING_MICRO_CV:
    case USER_FIELDS.USER_FIELDS_LOADING_LMS_USER_PROFILE:
    case USER_FIELDS.USER_FIELDS_LOADING_BATCH_DETAILS:
      return { ...state, [payload.key]: payload.value };

    case USER_FIELDS.USER_FIELDS_SET_DATA:
      return { ...state, [payload.key]: [...payload.content] };

    case USER_FIELDS.USER_FIELDS_SET_OBJ_PAYLOAD: {
      return {
        ...state,
        [payload.key]: { ...payload.content },
      };
    }

    case USER_FIELDS.USER_FIELDS_STRENGTH: {
      return {
        ...state,
        profileStrength: { totalWorkExp: null, ...payload },
      };
    }

    case USER_FIELDS.BUTTON_LOADING: {
      return {
        ...state,
        isButtonLoading: payload,
      };
    }

    case USER_FIELDS.USER_FIELDS_SET_BATCH_DETAIL: {
      return {
        ...state,
        batchDetail: { ...state.batchDetail, ...payload },
      };
    }

    case USER_FIELDS.USER_FIELDS_RESET_STATE: {
      return {
        ...initState,
      };
    }

    default: {
      return state;
    }
  }
};

export default userFieldsReducer;
