/* eslint-disable guard-for-in */
/* eslint-disable no-undef */
import AWS from 'aws-sdk';
import Axios from 'axios';
import { convertFromRaw, EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import * as _ from 'lodash';
import services from '../services';
import { B2B_API } from './api-constants';
import {
  ADMIN_LINKS,
  API_INFO,
  APP_STORAGE,
  CANDIDATE_STATUS_LIST,
  COMPETENCY_LEVEL_LIST,
  FILTERS,
  MANDATORY_SKILL_LIST,
  ORG_TYPE_CONSTANTS,
  PAYMENT_CHECKOUT,
  REDIRECT_CONSTANTS,
  STRINGS,
  TAGS,
} from './app-constants';
import SessionStorage from './SessionStorage';
import showToastMsg from './show-toasts';
import THEME from './theme';
import TokenStorage from './TokenStorage';

const queryString = require('query-string');
const moment = require('moment');
const mime = require('mime-types');

export const isDev = () => process.env.NODE_ENV === 'development';
export const getHost = () => {
  if (isDev()) {
    //return "yenepoya.seekho.ai" //for production
    return 'techno.stagseekho.com'; //for local
  }
  return (
    window.location.hostname || /:\/\/([^\/]+)/.exec(window.location.href)[1]
  );
};

export const hasSubDomain = () => {
  const domain = getHost();
  const subDomainPos = isDev() ? 1 : 2;
  return domain.split('.').length > subDomainPos;
};

export const getDomainName = () => {
  const domain = getHost();
  const isSubDomain = hasSubDomain();
  const subDomain = domain.split('.')[0];
  if (isSubDomain) {
    return subDomain;
  }
  return '';
};

export const getPathName = () => {
  return window.location.pathname + window.location.search;
};

export const numFormatter = (value) => {
  const num = value.match(/\d+/g).map(Number);
  let finalString = '';
  if (num.length <= 0) return value;
  num.forEach((item, i) => {
    if (item >= 100000) {
      finalString += (item / 100000).toPrecision(2);
    }
    if (num.length > 1 && i + 1 != num.length) finalString += '-';
  });
  return `${finalString} LPA`;
};

export const yearFormatter = (value) => {
  const num = value.match(/\d+/g).map(Number);
  let finalString = '';
  if (num.length <= 0) return value;
  num.forEach((item, i) => {
    finalString += item;
    if (num.length > 1 && i + 1 != num.length) finalString += '-';
  });
  return `${finalString} Year(s)`;
};

const customStyles = {
  container: (base, state) => ({
    ...base,
    width: state.selectProps.width ? state.selectProps.width : '100%',
    boxShadow: 'none',
  }),
  control: (base, state) => ({
    ...base,
    background: `${
      state.selectProps.bgColor ? state.selectProps.bgColor : '#f5f6fa'
    }`,
    boxShadow: 'none',
    height: state.selectProps.height ? state.selectProps.height : '60px',
    width: state.selectProps.width ? state.selectProps.width : '100%',
    textTransform: 'capitalize',
    borderRadius: state.selectProps.borderRadius
      ? state.selectProps.borderRadius
      : '6px',
  }),

  placeholder: (base, state) => ({
    ...base,
    color: `${THEME.secondaryColor}`,
    opacity: `1`,
    letterSpacing: 0,
    lineHeight: '22px',
  }),
  menu: (base, state) => ({
    ...base,
    maxHeight: state.selectProps.menuHeight
      ? state.selectProps.menuHeight
      : '250px',
    zIndex: 99,
    width: state.selectProps.width ? state.selectProps.width : '100%',
  }),
  menuList: (base, state) => ({
    ...base,
    maxHeight: state.selectProps.menuHeight
      ? state.selectProps.menuHeight
      : '250px',
  }),
  input: (base, state) => ({
    ...base,
    paddingLeft: '0px',
    lineHeight: '23px',
    verticalAlign: 'center',
    textTransform: 'capitalize',
  }),

  option: (styles, state) => ({
    ...styles,
    color:
      state.isSelected || state.isFocused
        ? `${THEME.primaryColor}`
        : `${THEME.secondaryColor}`,
    backgroundColor:
      state.isSelected || state.isFocused ? `${THEME.bgColor}` : '#fff',
    textTransform: 'capitalize',
    lineHeight: state.selectProps.lineHeight
      ? state.selectProps.lineHeight
      : 'none',
    fontSize: state.selectProps.fontSize
      ? state.selectProps.fontSize
      : '0.8rem',
    margin: state.selectProps.margin ? state.selectProps.margin : '0px',
    cursor: 'pointer',
  }),
};

export const singleSelectStyle = {
  container: (base, state) => ({
    ...base,
    width: state.selectProps.width ? state.selectProps.width : '100%',
    boxShadow: 'none',
  }),
  control: (base, state) => ({
    ...base,
    background: `${
      state.selectProps.bgColor ? state.selectProps.bgColor : '#f5f6fa'
    }`,
    boxShadow: 'none',
    height: state.selectProps.height ? state.selectProps.height : '30px',
    width: state.selectProps.width ? state.selectProps.width : '100%',
    textTransform: 'capitalize',
    borderRadius: state.selectProps.borderRadius
      ? state.selectProps.borderRadius
      : '6px',
  }),

  placeholder: (base, state) => ({
    ...base,
    color: `${THEME.secondaryColor}`,
    opacity: `1`,
    letterSpacing: 0,
  }),
  menu: (base, state) => ({
    ...base,
    maxHeight: state.selectProps.menuHeight ? state.selectProps.menuHeight : ``,
    zIndex: 99,
    width: state.selectProps.width ? state.selectProps.width : '100%',
  }),
  menuList: (base, state) => ({
    ...base,
    maxHeight: state.selectProps.menuHeight ? state.selectProps.menuHeight : ``,
  }),
  input: (base, state) => ({
    ...base,
    paddingLeft: '0px',
    lineHeight: '23px',
    verticalAlign: 'center',
    textTransform: 'capitalize',
  }),

  option: (styles, state) => ({
    ...styles,
    color:
      state.isSelected || state.isFocused
        ? `${THEME.primaryColor}`
        : `${THEME.secondaryColor}`,
    backgroundColor:
      state.isSelected || state.isFocused ? `${THEME.bgColor}` : '#fff',
    textTransform: 'capitalize',
    lineHeight: state.selectProps.lineHeight
      ? state.selectProps.lineHeight
      : 'none',
    fontSize: state.selectProps.fontSize
      ? state.selectProps.fontSize
      : '0.8rem',
    margin: state.selectProps.margin ? state.selectProps.margin : '0px',
    cursor: 'pointer',
    fontWeight: state.isSelected ? 'bold' : 'normal',
  }),
};
export const newInputStyles = {
  container: (styles, state) => ({
    ...styles,
    width: '100%',
    boxShadow: 'none',
    fontSize: '14px',
  }),
  control: (styles, state) => {
    return {
      ...styles,
      backgroundColor: 'white',
      width: '100%',
      height: '48px',
      boxShadow: 'none',
      outline: 'none',
      borderRadius: '4px',
      border: state.selectProps.hasError
        ? '1px solid red'
        : '1px solid #9C9CB1',
      '&:hover': {
        border: state.selectProps.hasError
          ? '1px solid red'
          : '1px solid #9C9CB1',
      },
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      boxShadow: '0px 4px 8px 2px rgba(0, 0, 0, 0.07)',
    };
  },
  menuList: (styles) => {
    return {
      ...styles,
      borderRadius: '0px 0px 8px 8px',
    };
  },
  option: (styles, { isDisabled, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? '#9C9CB1' : '#fff',
      fontSize: '14px',
      cursor: isDisabled ? 'not-allowed' : 'default',
      color: `${THEME.primaryColor}`,
    };
  },
  input: (styles) => ({ ...styles, fontSize: '14px' }),
  placeholder: (styles) => ({ ...styles, fontSize: '14px' }),
  singleValue: (styles, { data }) => ({ ...styles }),
  indicatorSeparator: (styles, state) => {
    return {
      ...styles,
      display: 'none',
    };
  },
  indicatorsContainer: (styles, state) => {
    return {
      ...styles,
    };
  },
  multiValue: (styles) => {
    return {
      ...styles,
      borderRadius: 8,
      backgroundColor: '#6A6A86',
      padding: '8px 5px',
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    fontSize: 14,
    color: '#ffffff',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: '#ffffff',
    cursor: 'pointer',
    svg: {
      width: 18,
      height: 18,
    },
    ':hover': {
      color: 'white',
    },
  }),
};

export const newInputStylesFilter = {
  ...newInputStyles,
  container: (styles, state) => ({
    ...styles,
    width: '100%',
    boxShadow: 'none',
    fontSize: '14px',
  }),
  control: (styles, state) => {
    return {
      ...styles,
      backgroundColor: '#ECF1F4',
      width: '100%',
      height: '48px',
      boxShadow: 'none',
      outline: 'none',
      borderRadius: '8px',
      zIndex: 10,
      border: state.selectProps.hasError
        ? '1px solid red'
        : '1px solid #8C8CA1',
      '&:hover': {
        border: state.selectProps.hasError
          ? '1px solid red'
          : '1px solid #8C8CA1',
      },
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      marginTop: -8,
      marginBottom: -8,
      borderRadius: '0px 0px 8px 8px',
      boxShadow: '0px 4px 8px 2px rgba(0, 0, 0, 0.07)',
    };
  },
};

const getCurrentAndPreviousFilters = (props, prevProps) => {
  const {
    adminHome: { checkedItems },
  } = props;
  let active = null;
  let inactive = null;
  let prevActive = null;
  let prevInactive = null;
  if (prevProps) {
    const {
      adminHome: { checkedItems: prevItems },
    } = prevProps;
    prevActive = prevItems.get('active');
    prevInactive = prevItems.get('inactive');
  }

  active = checkedItems.get('active');
  inactive = checkedItems.get('inactive');

  return [active, inactive, prevActive, prevInactive];
};

const getLocalDateFromUTC = (date) => {
  try {
    const gmtDateTime = moment.utc(date, 'YYYY-MM-DDTHH:mm a');
    const local = gmtDateTime.local().format('DD-MM-YYYY hh:mm a');
    return local;
  } catch (e) {
    return moment(date).format('DD-MM-YYYY hh:mm a');
  }
};

const getEntityStatusByFilters = (filter) => {
  switch (filter) {
    case FILTERS.InACTIVE:
      return ['INACTIVE'];
    case FILTERS.ALL:
      return ['ACTIVE', 'INACTIVE'];

    default:
      return ['ACTIVE'];
  }
};
export const getEntityStatusByFiltersForGetRequest = (filter) => {
  switch (filter) {
    case FILTERS.InACTIVE:
      return 'INACTIVE';
    case FILTERS.ALL:
      return 'ACTIVE,INACTIVE';

    default:
      return 'ACTIVE';
  }
};
export const getRoomType = (roomType) => {
  switch (roomType) {
    case 'SMALL':
      return { name: 'Small', size: '(1-15 students)' };
    case 'BIG':
      return { name: 'Big', size: '(15+ students)' };

    default:
      return { name: 'One-to-One', size: '(1 student)' };
  }
};
export const getFilterByCheckBoxValue = (active, inactive) => {
  if (active && !inactive) return FILTERS.ACTIVE;
  if (!active && inactive) return FILTERS.InACTIVE;
  return FILTERS.ALL;
};

const parseMicroCvData = (microCvData) => {
  const data = JSON.parse(JSON.stringify(microCvData));
  const { educationDetails, jobPreference } = data;
  if (educationDetails && jobPreference) {
    parseDataForEducationDetails(educationDetails);
    parseDataForJobPreference(jobPreference);
  }
  return data;
};
const parseDataForEducationDetails = (educationDetails) => {
  const fldsNotToInclude = ['formGrpId', 'degreeList'];
  educationDetails.forEach((educationObj, index) => {
    let removeGrp = true;
    Object.keys(educationObj).forEach((eduKey) => {
      if (!fldsNotToInclude.includes(eduKey) && educationObj[eduKey]) {
        removeGrp = false;
      }
    });
    if (removeGrp) {
      educationDetails.splice(index, 1);
    }
  });
};
const parseDataForJobPreference = (jobPreference) => {
  const fldsNotToInclude = ['formGrpId'];
  jobPreference.forEach((jobPreferenceObj, index) => {
    let removeGrp = true;
    Object.keys(jobPreferenceObj).forEach((jobPreferenceKey) => {
      if (
        !fldsNotToInclude.includes(jobPreferenceKey) &&
        jobPreferenceObj[jobPreferenceKey]
      ) {
        removeGrp = false;
      }
    });
    if (removeGrp) {
      jobPreference.splice(index, 1);
    }
  });
};

const openLinkInNewTab = (url) => {
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('target', '_blank');
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const openLinkInSameTab = (url) => {
  const link = document.createElement('a');
  link.setAttribute('href', url);
  // link.setAttribute("target", "_blank");
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadFile = (encodedUri, name = 'file') => {
  let link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', name);
  document.body.appendChild(link); // Required for FF
  link.click();
  return Promise.resolve();
};

export const downloadCsvFile = async (data, fileName = 'file') => {
  const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${data}`);
  return await downloadFile(encodedUri, `${fileName}.csv`);
};

const getActiveFilterValue = (checkedItems) => {
  const active = checkedItems.get('active');
  const inactive = checkedItems.get('inactive');
  if (active && !inactive) return FILTERS.ACTIVE;
  if (!active && inactive) return FILTERS.InACTIVE;
  return FILTERS.ALL;
};

const getJobRolesFromJobFunctions = (
  jobFunctionId,
  jobRoleId,
  jobFunctionsArr
) => {
  const data = jobFunctionsArr.filter((item) => item.id === jobFunctionId);
  const jobRolesData =
    data &&
    data.length > 0 &&
    data[0].jobRoles.filter((item) => item.id === jobRoleId);
  return jobRolesData && jobRolesData.length > 0 && jobRolesData[0];
};

export const getJobRolesForJob = (jobRoles, jobRoleId) => {
  const jobRolesData = jobRoles.filter((item) => item.id === jobRoleId);
  return jobRolesData && jobRolesData.length > 0 && jobRolesData[0];
};

const getLocationData = (id, locationList) => {
  const data = locationList.filter((location) => location.id === id);
  return data && data.length > 0 && data[0];
};

const getSkill = (skillId, skillsArr) => {
  const data = skillsArr.filter((item) => item.name === skillId);
  return (data && data.length > 0 && data[0]) || {};
};
const getSkillLevel = (skillId, skillLevelArr) => {
  try {
    const data = skillLevelArr.filter((item) => item.id === skillId);
    return data && data.length > 0 && data[0];
  } catch (err) {
    return null;
  }
};

const getJobFunctionsData = (jobFunctionId, jobFunctionsArr) => {
  const data = jobFunctionsArr.filter((item) => item.id === jobFunctionId);
  return data && data.length > 0 && data[0];
};

const getIndustryData = (industryId, industryArr) => {
  const data = industryArr.filter((item) => item.id === industryId);
  return data && data.length > 0 && data[0];
};
export const getOptions = (obj, name = 'fullName', index = 'id') => {
  const arr = [];
  try {
    obj.map((item) => {
      const newObj = { label: item[name], value: item[index] };
      if (item.lastName) {
        newObj.label += ` ${item.lastName} ( ${item.email} )`;
      }
      arr.push(newObj);
    });
  } catch (err) {
    return [];
  }
  return arr;
};
export const showErrorMsg = (err) => {
  if (
    err &&
    err.response &&
    err.response.status !== 500 &&
    err.response.status !== 401
  ) {
    let message = '';
    let subMessage = '';
    const { data: { apierror, error } = {} } = err.response;
    if (apierror) {
      message = apierror.message;
      const { subErrors } = apierror;
      if (subErrors) {
        const [val] = subErrors;
        if (val) {
          subMessage = val.message;
        }
      }
    }
    if (
      typeof error === 'object' &&
      Object.keys(error).length > 0 &&
      error.message
    ) {
      message = error.message;
    }
    showToastMsg(subMessage || message || STRINGS.ERR_MSG);
  }
  // else {
  //   showToastMsg(STRINGS.ERR_MSG);
  // }
};

export const getTime = (date, from) => {
  const getDate = date.format('YYYY-MM-DD');
  const getFrom = from.format('HH:mm:ss');
  return `${getDate} ${getFrom}`;
};

export const roleParser = (roles = []) => {
  try {
    const roleIds = roles.map((item) => item.id);
    return roleIds;
  } catch (err) {
    return [];
  }
};

export function cipher(salt) {
  const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0));
  const byteHex = (n) => `0${Number(n).toString(16)}`.substr(-2);
  const applySaltToChar = (code) =>
    textToChars(salt).reduce((a, b) => a ^ b, code);

  return (text) =>
    text
      .split('')
      .map(textToChars)
      .map(applySaltToChar)
      .map(byteHex)
      .join('');
}

export const decipher = (salt) => {
  const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0));
  const applySaltToChar = (code) =>
    textToChars(salt).reduce((a, b) => a ^ b, code);
  return (encoded) =>
    encoded
      .match(/.{1,2}/g)
      .map((hex) => parseInt(hex, 16))
      .map(applySaltToChar)
      .map((charCode) => String.fromCharCode(charCode))
      .join('');
};

export const getMessageStringByParams = (
  isSkill,
  isJobFunction,
  isJobRole,
  isOrganization,
  isIndustry
) => {
  if (isSkill) return 'skill';
  if (isJobFunction) return 'job function';
  if (isJobRole) return 'job role';
  if (isOrganization) return 'organization';
  if (isIndustry) return 'industry';
  return 'industry';
};

export const setSearchUrl = (searchParam) => {
  window.history.replaceState(
    {},
    '',
    `${window.location.protocol}//${window.location.host}${window.location.pathname}?search=${searchParam}`
  );
};
export const resetUrl = () => {
  window.history.replaceState(
    {},
    '',
    `${window.location.protocol}//${window.location.host}${window.location.pathname}`
  );
};

export const getFormatedLocalDataFromUtc = (timestamp, getCurrent) => {
  try {
    if (getCurrent) {
      return moment()
        .local()
        .format('DD-MMM-YYYY');
    }
    return moment(timestamp)
      .local()
      .format('DD-MMM-YYYY');
  } catch (err) {
    return '';
  }
};

export const setPendingCall = (object) => {
  try {
    sessionStorage.setItem('pendingCalls', JSON.stringify(object));
  } catch (err) {
    return null;
  }
};
export const resolvePendingCall = (key) => {
  try {
    const pendingCalls = JSON.parse(sessionStorage.getItem('pendingCalls'));
    return pendingCalls[key];
  } catch (err) {
    return null;
  }
};
export const deletePendingCall = (key = '') => {
  try {
    const pendingCalls = JSON.parse(sessionStorage.getItem('pendingCalls'));
    if (pendingCalls) {
      //  delete pendingCalls[key];
      if (key) {
        delete pendingCalls[key];
      }
      pendingCalls.redirectedFrom = '';
    }
    sessionStorage.setItem('pendingCalls', JSON.stringify(pendingCalls));
  } catch (err) {
    console.warn('Pending call error');
  }
};
export const getPendingCall = (object, key) => {
  try {
    const pendingCalls = JSON.parse(sessionStorage.getItem('pendingCalls'));
    return pendingCalls;
  } catch (err) {
    return {};
  }
};

export const getQueryParams = (props) => {
  try {
    const { history } = props;
    const location = history && history.location;
    const { search } = location;
    const parsed = queryString.parse(search);
    return parsed;
  } catch (err) {
    return {};
  }
};

export const getPaymentObj = ({
  title,
  amount,
  description,
  contentHash,
  contentType,
  redirectedFrom,
  onSuccessRedirect,
  onFailureRedirect,
  metaData,
  courseType,
  slug,
  contentUrl,
  ekey,
}) => {
  const obj = {
    title: title || '',
    amount: amount || null,
    description: description || '',
    redirectedFrom: redirectedFrom || getPathName(),
    onSuccessRedirect: PAYMENT_CHECKOUT[contentType].getSuccessUrl(
      contentHash,
      contentUrl
    ),
    onFailureRedirect: onFailureRedirect || '',
    contentHash: contentHash || '',
    contentType: contentType || '',
    metaData: metaData || {},
    slug,
    courseType,
    ekey,
  };
  localStorage.setItem('paymentInfo', JSON.stringify(obj));
  return obj;
};

export const clean = (realObj) => {
  const propNames = Object.getOwnPropertyNames(realObj);
  const obj = JSON.parse(JSON.stringify(propNames));
  for (let i = 0; i < propNames.length; i++) {
    const propName = propNames[i];
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj;
};

export const addMetaTags = {
  makeMetaTag() {
    const meta = document.createElement('meta');
    return meta;
  },
  addImageAltTag(title = '') {
    if (title) {
      const str = `${title.split(' ').join('-')}.jpg`;
      return str;
    }
    return title;
  },
  selectMetaTag(tagName, selectAttribute = 'name') {
    return document.querySelector(`meta[${selectAttribute}="${tagName}"]`);
  },
  insertMetaTag(metaTag) {
    const metaTagWithTheme = this.selectMetaTag('og:description', 'property');
    if (!metaTagWithTheme) return;
    metaTagWithTheme.insertAdjacentElement('afterend', metaTag);
  },
  addTitle(title = '') {
    if (!title) return;
    const metaElementTag = document.querySelector('title');
    const metaElementTitleOgTag = this.selectMetaTag('og:title', 'property');
    const metaTwitterTitleTag = this.selectMetaTag('twitter:title', 'property');
    if (metaElementTag && metaElementTitleOgTag && metaTwitterTitleTag) {
      metaElementTag.textContent = `${title} | Seekho`;
      metaElementTitleOgTag.content = `${title} | Seekho`;
      metaTwitterTitleTag.content = `${title} | Seekho`;
    }
  },
  addDescription(description = '') {
    if (!description) return;
    const metaDescriptionTag = this.selectMetaTag('og:description', 'property');
    const metaTwitterDescriptionTag = this.selectMetaTag(
      'twitter:description',
      'property'
    );
    if (metaDescriptionTag && metaTwitterDescriptionTag) {
      metaDescriptionTag.content = description.slice(0, 150);
      metaTwitterDescriptionTag.content = description.slice(0, 150);
    }
  },
  addImageMetaTag(imageUrl) {
    if (!imageUrl) return;
    const metaImgTag = this.makeMetaTag();
    const metaTwitterImg = this.makeMetaTag();
    metaImgTag.setAttribute('property', 'og:image');
    metaImgTag.content = imageUrl;
    metaTwitterImg.setAttribute('property', 'twitter:image');
    metaTwitterImg.content = imageUrl;
    this.insertMetaTag(metaImgTag);
    this.insertMetaTag(metaTwitterImg);
  },
  removeImageMetaTag() {
    const metaTag = document.querySelector('meta[property="og:image"]');
    const metaTwitterTag = document.querySelector(
      'meta[property="twitter:image"]'
    );

    if (metaTag && metaTwitterTag) {
      metaTag.remove();
      metaTwitterTag.remove();
    }
  },
};

export const lockScroll = (data) => {
  const root = document.getElementById('root');
  if (root) {
    root.style.overflow = data;
  }
};

export const commonPendingCallHandler = (redirectedFrom = null) => {
  if (!redirectedFrom) return;
  let pendingCall = getPendingCall();
  if (!pendingCall) {
    pendingCall = {};
  }
  pendingCall.redirectedFrom = redirectedFrom;
  setPendingCall(pendingCall);
};

export function checkObjectEmpty(obj, ignoreArray) {
  for (const key in obj) {
    if (obj[key] !== null && obj[key] != '' && !ignoreArray.includes(key))
      return false;
  }
  return true;
}

export function checkUserEmpInfo(obj, ignoreArray) {
  const errors = {};
  const isObjectEmpty = checkObjectEmpty(obj, ignoreArray);
  if (isObjectEmpty)
    return {
      userEmploymentInfoErrors: {},
      userEmpHasError: false,
      isObjectEmpty,
    };
  for (const objKey in obj) {
    const value = obj[objKey];
    if (value === null || value === '') {
      if (!ignoreArray.includes(objKey)) errors[objKey] = 'Missing field';
    }
    if (value && !ignoreArray.includes(objKey)) {
      if (
        (objKey === 'expectedCtc' || objKey === 'lastCtc') &&
        (value > 10000000 || value <= 0 || isNaN(value))
      )
        errors[objKey] = 'Invalid value';
    }
    if (value && !ignoreArray.includes(objKey)) {
      if (objKey === 'expYrs' && (value <= 0 || value > 50 || isNaN(value))) {
        errors[objKey] = 'Invalid value';
      }
    }
  }
  const hasError = Object.keys(errors).length > 0;
  return {
    userEmploymentInfoErrors: errors,
    userEmpHasError: hasError,
    isObjectEmpty,
  };
}

function isArrayEmpty(arr = [], ignoreArray) {
  if (!arr || arr.length === 0) return true;

  for (let i = 0; i < arr.length; i++) {
    if (Object.keys(arr[i]).length > 0) {
      return false;
    }
  }

  return true;
}

export function checkUserEducationErrors(array) {
  const errors = [];

  array.forEach((obj, index) => {
    const isObjectEmpty = checkObjectEmpty(obj, ['formGrpId']);
    const error = {};
    if (!isObjectEmpty) {
      for (const objKey in obj) {
        const value = obj[objKey];
        if (value === null || value === '') error[objKey] = 'Missing field';
      }
    }
    errors.push(error);
  });

  return {
    educationInfoErrors: errors,
    educationInfoHasError: !isArrayEmpty(errors),
  };
}

export function checkUserJobPreferenceErrors(array) {
  const errors = [];

  array.forEach((obj, index) => {
    const isObjectEmpty = checkObjectEmpty(obj, ['formGrpId', 'id']);
    const error = {};
    if (!isObjectEmpty) {
      for (const objKey in obj) {
        const value = obj[objKey];
        if (value === null || value === '') {
          if (objKey !== 'id') error[objKey] = 'Missing field';
        }
      }
    }
    errors.push(error);
  });

  return {
    jobPreferenceErrors: errors,
    jobPreferenceHasError: !isArrayEmpty(errors),
  };
}

export function checkUserPreviousJobsErrors(array, excludeFieldsArr) {
  const errors = [];
  array.forEach((obj, index) => {
    const isObjectEmpty = checkObjectEmpty(obj, excludeFieldsArr);
    const error = {};
    if (!isObjectEmpty) {
      for (const objKey in obj) {
        const value = obj[objKey];
        if (value === null || value === '') {
          if (!excludeFieldsArr.includes(objKey))
            error[objKey] = 'Missing field';
        }
      }
    }
    errors.push(error);
  });

  return {
    previousJobsErrors: errors,
    previousJobsHasError: !isArrayEmpty(errors),
  };
}

export function parseMicroCv({
  microCv,
  isEducationInfoCompleted,
  isJobPreferenceCompleted,
  isPreviousJobsCompleted,
}) {
  const newMicroCv = {};
  for (const objKey in microCv) {
    if (objKey === 'addresses') {
      if (!microCv.addresses[0].cityId) newMicroCv[objKey] = [];
      else {
        newMicroCv[objKey] = microCv[objKey];
      }
    } else if (objKey === 'educationDetails') {
      if (!isEducationInfoCompleted) {
        microCv.educationDetails.splice(-1, 1);
        newMicroCv[objKey] = microCv.educationDetails;
      } else {
        newMicroCv[objKey] = microCv.educationDetails;
      }
    } else if (objKey === 'jobPreference') {
      if (!isJobPreferenceCompleted) {
        microCv.jobPreference.splice(-1, 1);
        newMicroCv[objKey] = microCv.jobPreference;
      } else {
        newMicroCv[objKey] = microCv.jobPreference;
      }
    } else if (objKey === 'previousJobs') {
      if (!isPreviousJobsCompleted) {
        microCv.previousJobs.splice(-1, 1);
        if (microCv.previousJobs.length === 0) {
          newMicroCv[objKey] = [];
        } else {
          const updatedPrevJobs = [];
          microCv.previousJobs.forEach((item) => {
            const newItem = deleteEmptyValuesFromObject(item);
            updatedPrevJobs.push(newItem);
          });
          newMicroCv[objKey] = updatedPrevJobs;
        }
      } else {
        const updatedPrevJobs = [];
        microCv.previousJobs.forEach((item) => {
          const newItem = deleteEmptyValuesFromObject(item);
          updatedPrevJobs.push(newItem);
        });
        newMicroCv[objKey] = updatedPrevJobs;
      }
    } else if (objKey === 'userEmploymentInfo') {
      const experiencedObj = microCv.expList.find(
        (item) => item.fullName === 'Experienced'
      );
      const expId = experiencedObj.id;
      const experienced = microCv.userEmploymentInfo.experienceId == expId;
      const { isObjectEmpty } = checkUserEmpInfo(
        microCv.userEmploymentInfo,
        experienced
          ? ['ctcIn']
          : ['currentlyWorking', 'lastCtc', 'expYrs', 'ctcIn']
      );
      if (isObjectEmpty) {
      } else newMicroCv[objKey] = microCv[objKey];
    } else {
      newMicroCv[objKey] = microCv[objKey];
    }
  }
  const parsedMicroCv = _.clone(newMicroCv, true);

  return parsedMicroCv;
}

export function getErrorFldFromObject(fld) {
  switch (fld) {
    case 'educationDetails':
      return 'educationInfoErrors';
    case 'jobPreference':
      return 'jobPreferenceErrors';
    case 'previousJobs':
      return 'previousJobsErrors';
    case 'userEmploymentInfo':
      return 'userEmploymentInfoErrors';
    default:
      return null;
  }
}

export function getErrorMsgFromArray(index, name, arr) {
  return arr && arr[index] && arr[index][name];
}

export function deleteEmptyValuesFromObject(obj) {
  const clonedObj = _.cloneDeep(obj, true);
  const object = _.pickBy(clonedObj, _.identity);
  return object;
}

export const getMergedGtmArray = ({ name, defaultArray, gtmArray }) => {
  try {
    return [...defaultArray, ...gtmArray];
  } catch (err) {
    return defaultArray;
  }
};

export const parseUserName = (firstName = '', lastName = '') => {
  try {
    const userFirstName = firstName && firstName.trim();
    const userLastName = lastName && lastName.trim();
    let fullName = ``;
    if (userFirstName) {
      fullName += `${userFirstName[0].toUpperCase() + userFirstName.slice(1)} `;
    }
    if (userLastName) {
      fullName += userLastName[0].toUpperCase() + userLastName.slice(1);
    }
    return fullName;
  } catch (e) {
    return '';
  }
};

export const getContentDataFromLMS = ({
  pageNumber,
  contentType,
  contentStatus,
  pageSize,
}) => {
  const userHash = TokenStorage.getItem(APP_STORAGE.USER_HASH);
  return Axios.get(
    `${B2B_API.CONTENT}/${userHash}?contentType=${contentType}&status=${contentStatus}&pageSize=${pageSize}&pageNumber=${pageNumber}`,
    {
      info: 'lmsAuthApi',
    }
  );
};

export const getModifiedDataForLp = (data = {}, keyToLookFor = null) => {
  const { cost, groupToTags = {} } = keyToLookFor ? data[keyToLookFor] : data;

  const ftOrMcTagObj = getProductObjFromTags(groupToTags);
  const complexityTag = getComplexityTag(groupToTags);

  const formattedDataObj = {
    showMcOrFtBadge: Object.keys(ftOrMcTagObj).length > 0,
    showComplexityTag: Object.keys(complexityTag).length > 0,
    complexityTagName:
      Object.keys(complexityTag).length > 0 ? complexityTag.name : '',
    isPremium: !!(cost && cost > 0),
    showDuration: false,
    ftOrMcTagObj,
  };
  const resultObj =
    keyToLookFor && data[keyToLookFor]
      ? { ...data[keyToLookFor], ...formattedDataObj }
      : { ...data, ...formattedDataObj };
  return resultObj;
};

export const getComplexityTag = (groupToTags = {}) => {
  if (
    groupToTags &&
    Object.keys(groupToTags).length > 0 &&
    groupToTags['difficulty-level']
  ) {
    const difficultyLevel = groupToTags['difficulty-level'];
    if (difficultyLevel && difficultyLevel.length > 0) {
      return difficultyLevel[0];
    }
    return {};
  }
  return {};
};

export const getProductObjFromTags = (groupToTags = {}) => {
  if (
    groupToTags &&
    Object.keys(groupToTags).length > 0 &&
    groupToTags[TAGS.CONTENT_TYPE_1]
  ) {
    const contentType = groupToTags[TAGS.CONTENT_TYPE_1];
    const filteredResult = contentType.find((type) => {
      return (
        type &&
        Object.keys(type).length > 0 &&
        type.name &&
        (type.name === TAGS.FAST_TRACK || type.name === TAGS.MASTER_CLASS)
      );
    });
    return filteredResult || {};
  }
  return {};
};

export function toTitleCase(phrase) {
  try {
    return phrase
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  } catch (err) {
    return phrase;
  }
}

export const getTagList = (tags = []) => {
  try {
    const tagList = tags.map((item) => item.name);
    const newTagList = tagList.map((item) => item.toLowerCase());
    return newTagList;
  } catch (err) {
    return [];
  }
};

export const showOtpText = (userDetail, isUserExist) => {
  if (userDetail) {
    return `${userDetail.slice(0, 4)}${'*'.repeat(
      userDetail && userDetail.length - 4
    )} ${isUserExist ? 'for login' : 'for sign up'}`;
  }
  return '';
};

export const checkAndAppendIntoQuery = (
  key = '',
  value = '',
  history,
  pathName = ''
) => {
  const { search, pathname } = window.location;
  const params = new URLSearchParams(search);
  const url = pathName || pathname;
  if (params.get(key)) {
    params.set(key, value);
  } else {
    params.append(key, value);
  }
  history.push(`${url}?${params}`);
};

export const getDataValueForInput = (date = Date.now()) => {
  return new Date(date).toISOString().split('T')[0];
};

export const getArrayMap = (data = []) => {
  const dataMap = {};

  if (!Array.isArray(data) || data.length === 0) {
    return dataMap;
  }

  data.map((item) => {
    dataMap[item] = true;
    return item;
  });

  return dataMap;
};

export const getIssuedCertificateType = (certificateType) => {
  if (certificateType) {
    return certificateType
      .split(' ')
      .join('_')
      .toLowerCase();
  }
  return '';
};

export const getPermissionsArr = (data = {}) => {
  try {
    const { productPermissions } = data;
    return productPermissions['spark-hash'] || [];
  } catch (e) {
    return [];
  }
};

export const getJobPortalMenuItems = (permissions = []) => {
  try {
    return ADMIN_LINKS.filter((eachLink) =>
      permissions.includes(eachLink.access)
    );
  } catch (err) {
    return [];
  }
};

export const getAuthRoutesForAdmin = () => {
  const organisationType = TokenStorage.getItem(APP_STORAGE.ORG_TYPE) || '';
  const isSubDomain = hasSubDomain();
  if (isSubDomain && organisationType === ORG_TYPE_CONSTANTS.COMPANY) {
    return [REDIRECT_CONSTANTS.EMP_LOGIN, REDIRECT_CONSTANTS.EMP_JOBS];
  } else if (isSubDomain && organisationType === ORG_TYPE_CONSTANTS.COLLEGE) {
    return [REDIRECT_CONSTANTS.EMP_LOGIN, REDIRECT_CONSTANTS.COLLEGE_OVERVIEW];
  }
  return [REDIRECT_CONSTANTS.ADMIN_LOGIN, REDIRECT_CONSTANTS.ADMIN_HOME];
};

export const stringToHslColor = (str = '', s, l) => {
  let hash = 0;
  str = str || '';
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const h = hash % 360;
  return `hsl(${h}, ${s}%, ${l}%)`;
};

export function AWSUpload(photoKey, file) {
  AWS.config.update({
    region: 'ap-south-1',
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'ap-south-1:e1dcb2a5-5d96-47c6-a89e-3ac9de660d28',
    }),
  });
  const mimeType = mime.lookup(file.name);

  return new AWS.S3.ManagedUpload({
    apiVersion: '2006-03-01',
    params: {
      Bucket: 'seekify-public',
      Key: photoKey,
      Body: file,
      ACL: 'private',
      ContentType: mimeType,
    },
  });
}

export function uploadFileResourceToS3(file, type = 'File') {
  const userInfo =
    JSON.parse(localStorage.getItem(APP_STORAGE.USER_INFO)) ||
    JSON.parse(localStorage.getItem(APP_STORAGE.ADMIN_USER_INFO));
  const { userId } = userInfo || {};
  const uniqueKey = `${encodeURIComponent(`${Date.now()}-${userId}`)}`;
  const photoKey = `${uniqueKey}-${file.name}`;
  const promise = AWSUpload(photoKey, file).promise();

  return promise.then(
    (response) => {
      showToastMsg(`${type} Uploaded`, 'success');
      return response;
    },
    (error) => {
      showToastMsg('Error');
      console.log(error, 'error');
    }
  );
}

export function getUploadedFileName(url) {
  const name =
    url
      .split('/')
      .pop()
      .split('#')
      .shift()
      .split('?')
      .shift() || null;
  const newName = name.substring(
    name.indexOf('-', name.indexOf('-') + 1) + 1,
    name.length
  );
  return decodeURI(newName);
}

export function urlSearchParams() {
  const searchParams = new URLSearchParams(window.location.search);
  return {
    getQuery(key = '') {
      return searchParams.get(key);
    },
    getSearchQuery() {
      return searchParams.toString();
    },
    setMultipleQuery(arr = []) {
      arr.forEach((item) => {
        searchParams.set(item.key, item.value);
        if (item.value.length < 1) {
          searchParams.delete(item.key);
        }
      });
      this.replaceState();
    },
    replaceState() {
      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}${decodeURIComponent(searchParams) &&
          `?${decodeURIComponent(searchParams)}`}`
      );
    },
    removeQuery(key) {
      if (searchParams.has(key)) {
        searchParams.delete(key);
      }
      this.replaceState();
    },
  };
}

export function getTextForJobStatus(status = '', appliedOn = '') {
  const statusObj =
    CANDIDATE_STATUS_LIST.find((item) => item.value === status) || {};
  const { label = '' } = statusObj || {};

  if (label && appliedOn) {
    return `Applied On ${getFormatedLocalDataFromUtc(appliedOn)}`;
  }
  if (label) {
    return label;
  }

  return '';
}

export function getNavLinkClass(link, active = false) {
  switch (link) {
    case REDIRECT_CONSTANTS.SEEKHO_URL:
      return active ? 'navbar-link-seekho-active' : 'navbar-link';
    default:
      return active ? 'navbar-link-active' : 'navbar-link';
  }
}

export function getJobDetailSlug(arr = []) {
  return arr
    .filter((ele) => ele && ele.length > 0)
    .map((ele) =>
      ele
        .split(' ')
        .join('-')
        .trim()
        .toLowerCase()
    )
    .join('-');
}

export function slugify(string) {
  return string
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
}

export const stripHTML = (html = '') => {
  if (typeof document !== 'undefined') {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }
  return html;
};

export function stripHTMLOnServer(html = '') {
  if (html) {
    return html.replace(/(<([^>]+)>)/gi, '');
  }
  return html;
}

export const getAllDataFromApi = async (url, info = API_INFO.LMS) => {
  let pageNo = 1;
  let allPages = 1;
  let finalData = [];
  do {
    const { data = {} } = await services.getRequest(
      `${url}?pageSize=100&pageNumber=${pageNo}`,
      {
        info,
      }
    );
    const { totalPages, data: list = [], pageNumber } = data || {};
    allPages = totalPages;
    pageNo = pageNumber + 1;
    if (list && list.length > 0) {
      finalData = finalData.concat(list);
    }
  } while (allPages >= pageNo);
  return finalData;
};

export const getArrayFromSessionStorage = (key = '') => {
  if (key && SessionStorage.getItem(key)) {
    return JSON.parse(SessionStorage.getItem(key)) || [];
  }
  return [];
};

export const getStringFromArray = (items = [], key = 'name') => {
  return items
    .map((item) => item[key])
    .toString()
    .split(',')
    .join(', ');
};

const getStrippedDescription = (description = '') => {
  return description &&
    description[0] === '{' &&
    description[description.length - 1] === '}'
    ? stateToHTML(
        EditorState.createWithContent(
          convertFromRaw(JSON.parse(description))
        ).getCurrentContent()
      )
    : description;
};

export const getJobDescription = (description = '', responsibility = '') => {
  const strippedDesc = getStrippedDescription(description);
  const newResponsibility = responsibility
    ? `<p><br /><strong>Responsibilities</strong><p><br />${responsibility.replace(
        /\n/g,
        '<br/>'
      )}</p></p>`
    : '';
  return strippedDesc + newResponsibility;
};

export const openOrgProxyUrl = (userHash = '', orgData = {}) => {
  const { domain = '' } = orgData;
  if (userHash && domain) {
    const token = TokenStorage.getItem(APP_STORAGE.ADMIN_ACCESS_TOKEN) || '';
    if (isDev()) {
      const orgDomain = domain.split('.')[0] || '';
      return openLinkInNewTab(
        `http://${orgDomain}.localhost:3002/recruiter/proxy/${userHash}?t=${token}`
      );
    }
    return openLinkInNewTab(
      `https://${domain}/recruiter/proxy/${userHash}?t=${token}`
    );
  }
  return null;
};
export const handleUserLabel = (name = '', email = '', phone = '') => {
  if (email && !phone) {
    return name + ' (' + email + ')';
  }
  if (!email && phone) {
    return name + ' (' + phone + ')';
  }
  if (email && phone) {
    return name + ' (' + email + ', ' + phone + ')';
  }
  return name;
};

export const getAttachedSkills = (skills = []) => {
  return (
    (skills &&
      skills.length > 0 &&
      skills.map((item) => {
        const {
          skillId = '',
          skillName = '',
          competencyLevel = '',
          mandatory = false,
          displayOrder = 0,
        } = item;
        if (skillId) {
          return {
            skill: skillId
              ? {
                  label: skillName,
                  value: skillId,
                }
              : null,
            competencyLevel: competencyLevel
              ? {
                  label:
                    competencyLevel.charAt(0).toUpperCase() +
                    competencyLevel.slice(1).toLowerCase(),
                  value: competencyLevel,
                }
              : null,
            mandatory: mandatory
              ? {
                  label: 'Mandatory',
                  value: mandatory,
                }
              : MANDATORY_SKILL_LIST[0],
            displayOrder,
          };
        }
      })) || [
      {
        skill: null,
        competencyLevel: COMPETENCY_LEVEL_LIST[0],
        mandatory: MANDATORY_SKILL_LIST[0],
        displayOrder: 0,
      },
    ]
  );
};

function isBlank(value) {
  return value === undefined || value === null || value === '';
}

function isNotBlank(value) {
  return !isBlank(value);
}

export const getQueryParamValues = (search) => {
  const urlParams = new URLSearchParams(search);
  const entries = urlParams.entries();
  return Object.fromEntries(entries);
};

export const getNextMonthTimestampFromNow = (numberOfMonths = 1)=> {
  return new Date(`${((new Date(Date.now()).getMonth()+numberOfMonths)%12 + 1)}/${new Date(Date.now()).getDay()}/${new Date(Date.now()).getFullYear()}`);
}

export {
  customStyles,
  getCurrentAndPreviousFilters,
  getLocalDateFromUTC,
  getEntityStatusByFilters,
  openLinkInNewTab,
  getActiveFilterValue,
  parseMicroCvData,
  getJobRolesFromJobFunctions,
  getLocationData,
  getSkill,
  getJobFunctionsData,
  getIndustryData,
  getSkillLevel,
  isBlank,
  isNotBlank,
};
