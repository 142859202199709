import styled from 'styled-components';

const StyledPopup = styled.div`
  .popup-content {
    width: ${({ maxWidth }) => `${maxWidth}!important` || ''};
  }
  .common-popup-content {
    max-width: ${({ maxWidth }) => maxWidth || ''};
    background: ${({ bgColor }) => `${bgColor} !important` || ''};
  }
  .cross-btn {
    top: 8px;
    display: flex;
  }
  .common-popup-container {
    max-height: ${props => props.maxHeight || ''};
    height: ${({ height }) => height || ''};
    overflow-y: ${props => (props.maxHeight ? 'scroll' : 'hidden')};
  }
  @media (max-width: 768px) {
    .popup-content {
      width: 93% !important;
    }
  }
`;

export default StyledPopup;
