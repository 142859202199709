import Axios from "axios";
// import Cookie from 'js-cookie';
import showToastMsg from "./show-toasts";
import TokenStorage from "./TokenStorage";
import UserState from "./UserState";

let isRefreshing = false;
const requestArr = [];

const errorHandler = (err) => {
  const { status, config = {} } = (err && err.response) || {};
  const originalReq = config;

  if (status) {
    if (status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;

        const isUserRoute = !!checkIfCandidateRoute();
        const refreshTokenFunc = isUserRoute
          ? TokenStorage.refreshCandidateToken
          : TokenStorage.refreshAdminToken;

        // Cookie.remove(APP_STORAGE.ACCESS_TOKEN);
        return refreshTokenFunc()
          .then((token) => {
            originalReq.headers.Authorization = `Bearer ${token}`;
            return retryRequest(1000, originalReq).then((res) => {
              isRefreshing = false;
              window.location.reload(true);
              /* if (requestArr.length > 0) {
                debugger;
                window.location.reload(true);
              } */
              return res;
            });
          })
          .catch((rej) => {
            if (isUserRoute) {
              UserState.clearCandidateTokenStorage();
              window.location = `${window.location.origin}`;
            } else {
              UserState.clearAdminTokenStorage();
              window.location = `${
                window.location.origin
              }/${getCurrentRoute()}`;
            }
            return Promise.reject(err);
          });
      }
      requestArr.push(originalReq);
    } else {
      return Promise.reject(err);
    }
  }
};

const checkError = (err, errFrom) => {
  if (err && err.response) {
    let subMessage = "";
    const { status, data } = err.response;
    const message = data.apierror ? data.apierror.message : "";
    if (data && data.apierror) {
      const { subErrors } = data.apierror;
      if (subErrors) {
        const [val] = subErrors;
        if (val) {
          subMessage = val.message;
        }
      }
    }
    const defaults = [401, 500];
    if (!defaults.includes(status)) {
      let msg = errFrom && errFrom[status] ? errFrom[status] : message;
      if (!msg) {
        msg = "Something went wrong, try again later";
      }
      showToastMsg(subMessage || msg, "error");
    }
  }
};

const instance = Axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASEURL,
});

const retryRequest = (milliseconds, originalRequest) => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        resolve(instance(originalRequest).catch((rej) => errorHandler(rej))),
      milliseconds
    );
  });
};

const checkIfCandidateRoute = () => {
  const { location = {} } = window;
  const { pathname = {} } = location;
  if (
    !pathname.includes("admin") &&
    !pathname.includes("emp") &&
    !pathname.includes("teacher") &&
    !pathname.includes("classroom")
  )
    return true;
  return false;
};
export const getCurrentRoute = () => {
  if (window.location.pathname.includes("emp")) return "emp-login";
  if (window.location.pathname.includes("admin")) return "admin-login";
  if (window.location.pathname.includes("teacher")) return "teacher-login";
  return "";
};
export { errorHandler, checkError };
