const THEME = {
  bgColor: '#EEF0F5',
  primaryColor: '#131313',
  secondaryColor: '#838383',
  navBarBgColor: 'linear-gradient(270deg, #413896 0%, #53A0FD 100%)',
  primaryBtnColor: '#364DED',
  secondaryBtnColor: '#4E83E1',
  dividerColor: '#e1e1e1',
  pink: '#E85697',
  green: '#37BC79',
  blue: '#0078ff',
};

Object.freeze(THEME);

export default THEME;
