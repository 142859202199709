import showToastMsg from "util/show-toasts";
import { ADMIN_HOME } from "../AdminLogin/adminActionTypes";

const initialState = { checkedItems: new Map([["active", true]]), menuOpen: false };

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  const newState = { ...state };

  switch (type) {
    case ADMIN_HOME.CHANGE_FILTER: {
      const item = payload.target.name;
      const isChecked = payload.target.checked;
      if (isChecked === false) {
        const val = state.checkedItems.get(item === "active" ? "inactive" : "active");
        if (!val) {
          showToastMsg("One filter must be active", "info");
          return { ...newState };
        }
      }
      const newMap = new Map(JSON.parse(JSON.stringify([...state.checkedItems])));
      const mod = newMap.set(item, isChecked);
      return {
        ...newState,
        checkedItems: mod,
      };
    }
    case ADMIN_HOME.RESET_FILTER: {
      return {
        ...newState,
        checkedItems: new Map([["active", true]]),
      };
    }
    case "toggleMenu": {
      return { ...newState, menuOpen: payload };
    }
    default:
      return { ...newState };
  }
};

export default reducer;
