const OrganizationActionTypes = {
  UPDATE_LOADER_STATE: "UPDATE_LOADER_STATE",
  SET_LIST: "SET_LIST",
  HAS_ERROR: "ORG_HAS_ERROR",
  RESET_LIST: "RESET_LIST",
  ADD_ORGANIZATION: "ADD_ORGANIZATION",
  VISIT_END_LIST: "VISIT_END__ORG_LIST",
  UPDATE_ORG: "UPDATE_ORG",
  DELETE_ORG: "DELETE_ORG",
  ORG_SET_SEARCH: "ORG_SET_SEARCH",
  ORG_RESET_REDIRECTION_DATA: "ORG_RESET_REDIRECTION_DATA",
};
export default OrganizationActionTypes;
