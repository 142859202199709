import { deleteEmptyValuesFromObject } from './common';
import TokenStorage from './TokenStorage';
import { APP_STORAGE } from './app-constants';

class CleverEvents {
  static getUserInfo() {
    try {
      const userInfo = JSON.parse(localStorage.getItem('user_info'));
      return { userId: userInfo.id };
    } catch (err) {
      return {};
    }
  }

  static saveCleverEvent({ event, evtData }) {
    if (window.clevertap && window.clevertap.event) {
      window.clevertap.profile.push({
        Site: {
          Identity: `${TokenStorage.getItem(APP_STORAGE.USER_HASH)}`,
        },
      });
      window.clevertap.event.push(event, { ...evtData });
    }
  }

  static saveUserProfile() {
    if (window.clevertap && window.clevertap.onUserLogin) {
      window.clevertap.onUserLogin.push({
        Site: {
          Identity: `${TokenStorage.getItem(APP_STORAGE.USER_HASH)}`,
        },
      });
    }
  }

  static addToProfile = obj => {
    if (window.clevertap && window.clevertap.profile) {
      const { userId } = this.getUserInfo();
      const ctObj = obj;
      ctObj.Identity = userId;
      window.clevertap.profile.push({
        Site: ctObj,
      });
    }
  };

  static pushCleverEvents = ({ event, data = {} }) => {
    let payload = {
      event,
      type: 'event',
      ...data,
      pageUrl: window.location.href,
    };
    payload = deleteEmptyValuesFromObject(payload);
    console.log(JSON.stringify(payload));
    this.saveCleverEvent({ event, evtData: payload });
  };
}
export default CleverEvents;
