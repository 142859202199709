import React from 'react';

function useFetchUserRefreshToken({ isUserLoggedIn, fn, tokenExpiryTime }) {
  const [isRefreshingUserToken, setRefreshUserToken] = React.useState(false);

  React.useEffect(() => {
    if (isUserLoggedIn) {
      const currentTime = Date.now();

      if (currentTime < tokenExpiryTime) {
        const diff = tokenExpiryTime - currentTime;
        const diffInMin = Math.floor(diff / 60000);
        if (diffInMin <= 5) {
          setRefreshUserToken(true);
          fn()
            .then(() => {
              setRefreshUserToken(false);
            })
            .catch(err => {
              setRefreshUserToken(false);
            });
        }
      } else {
        setRefreshUserToken(true);
        fn()
          .then(() => {
            setRefreshUserToken(false);
          })
          .catch(err => {
            setRefreshUserToken(false);
          });
      }
    }
  }, [fn, isUserLoggedIn, tokenExpiryTime]);
  return isRefreshingUserToken;
}

export default useFetchUserRefreshToken;
