import LiveSessionActionTypes from "./actionTypes";

const initialState = {
  addLoader: false,
  list: [],
  hasError: false,
  listLoader: false,
  hostList: [],
  batchList: [],
  pageNo: 0,
  noMoreRecords: false,
  visitingEnd: false,
  hostLoader:false,
  batchLoader:false,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  const newState = { ...state };
  switch (type) {
    case LiveSessionActionTypes.UPDATE_LOADER_STATE:
      return {
        ...newState,
        [payload.loaderType]: payload.value,
      };
    case LiveSessionActionTypes.SET_LIST: {
      const currentArr = JSON.parse(JSON.stringify(newState.list.concat(payload)));
      const returnArr = [];
      const ids = currentArr.map(item => item.id);
      const uniqueIds = [...new Set(ids)];

      uniqueIds.forEach(id => {
        const arr = currentArr.filter(dataItem => dataItem.id === id);
        returnArr.push(arr[0]);
      });
      return {
        ...newState,
        list: returnArr,
        // hasError: false,
        listLoader: false,
        pageNo: payload.length > 0 ? newState.pageNo + 1 : 1,
        noMoreRecords: payload.length === 0,
        visitingEnd: false,
      };
    }
    case LiveSessionActionTypes.SET_HOST_LIST: {
      // const currentArr = JSON.parse(JSON.stringify(newState.hostList.concat(payload)));
      // const returnArr = [];
      // const ids = currentArr.map(item => item.id);
      // const uniqueIds = [...new Set(ids)];

      // uniqueIds.forEach(id => {
      //   const arr = currentArr.filter(dataItem => dataItem.id === id);
      //   returnArr.push(arr[0]);
      // });
      return {
        ...newState,
        hostList: payload,
        // hasError: false,
        listLoader: false,
        hostLoader:false,
      };
    }

    case LiveSessionActionTypes.SET_BATCH_LIST: {
      // const currentArr = JSON.parse(JSON.stringify(newState.batchList.concat(payload)));
      // const returnArr = [];
      // const ids = currentArr.map(item => item.id);
      // const uniqueIds = [...new Set(ids)];

      // uniqueIds.forEach(id => {
      //   const arr = currentArr.filter(dataItem => dataItem.id === id);
      //   returnArr.push(arr[0]);
      // });
      return {
        ...newState,
        batchList: payload,
        // hasError: false,
        listLoader: false,
        batchLoader:false,
      };
    }

    case LiveSessionActionTypes.HAS_ERROR:
      return {
        ...newState,
        hasError: true,
        listLoader: false,
        addLoader: false,
        batchLoader:false,
        hostLoader:false,
      };
    case LiveSessionActionTypes.RESET_LIST:
      return {
        ...newState,
        list: [],
        hasError: false,
        listLoader: false,
        addLoader: false,
        noMoreRecords: false,
        pageNo: 0,
      };
    case LiveSessionActionTypes.VISIT_END_LIST: {
      return { ...newState, visitingEnd: true };
    }
    case LiveSessionActionTypes.ADD_LIVESESSION: {
      // const currentArr = JSON.parse(JSON.stringify(newState.list));
      // currentArr.push(payload);
      return { ...newState };
    }
    case LiveSessionActionTypes.UPDATE_SESSION: {
      const index = newState.list.findIndex(item => item.id === payload.id);
      let item = newState.list[index];

      item = {
        ...item,
        name: payload.name,
        description: payload.description,
        roomType: payload.roomType,
        sessionTime: payload.sessionTime,
        hostId: payload.hostId,
        sessionStartTime: payload.sessionStartTime,
        sessionEndTime: payload.sessionEndTime,
        sessionBatches:payload.sessionBatches,
      };
      newState.list[index] = item;
      return {
        ...newState,
      };
    }
    case LiveSessionActionTypes.DELETE_SESSION: {
      const index = newState.list.findIndex(item => item.id === payload);
      let item = newState.list[index];
      const { deleted } = item;
      item = {
        ...item,
        deleted: !deleted,
      };
      newState.list[index] = item;
      return {
        ...newState,
      };
    }
    default:
      return state;
  }
};
export default reducer;
