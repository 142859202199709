const LiveSessionActionTypes = {
  UPDATE_LOADER_STATE: "UPDATE_LOADER_STATE",
  SET_LIST: "SET_LIST",
  HAS_ERROR: "SESSION_HAS_ERROR",
  RESET_LIST: "RESET_LIST",
  ADD_LIVESESSION: "ADD_LIVESESSION",
  UPDATE_SESSION: "UPDATE_SESSION",
  DELETE_SESSION: "DELETE_SESSION",
  GET_HOST: "GET_HOST",
  SET_HOST_LIST: "SET_HOST_LIST",
  SET_BATCH_LIST: "SET_BATCH_LIST",
  VISIT_END_LIST: "VISIT_END__SESSION_LIST",

};
export default LiveSessionActionTypes;
