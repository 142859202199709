import styled from 'styled-components';
import StyleConstant from 'util/style-constants';

const ButtonStyle = styled.button`
  border-radius: 7px;
  background: ${(props) =>
    props.bgColor ? props.bgColor : StyleConstant.PRIMARY};
  cursor: ${(props) => (props.disabled ? `not-allowed` : ``)};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '18px')};
  font-weight: 600;
  width: ${(props) => (props.width ? props.width : '')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '0.7rem')};
  color: ${(props) =>
    props.disabled ? 'black' : props.color ? props.color : StyleConstant.WHITE};
  padding: ${(props) => (props.padding ? props.padding : `11px 17px`)};
  margin: ${(props) => (props.margin ? props.margin : `0px`)};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.1px'};
  border: ${(props) => (props.border ? props.border : '')};
  :disabled {
    color: #fff;
  }
`;

export const SecondaryButtonStyle = styled.button`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : `${StyleConstant.GREEN6}`};
  border-radius: 4px;
  color: ${(props) => (props.color ? props.color : `${StyleConstant.WHITE}`)};
  font-size: 0.7rem;
  font-weight: 600;
  line-height: 18px;
  padding: ${(props) => (props.padding ? props.padding : `21px 58px;`)};
  height: ${(props) => (props.height ? props.height : '')};
  width: ${(props) => (props.width ? props.width : '')};
  border: ${(props) => (props.border ? props.border : '')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '0.7rem')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '600')};
  margin: ${(props) => (props.margin ? props.margin : `0px`)};
  :disabled{
    color:#fff
  }
  /* background-color: ${(props) =>
    props.disabled ? '#efefef !important' : ''}; */
`;

export const LoaderButtonStyle = styled.button`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : `${StyleConstant.PRIMARY}`};
  border-radius: 4px;
  color: ${(props) => (props.color ? props.color : `${StyleConstant.WHITE}`)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '0.7rem')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '600')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '18px')};
  padding: ${(props) => (props.padding ? props.padding : `21px 58px;`)};
  height: ${(props) => (props.height ? props.height : '')};
  width: ${(props) => (props.width ? props.width : '')};
  border: ${(props) => (props.border ? props.border : '')};
  /* background-color: ${(props) =>
    props.disabled ? `${StyleConstant.PURPLE} !important` : ''}; */
  display: flex;
  justify-content: space-between;
  :disabled {
    opacity: 0.5;
    cursor: no-drop;
  }
  .loader-btn-text {
    margin-right: 5px;
  }
`;

export const IconButtonWrapper = styled.button`
  /* background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : `${StyleConstant.PURPLE}`}; */
  /* // border-radius: 4px; */
  color: ${(props) => (props.color ? props.color : `#E85697`)};
  font-size: 0.7rem;
  /* font-weight: 600; */
  line-height: 20px;
  /* padding: ${(props) => (props.padding ? props.padding : `21px 58px;`)}; */
  /* height: ${(props) => (props.height ? props.height : '')}; */
  /* width: ${(props) => (props.width ? props.width : '')}; */
  /* border: ${(props) => (props.border ? props.border : '')}; */
  /* background-color: ${(props) =>
    props.disabled ? '#efefef !important' : ''}; */
  .plus-img{
    height: 15px;
    position: relative;
    top: 3px;
  }
  .text{
    margin-left:8px;
  }
  background: transparent;
  .custom-icon{
    position: relative;
    top: 2px;
    left: 3px;
  }

`;

export const BackBtnStyleWrapper = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
`;

export default ButtonStyle;
