import TokenStorage from 'util/TokenStorage';
import { roleParser, cipher } from 'util/common';
import { APP_STORAGE } from 'util/app-constants';
import { ADMIN_LOGGING_IN } from './adminActionTypes';

const myCipher = cipher('mySecretSalt');

/* eslint-disable no-case-declarations */
const initialState = {
  // roleLoggedIn: !!localStorage.getItem(APP_STORAGE.ADMIN_ACCESS_TOKEN),
  userEmail: '',
  loading: false,
  industryList: [],
  roles: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  const newState = { ...state };
  switch (type) {
    case 'ADMIN_LOGIN':
      const {
        tokenDetails: {
          data: { access_token: token },
        },
        profileDetails: {
          data: { firstName, lastName, email, role: roles },
        },
      } = payload;
      const userRoles = myCipher(window.btoa(JSON.stringify(roleParser(roles))));

      TokenStorage.setItems([
        { name: APP_STORAGE.ADMIN_ACCESS_TOKEN, value: token },
        { name: APP_STORAGE.ADMIN_FIRST_NAME, value: firstName },
        { name: APP_STORAGE.ADMIN_LAST_NAME, value: lastName },
        { name: APP_STORAGE.ADMIN_EMAIL, value: email },
        { name: APP_STORAGE.ADMIN_ROLES, value: userRoles },
      ]);
      return {
        ...newState,
        loading: false,
        roleLoggedIn: true,
        roles: roleParser(roles),
      };
    case ADMIN_LOGGING_IN: {
      return { ...newState, loading: true };
    }
    case 'ADMIN_LOGIN_FAILED': {
      return { ...newState, loading: false };
    }
    case 'LOGOUT_ADMIN': {
      return { ...newState, roleLoggedIn: false };
    }
    default:
      return { ...newState };
  }
};
export default reducer;
