const UserLoginActionTypes = {
  TOGGLE_POPUP_LOGIN: 'TOGGLE_POPUP_LOGIN',
  TOGGLE_POPUP_LOGIN_STATE: 'TOGGLE_POPUP_LOGIN_STATE',
  TOGGLE_STATE_USER_LOGGED_IN: 'TOGGLE_STATE_USER_LOGGED_IN',
  ADMIN_LOGGED_IN: 'ADMIN_LOGGED_IN',
  ADMIN_LOGGED_OUT: 'ADMIN_LOGGED_OUT',
  USER_LOGGED_OUT: 'USER_LOGGED_OUT',
};

export default UserLoginActionTypes;
