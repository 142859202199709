const USER_FIELDS = {
  USER_FIELDS_JOB_ROLE_LOADING: 'USER_FIELDS_JOB_ROLE_LOADING',
  USER_FIELDS_JOB_FUNCTIONS_LOADING: 'USER_FIELDS_JOB_FUNCTIONS_LOADING',
  USER_FIELDS_CITIES_LOADING: 'USER_FIELDS_CITIES_LOADING',
  USER_FIELDS_SET_DATA: 'USER_FIELDS_SET_DATA',
  USER_FIELDS_LOADING_MICRO_CV: 'USER_FIELDS_LOADING_MICRO_CV',
  USER_FIELDS_SET_OBJ_PAYLOAD: 'USER_FIELDS_SET_OBJ_PAYLOAD',
  USER_FIELDS_LOADING_LMS_USER_PROFILE: 'USER_FIELDS_LOADING_LMS_USER_PROFILE',
  USER_FIELDS_LOADING_SET_LMS_USER_PROFILE_DATA: 'USER_FIELDS_LOADING_SET_LMS_USER_PROFILE_DATA',
  USER_FIELDS_LOADING_BATCH_DETAILS: 'USER_FIELDS_LOADING_BATCH_DETAILS',
  USER_FIELDS_SET_BATCH_DETAIL: 'USER_FIELDS_SET_BATCH_DETAIL',
  USER_FIELDS_RESET_STATE: 'USER_FIELDS_RESET_STATE',
  USER_FIELDS_STRENGTH: 'USER_FIELDS_STRENGTH',
  BUTTON_LOADING: 'BUTTON_LOADING',
};

export default USER_FIELDS;
