const ADMIN_LOGGING_IN = 'ADMIN_LOGGING_IN';
const ADMIN_LOGIN = 'ADMIN_LOGIN';
const ADMIN_LOGIN_FAILED = 'ADMIN_LOGIN_FAILED';

const JOB_ROLES = {
  SET_JOBROLES: 'SET_JOBROLES',
  SET_JOBFUNCTIONS: 'SET_JOBFUNCTIONS',
  SET_ALL_JOBFUNCTIONS: 'SET_ALL_JOBFUNCTIONS',
  JOB_FUNCTIONS_LOADING: 'JOB_FUNCTIONS_LOADING',
  ADDING_JOBROLE: 'ADDING_JOBROLE',
  RESET_JOBROLE: 'RESET_JOBROLE',
  UPDATING_JOBROLE: 'UPDATING_JOBROLE',
  UPDATE_JOBROLE: 'UPDATE_JOBROLE',
  DELETE_JOBROLE: 'DELETE_JOBROLE',
  SET_JOBROLES_AFTER_ADDING: 'SET_JOBROLES_AFTER_ADDING',
  JOB_FUNCTIONS_ERR: 'JOB_FUNCTIONS_ERR',
  SET_SKILL_LEVELS: 'SET_SKILL_LEVELS',
  UPDATING_JOB_FUNCTION: 'UPDATING_JOB_FUNCTION',
  UPDATE_JOB_FUNCTION: 'UPDATE_JOB_FUNCTION',
  ADDING_JOBFUNCTION: 'ADDING_JOBFUNCTION',
  DELETE_JOBFUNCTION: 'DELETE_JOBFUNCTION',
};

const INDUSTRY_TYPES = {
  INDUSTRIES_LOADING: 'INDUSTRIES_LOADING',
  SET_INDUSTRIES: 'SET_INDUSTRIES',
  UPDATING_INDUSTRY: 'UPDATING_INDUSTRY',
  UPDATE_INDUSTRY: 'UPDATE_INDUSTRY',
  DELETE_INDUSTRY: 'DELETE_INDUSTRY',
  ADDING_INDUSTRY: 'ADDING_INDUSTRY',
};
const SKILL_TYPE = {
  SET_SKILLS: 'SET_SKILLS',
  SKILLS_LOADING: 'SKILLS_LOADING',
  SKILLS_ERR: 'SKILLS_ERR',
  UPDATING_SKILL: 'UPDATING_SKILL',
  UPDATE_SKILL: 'UPDATE_SKILL',
  ADDING_SKILL: 'ADDING_SKILL',
  DELETE_SKILL: 'DELETE_SKILL',
};
const PAYMENT_TYPES = {
  PAYMENTS_LOADING: 'PAYMENTS_LOADING',
  SET_PAYMENTS: 'SET_PAYMENTS',
  PAYMENTS_ERR: 'PAYMENTS_ERR',
  RESET_STATE: 'RESET_STATE',
  UPDATE_PAYMENT: 'UPDATE_PAYMENT_STATUS',
  PAYMENT_SET_SEARCH: 'PAYMENT_SET_SEARCH',
  SET_ADMIN_PAYMENTS: 'SET_ADMIN_PAYMENTS',
};

const JOB_FUNCTION_TYPE = {
  SET_JOB_FUNCTIONS: 'SET_JOB_FUNCTIONS',
  JOB_FUNCTIONS_LOADING: 'JOB_FUNCTIONS_LOADING',
  JOB_FUNCTIONS_ERR: 'JOB_FUNCTIONS_ERR',
  UPDATING_JOB_FUNCTION: 'UPDATING_JOB_FUNCTION',
  UPDATE_JOB_FUNCTION: 'UPDATE_JOB_FUNCTION',
  ADDING_JOBFUNCTION: 'ADDING_JOBFUNCTION',
  DELETE_JOBFUNCTION: 'DELETE_JOBFUNCTION',
};
const ADMIN_HOME = {
  CHANGE_FILTER: 'CHANGE_FILTER',
  RESET_FILTER: 'RESET_FILTER',
};
export {
  ADMIN_LOGGING_IN,
  ADMIN_LOGIN,
  ADMIN_LOGIN_FAILED,
  JOB_ROLES,
  PAYMENT_TYPES,
  INDUSTRY_TYPES,
  SKILL_TYPE,
  JOB_FUNCTION_TYPE,
  ADMIN_HOME,
};
