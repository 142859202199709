import Job from '../../../services/Job';

export const JOB_LIST_REQUEST = 'JOB_LIST_REQUEST';
export const JOB_LIST_SUCCESS = 'JOB_LIST_SUCCESS';
export const JOB_LIST_FAILURE = 'TEAM_REPORT_LIST_FAILURE';

export const requestJobList = (params, loadMore) => ({
  type: JOB_LIST_REQUEST,
  params,
  isLoadingMore: loadMore,
});

export const receiveJobList = (jobs, loadMore) => ({
  type: JOB_LIST_SUCCESS,
  payload: jobs,
  isLoadingMore: loadMore,
});

export function fetchJobList(params = {}, loadMore = false) {
  return async (dispatch, getState) => {
    try {
      dispatch(requestJobList(params, loadMore));
      const response = await Job.fetch(params);
      dispatch(receiveJobList(response.data, loadMore));
      return response;
    } catch (e) {
      return e;
    }
  };
}
