import ApplicantModel from '../models/ApplicantModel';
import BaseService from '../services/BaseService';
class CandidateAdapter extends BaseService {
  candidateListResponseAdapter = (response) => {
    return response.map(
      (item) =>
        new ApplicantModel({
          ...item,
        })
    );
  };
}

export default CandidateAdapter;
