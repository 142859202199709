class SparkUserModel {
  constructor({
    userId,
    hash,
    name,
    userEmail,
    email,
    phone,
    uniqueUserName,
    photoUrl,
    id,
    accountId,
    orgId,
    orgUserId,
    communicationEmail,
    createDate,
    profilePercentage,
    placementCategory,
    learningCategory,
    highestEducation,
    emailVerified,
    phoneVerified,
    seekhoShareUrl,
    fullName,
    resumeUrl,
    totalWorkExp,
  }) {
    this.userId = userId;
    this.hash = hash;
    this.name = name;
    this.userEmail = userEmail;
    this.email = email;
    this.phone = phone;
    this.uniqueUserName = uniqueUserName;
    this.photoUrl = photoUrl;
    this.id = id;
    this.accountId = accountId;
    this.orgId = orgId;
    this.orgUserId = orgUserId;
    this.communicationEmail = communicationEmail;
    this.createDate = createDate;
    this.profilePercentage = profilePercentage;
    this.placementCategory = placementCategory;
    this.learningCategory = learningCategory;
    this.highestEducation = highestEducation;
    this.emailVerified = emailVerified;
    this.phoneVerified = phoneVerified;
    this.seekhoShareUrl = seekhoShareUrl;
    this.fullName = fullName;
    this.resumeUrl = resumeUrl;
    this.totalWorkExp = totalWorkExp;
  }
}

export default SparkUserModel;
