import Organisation from '../../../services/Organisation';

export const ORGANISATION_LIST_REQUEST = 'ORGANISATION_LIST_REQUEST';
export const ORGANISATION_LIST_SUCCESS = 'ORGANISATION_LIST_SUCCESS';
export const ORGANISATION_LIST_FAILURE = 'ORGANISATION_LIST_FAILURE';
export const ORGANISATION_PROXY_LOGIN = 'ORGANISATION_PROXY_LOGIN';

export const requestOrganisationList = (params, loadMore) => ({
  type: ORGANISATION_LIST_REQUEST,
  params,
  isLoadingMore: loadMore,
});

export const receiveOrganisationList = (organisationList, loadMore) => ({
  type: ORGANISATION_LIST_SUCCESS,
  payload: organisationList,
  isLoadingMore: loadMore,
});

export const proxyLoginOrganisation = orgData => ({
  type: ORGANISATION_PROXY_LOGIN,
  payload: orgData,
});

export function fetchOrganisationList(params, loadMore = false) {
  return async (dispatch, getState) => {
    try {
      dispatch(requestOrganisationList(params, loadMore));
      const response = await Organisation.fetch(params);
      dispatch(receiveOrganisationList(response.data, loadMore));
      return response;
    } catch (e) {
      return e;
    }
  };
}
