import styled from 'styled-components';

const LogoutWrapper = styled.div`
  .popup_pad {
    padding: 20px;
  }
  .do-you-want-to-text{
    color: #414141;
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
  }
  .no_btn {
    padding: 10px;
    color: var(--dark-blue);
    font-size: 0.8rem;
    background-color: #fff
  }
  .confrm_btns {
    margin-top: 50px;
    button {
    margin-left: 15px;
  }
`;

export default LogoutWrapper;
