import UPLOAD_PROGRESS_CONSTANTS from './action-types';

const initialState = {
  uploadProgress: 0,
};

const uploadProgressReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPLOAD_PROGRESS_CONSTANTS.UPDATE_UPLOADED_PROGRESS:
      return {
        uploadProgress: payload,
      };
    default:
      return { ...state };
  }
};
export default uploadProgressReducer;
