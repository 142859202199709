import services from './index';
import SEEKIFY_API from '../util/seekify-api-constants';
import { API_INFO } from '../util/app-constants';

class Job {
  fetchById = (id, params = {}) => {
    return services.getRequest(`${SEEKIFY_API.JOB.GET}/${id}`, {
      info: API_INFO.LMS_AUTH,
    });
  };

  fetch = (
    data = {
      pageNumber: 1,
    }
  ) => {
    return services.postRequest(
      `${SEEKIFY_API.JOB.LIST}?pageNumber=${data.pageNumber || 1}&pageSize=20`,
      data,
      {
        info: API_INFO.LMS_AUTH,
      }
    );
  };

  create = (data) => {
    return services.postRequest(SEEKIFY_API.JOB.POST, data, {
      info: API_INFO.LMS_AUTH,
    });
  };

  generateDescription = (data) => {
    return services.postRequest(
      `/rest/admin/v1/m/job/generate-job-description`,
      data,
      {
        info: API_INFO.LMS_AUTH,
        disableToast: true,
      }
    );
  };

  update = (id, data) => {
    return services.patchRequest(`${SEEKIFY_API.JOB.PUT}/${id}`, data, {
      info: API_INFO.LMS_AUTH,
    });
  };

  statusUpdate = (id, data) => {
    return services.patchRequest(
      `${SEEKIFY_API.JOB.STATUS}?jobId=${id}&status=${data.status}`,
      {},
      {
        info: API_INFO.LMS_AUTH,
      }
    );
  };

  getJobOrgs = (jobId) => {
    return services.getRequest(`${SEEKIFY_API.JOB.ORG}?jobId=${jobId}`, {
      info: API_INFO.LMS_AUTH,
    });
  };

  createJobOrg = (data) => {
    return services.postRequest(`${SEEKIFY_API.JOB.ORG}`, data, {
      info: API_INFO.LMS_AUTH,
    });
  };

  deleteJobOrg = (data) => {
    return services.deleteRequest(`${SEEKIFY_API.JOB.ORG}`, {
      info: API_INFO.LMS_AUTH,
      data,
    });
  };
}

export default new Job();
