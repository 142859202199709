import { PAYMENT_TYPES } from "../AdminLogin/adminActionTypes";

const initialState = {
  payments: [],
  paymentsLoading: false,
  hasError: false,
  pageNo: 0,
  noMoreRecords: false,
  visitingEnd: false,
  paymentSearchValue: "",
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  const newState = { ...state };
  switch (type) {
    case PAYMENT_TYPES.PAYMENTS_LOADING:
      return { ...newState, paymentsLoading: true };
    case "VISITING_END":
      return { ...newState, visitingEnd: true };
    case PAYMENT_TYPES.SET_ADMIN_PAYMENTS: {
      const currentArr = JSON.parse(JSON.stringify(newState.payments.concat(payload.content)));
      const returnArr = [];
      const ids = currentArr.map(item => item.id);
      const uniqueIds = [...new Set(ids)];

      uniqueIds.forEach(id => {
        const arr = currentArr.filter(dataItem => dataItem.id === id);
        returnArr.push(arr[0]);
      });
      return {
        ...newState,
        payments: returnArr,
        hasError: false,
        paymentsLoading: false,
        pageNo: payload.content.length > 0 ? newState.pageNo + 1 : 1,
        noMoreRecords: payload.content.length === 0,
        visitingEnd: false,
      };
    }
    case PAYMENT_TYPES.PAYMENTS_ERR:
      return { ...newState, hasError: true, paymentsLoading: false, visitingEnd: false };
    case PAYMENT_TYPES.RESET_STATE:
      return { ...newState, payments: [], pageNo: 0, noMoreRecords: false };
    case PAYMENT_TYPES.UPDATE_PAYMENT: {
      const index = newState.payments.findIndex(item => item.id === payload.id);
      let item = newState.payments[index];
      // console.log("pay",payload.status)
      item = {
        ...item,
        status: payload.status,
        adminRemark: payload.comment,
      };
      newState.payments[index] = item;
      return {
        ...newState,
      };
    }
    case PAYMENT_TYPES.PAYMENT_SET_SEARCH: {
      const { value } = payload;
      return { ...newState, paymentSearchValue: value };
    }
    default:
      return { ...state };
  }
};

export default reducer;
