const initialState = {
  data: null,
};

const videoScriptReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "setdata": {
      const data = { ...payload };
      return {
        ...state,
        data,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default videoScriptReducer;
