import FILTERS_TYPE from './action-types';

const initState = {
  isFilterOpen: false,
};

const filtersReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FILTERS_TYPE.FILTERS_TYPE_TOGGLE_FILTER: {
      return {
        ...state,
        isFilterOpen: payload,
      };
    }

    case FILTERS_TYPE.FILTERS_TYPE_RESET_STATE: {
      return {
        ...initState,
      };
    }

    default: {
      return state;
    }
  }
};

export default filtersReducer;
