import ActionTypes from './action-types';

const filters = {
  jobRole: [],
  jobFunction: null,
  location: null,
  industry: null,
};
const initialState = {
  jobList: [],
  jobListLoading: true,
  jobListError: false,
  pageNo: 0,
  jobRoles: [],
  currentPreference: [],
  filterValue: JSON.parse(JSON.stringify(filters)),
  appliedFilters: JSON.parse(JSON.stringify(filters)),
  showFilters: false,
  locations: [],
  skillLevels: [],
  skills: [],
  industries: [],
  jobFunctions: [],
  noMoreRecords: false,
  endLoader: false,
  noCurrentPreference: true,
  viewAccordingToMyPref: false,
};
const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  const newState = { ...state };
  switch (type) {
    case ActionTypes.SET_ENTITIES:
      return { ...newState, [payload.type]: payload.value };
    case ActionTypes.SET_CURRENT_PREF:
      return {
        ...newState,
        currentPreference: payload.preference,
        noCurrentPreference: payload.noCurrentPreference,
      };
    case ActionTypes.JOB_TOGGLE_FILTERS:
      return { ...newState, showFilters: payload };
    case ActionTypes.JOB_CANCEL_FILTER: {
      const { appliedFilters, filterValue } = newState;
      filterValue.location = appliedFilters.location;
      filterValue.jobRole = JSON.parse(JSON.stringify(appliedFilters.jobRole));
      filterValue.jobFunction = appliedFilters.jobFunction;
      filterValue.industry = appliedFilters.industry;
      return { ...newState, filterValue };
    }
    case ActionTypes.JOB_SET_FILTERS_VALUES: {
      const filterValue = JSON.parse(JSON.stringify(state.filterValue));
      filterValue[payload.filter] = payload.value;
      return { ...newState, filterValue };
    }
    case ActionTypes.JOB_SET_FILTERS: {
      const appliedFilters = JSON.parse(JSON.stringify(state.appliedFilters));
      const filterValue = JSON.parse(JSON.stringify(state.filterValue));
      const updatedFilters = { ...appliedFilters, ...payload.value };
      return { ...newState, appliedFilters: updatedFilters, filterValue };
    }

    case ActionTypes.CAND_JOB_LIST_LOADING:
      return { ...newState, jobListLoading: payload };

    case ActionTypes.CAND_SET_JOB_LISTING: {
      const currentArr = JSON.parse(JSON.stringify(newState.jobList.concat(payload.content)));
      const uniqueArr = [];
      const ids = currentArr.map(item => item.id);
      const uniqueIds = [...new Set(ids)];

      uniqueIds.forEach(id => {
        const arr = currentArr.filter(dataItem => dataItem.id === id);
        uniqueArr.push(arr[0]);
      });
      return {
        ...newState,
        jobList: uniqueArr,
        pageNo: payload.content.length > 0 ? newState.pageNo + 1 : 1,
        noMoreRecords: payload.totalElements === uniqueArr.length,
        endLoader: false,
        jobListLoading: false,
        jobListError: false,
      };
    }
    case ActionTypes.CAND_JOB_LIST_ERR:
      return { ...newState, jobListError: payload, jobListLoading: false };
    case ActionTypes.CAND_JOB_LIST_END_LOADER:
      return { ...newState, endLoader: payload };
    case ActionTypes.CAND_RESET_JOB_LIST:
      return {
        ...newState,
        jobList: [],
        pageNo: 0,
        noMoreRecords: false,
        jobListLoading: true,
        showFilters: false,
        viewAccordingToMyPref: false,
      };
    case ActionTypes.CAND_JOB_RESET_FILTERS:
      return {
        ...newState,
        filterValue: JSON.parse(JSON.stringify(filters)),
        appliedFilters: JSON.parse(JSON.stringify(filters)),
      };
    case ActionTypes.CAND_APPLY_FOR_JOB: {
      const jobs = JSON.parse(JSON.stringify(newState.jobList));
      const index = jobs.findIndex(item => item.id === payload);
      if (index < 0) {
        return { ...newState };
      }
      let item = jobs[index];

      item = {
        ...item,
        status: 'APPLIED',
      };
      jobs[index] = item;
      newState.jobList = jobs;
      return { ...newState };
    }
    case ActionTypes.CAND_RESET_PAGE_NO:
      return { ...newState, pageNo: 0 };
    case ActionTypes.CAND_SET_VIEW_OTHER: {
      return { ...newState, viewAccordingToMyPref: !state.viewAccordingToMyPref };
    }
    case ActionTypes.RESET_CURRENT_PREF:
      return { ...newState, currentPreference: [], noCurrentPreference: true };
    default:
      return newState;
  }
};

export default reducer;
