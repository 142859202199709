import React from "react";
import "react-toastify/dist/ReactToastify.css";
// import ToastStyle from "./toastStyle";
import Resources from "assets/Resources";

const ToastMsg = ({ msg = "Something went wrong", type }) => {
  return (
    <div className="toast-wrapper">
      <span className="toast-icon">
        <img src={getImageByType(type)} alt="" />
      </span>
      <span className="toast_msg">{msg}</span>
    </div>
  );
};

function getImageByType(type) {
  if (type === "info") return Resources.images.infoToast;
  if (type === "success") return Resources.images.smiling;
  return Resources.images.sad;
}

export default ToastMsg;
