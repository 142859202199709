/* eslint-disable global-require */
const Images = {
  seekifyLogo: require("assets/images/logo-colored.svg"),
  addMore: require("assets/images/add.svg"),
  trash: require("assets/images/trash.png"),
  arrow: require("assets/images/arrow.png"),
  editicon: require("assets/images/edit.svg"),
  sfl: require("assets/images/pink-add.svg"),
  logoutIcon: require("assets/images/logout.svg"),
  historyIcon: require("assets/images/time.svg"),
  editIcon: require("assets/images/Pencil.svg"),
  playIcon: require("assets/images/play-icon.svg"),
  bgLight: require("assets/images/bg.png"),
  bgLight1: require("assets/images/bg-lg.png"),
  playIconBig: require("assets/images/play-icon-big.svg"),
  seekhoImg1: require("assets/images/seekho1.svg"),
  mailIcon: require("assets/images/mail.svg"),
  phoneIcon: require("assets/images/Phone.svg"),
  downArrow: require("assets/images/arrowdown.svg"),
  rightarr: require("assets/images/right-arr.png"),
  playSm: require("assets/images/play-sm.svg"),
  pause: require("assets/images/pause.svg"),
  smActiveArrow: require("assets/images/sm-active-arrow.svg"),
  leftArrowActive: require("assets/images/left-arrow-active.svg"),
  play: require("assets/images/play.svg"),

  // USED IN APP CONSTANTS
  fbIcon: require("assets/images/facebook.svg"),
  fbActiveIcon: require("assets/images/facebook-active.svg"),
  linkedInIcon: require("assets/images/linkedin-1.svg"),
  linkedInActive: require("assets/images/linkedin-active.svg"),
  instaIcon: require("assets/images/insta.svg"),
  instaActiveIcon: require("assets/images/insta-active.svg"),
  twitterIcon: require("assets/images/twitter.svg"),
  twitterActiveIcon: require("assets/images/twitter-active.svg"),
  reportIcon: require("assets/images/report.png"),
  betterIndiaIcon: require("assets/images/better-india.svg"),
  indiaTodayIcon: require("assets/images/India-Today.png"),
  socialStoryIcon: require("assets/images/socialstory.svg"),
  startUpSuccess: require("assets/images/startup-success-stories.png"),
  pioneer: require("assets/images/pioneer.png"),
  hindustanTimes: require("assets/images/logo-ht.webp"),
  buisnessStandard: require("assets/images/business-standard-logo.png"),
  quoraIcon: require("assets/images/Quora.svg"),
  quoraActiveIcon: require("assets/images/quora-active.svg"),
  youtubeIcon: require("assets/images/youtube.svg"),
  youtubeActiveIcon: require("assets/images/youtube-active.svg"),
  educationBizz: require("assets/images/education-biz.png"),
  // **********

  Safexpress: require("assets/images/Safexpress.jpg"),
  testCross: require("assets/images/cross-test.svg"),
  playVideoIcon: require("assets/images/play-video.svg"),
  viewPdf: require("assets/images/view-pdf.svg"),
  assessmentBlack: require("assets/images/assesment-black.svg"),
  blackDropDown: require("assets/images/drop-down-arrow.svg"),
  videoScript: require("assets/images/video-script.svg"),
  location: require("assets/images/location.svg"),
  filter: require("assets/images/filter.svg"),
  sad: require("assets/images/sad.svg"),
  smiling: require("assets/images/smiling.svg"),
  infoToast: require("assets/images/info-toast.svg"),
  closeToast: require("assets/images/close-toast.svg"),
  rightArrClr: require("assets/images/right-arrow-clr.svg"),
  liveSession: require("assets/images/live-streaming.svg"),
  history: require("assets/images/history.svg"),
  noContent: require("assets/images/lost.png"),
  infoWarn: require("assets/images/info-warn.svg"),
  bottomLearning: require("assets/images/Education.png"),
  report: require("assets/images/Report.svg"),
  bottomJob: require("assets/images/Job.png"),
  bottomHome: require("assets/images/Home.png"),
  bottomTest: require("assets/images/workshop.png"),
  close: require("assets/images/close.svg"),
  whiteLogo: require("assets/images/white-logo.svg"),
  whiteRightArrow: require("assets/images/arrow-white-right.svg"),
  locked: require("assets/images/locked.svg"),
  accordionArrow: require("assets/images/accordion.svg"),
  playCourse: require("assets/images/Play-course.svg"),
  accordionArrowBlue: require("assets/images/Shape.svg"),
  continue: require("assets/images/Continue.svg"),
  complete: require("assets/images/complete.svg"),
  blackCheck: require("assets/images/blackTick.svg"),
  blackRefresh: require("assets/images/blackrefresh.svg"),
  community: require("assets/images/chat1.svg"),
  ctAssessment: require("assets/images/cta-assessment.svg"),
  ctCommunity: require("assets/images/cta-community.svg"),
  ctJob: require("assets/images/cta-job.svg"),
  liveSessionCam: require("assets/images/live-white-home.svg"),
  liveSessionCam1: require("assets/images/live_session.svg"),
  beginerTagLg: require("assets/images/beginer-lg.svg"),
  interTagLg: require("assets/images/intemidiate-lg.svg"),
  jobReady: require("assets/images/job-ready.svg"),
  analytics: require("assets/images/analytics.svg"),
  personalityDev: require("assets/images/personality-dev.svg"),
  sales: require("assets/images/sales.svg"),
  advanced: require("assets/images/advanced.svg"),

  // USED IN CONTENT BANNERS
  product1: require("assets/images/product1.svg"),
  product2: require("assets/images/product2.svg"),
  product3: require("assets/images/product3.svg"),
  classroomLearning: require("assets/images/classroomLearning.svg"),
  classroomLearningMobile: require("assets/images/class-room-learning-mobile.svg"),
  industryExpert: require("assets/images/industryExpert.svg"),
  industryExpertMobile: require("assets/images/industry-expert-mobile.svg"),
  liveTrainer: require("assets/images/liveTrainer.svg"),
  liveTrainerMobile: require("assets/images/live-trainer-mobile.svg"),
  Anil: require("assets/images/Anil-Ramsingar-Yadav.png"),
  Ashwani: require("assets/images/Ashwani-Pandey.png"),
  Atul: require("assets/images/Atul-Tripathi.png"),
  Harshal: require("assets/images/Harshal-Parikh.png"),
  Kitty: require("assets/images/Kitty-Tripathi.png"),
  Abhay_Malhotra: require("assets/images/Abhay_Malhotra.jpg"),
  Vandana: require("assets/images/Vandana.png"),
  Annirudh_Chaki: require("assets/images/Annirudh Chaki.jpeg"),
  Saurvandra_Bhati: require("assets/images/Saurvandra_Bhati.jpeg"),
  // ***********

  timeBadge: require("assets/images/time-badge.svg"),
  email_cv: require("assets/images/CV/email.png"),
  phone_cv: require("assets/images/CV/phone.png"),
  location_cv: require("assets/images/CV/location.png"),

  // USED IN XCEED UTILS
  indiaMart: require("assets/images/indiaMart.svg"),
  cars24: require("assets/images/cars24.png"),
  flipkart: require("assets/images/flipkart.svg"),
  oneMg: require("assets/images/oneMg.svg"),
  rivigo: require("assets/images/rivigo.svg"),
  fedex: require("assets/images/fedex.svg"),
  safeExpress: require("assets/images/safeExpress.svg"),
  xceedJob1: require("assets/images/xceed-job-1.svg"),
  xceedJob2: require("assets/images/xceed-job-2.svg"),
  xceedJob3: require("assets/images/xceed-job-3.svg"),
  xceedJob4: require("assets/images/xceed-job-4.svg"),
  ajeet: require("assets/images/ajeet.svg"),
  aman: require("assets/images/xceed/E_0ir8-x_400x400.jpg"),
  arhiant: require("assets/images/arhiant.svg"),
  yasho: require("assets/images/yasho.svg"),
  siemens: require("assets/images/siemens.svg"),
  freshWorks: require("assets/images/freshworks.svg"),
  google: require("assets/images/google.svg"),
  healthKart: require("assets/images/health-kart.svg"),
  urbanClap: require("assets/images/urban-clap.svg"),
  capitalOne: require("assets/images/captial-one.svg"),
  oneMg1: require("assets/images/oneMg1.svg"),
  diganta: require("assets/images/diganta.svg"),
  // ********

  xc1: require("assets/images/xc1.svg"),
  xc3: require("assets/images/xc3.svg"),
  xc4: require("assets/images/xc4.svg"),
  xc8: require("assets/images/xc8.svg"),
  xc9: require("assets/images/xc9.svg"),
  xc10: require("assets/images/xc10.svg"),
  xc11: require("assets/images/xc11.svg"),
  xc12: require("assets/images/xc12.svg"),
  xc13: require("assets/images/xc13.svg"),
  xc17: require("assets/images/xc17.svg"),
  xc18: require("assets/images/xc18.svg"),
  xc19: require("assets/images/xc19.svg"),
  xc27: require("assets/images/xc27.svg"),
  xc30: require("assets/images/xc30.svg"),
  xc35: require("assets/images/xc35.svg"),

  safeJobDark: require("assets/images/safejob-dark.svg"),
  facebookDark: require("assets/images/facebook-dark.svg"),
  twitterDark: require("assets/images/twitter-dark.svg"),
  linkedInDark: require("assets/images/linkedIn-dark.svg"),
  youtubeDark: require("assets/images/youtube-dark.svg"),
  quoraDark: require("assets/images/quora-dark.svg"),

  instaDark: require("assets/images/insta-dark.svg"),
  arnab: require("assets/images/arnab.jpg"),
  nikita: require("assets/images/nikita-sharma.jpg"),
  namrata: require("assets/images/Namrata-Chawla.jpg"),
  abhishekR: require("assets/images/Abhishek-Risbud.jpg"),

  dimple: require("assets/images/Dimple-Golani.jpg"),

  diamond: require("assets/images/diamond.svg"),
  flipKartSm: require("assets/images/flipkart-small.svg"),
  freshworksSm: require("assets/images/fresh-works-small.svg"),
  googleSm: require("assets/images/google-small.svg"),
  greenCheck: require("assets/images/green-check.svg"),
  healthKartSm: require("assets/images/health-kart-small.svg"),
  deloitte: require("assets/images/deloitte-small.svg"),
  tcs: require("assets/images/tcs-small.svg"),

  safexpresssm: require("assets/images/safeExpress-small.svg"),

  siemensSm: require("assets/images/siemens-small.svg"),
  urbanClapSm: require("assets/images/urban-clap-sm.svg"),
  safeducate: require("assets/images/safeducate.svg"),
  test1: require("assets/images/test1.jpg"),
  test2: require("assets/images/test2.jpg"),
  test3: require("assets/images/test3.jpg"),

  certificate: require("assets/images/Certificate.svg"),
  experts: require("assets/images/Experts.svg"),
  interviews: require("assets/images/Interviews.svg"),
  live: require("assets/images/live-white-home.svg"),

  homeGreenBg: require("assets/images/home-green-bg.svg"),

  homeClock: require("assets/images/home-clock.svg"),

  playSq: require("assets/images/play-sq.svg"),
  playSqActive: require("assets/images/play-sq-active.svg"),
  analyticsCover: require("assets/images/Analytics-cover.png"),
  bdCover: require("assets/images/Inside-Sales-cover.png"),
  operationsCover: require("assets/images/Operations-cover.png"),

  playSmallBlack: require("assets/images/play-small-black.svg"),
  playSmallWhite: require("assets/images/play-small-white.svg"),
  orangeCircleSm: require("assets/images/orange-circle-sm.svg"),

  greenBadge: require("assets/images/xceed/greenBadge.svg"),

  yellowLadder: require("assets/images/xceed/yellowLadder.svg"),
  pinkLadder: require("assets/images/xceed/pinkLadder.svg"),
  greenLadder: require("assets/images/xceed/greenLadder.svg"),
  lightGreenLadder: require("assets/images/xceed/lightGreenLadder.svg"),

  aditi: require("assets/images/xceed/aditi.jpeg"),
  olaCabs: require("assets/images/xceed/ola-cabs.png"),
  disney: require("assets/images/xceed/disney.png"),
  hotstar: require("assets/images/xceed/Hotstar_logo.svg"),

  safejobWhiteLogo: require("assets/images/safejobWhiteLogo.png"),
  sahil: require("assets/images/sahil.jpeg"),
  vaibhav: require("assets/images/Vaibhav.jpeg"),

  divya: require("assets/images/divya.jpg"),
  aboutusSafejobPhoto: require("assets/images/aboutus_safejob.jpg"),
  value1: require("assets/images/value1.png"),
  value2: require("assets/images/value2.png"),
  value3: require("assets/images/value3.png"),
  value4: require("assets/images/value4.png"),

  join_us: require("assets/images/final_joinus_image.jpg"),
  higher_edu_digest: require("assets/images/higher-education-digest.png"),
  telecom_today: require("assets/images/telecom-today-logo.jpg"),
  enterpreneur_india: require("assets/images/entrepreneur-india-logo.jpg"),
  businessworld: require("assets/images/business-world-logo.jpg"),
  the_hindu: require("assets/images/the-hindu-logo.jpg"),
  economictimes: require("assets/images/economic-times-logo.png"),
  indiatoday: require("assets/images/india_today.png"),
  financialexpress: require("assets/images/financial-express-logo.png"),

  increase_icon: require("assets/images/increase.png"),

  mountain_icon: require("assets/images/mountain.png"),
  pyramid_icon: require("assets/images/pyramid.png"),
  resume_template: require("assets/images/resume_template_jeetu.jpg"),
  register_gif: require("assets/images/register_gif.gif"),
  resume_gif: require("assets/images/resume_gif.gif"),
  download_gif: require("assets/images/download_gif.gif"),
  register_gif_mobile: require("assets/images/steps-gif(1).gif"),
  resume_gif_mobile: require("assets/images/steps-gif(2).gif"),
  download_gif_mobile: require("assets/images/steps-gif(3).gif"),
  chronometer_icon: require("assets/images/chronometer.png"),

  greenTick: require("assets/images/green-tick.svg"),

  bgLeft: require("assets/images/bgLeft.svg"),
  bgRight: require("assets/images/bgRight.svg"),
  certification: require("assets/images/certification.svg"),
  award: require("assets/images/award.svg"),
  seekhoLogo: require("assets/images/newSeekhoLogo.png"),
};

const Resources = {
  images: Images,
};

export default Resources;
