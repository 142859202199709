import DASHBOARD_TYPES from "./action-types";

const initialState = {
  learningPathFetched: false,
  learningPathData: {},
  course: {},
  lesson: [],
  lessonDetail: [],
  courseFetched: false,
  lessonFetched: false,
  lessonDetailFetched: false,
  hasError: false,
  pageNo: 1,
  noMoreRecords: false,
  visitingEnd: false,
  totalItems: 0,
  hideViewMore: false,
  rendered: false,
  currentLessonAssessments: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case DASHBOARD_TYPES.LEARNING_PATH_SUCESS: {
      const newState = { ...state };
      const { totalItems, data } = payload;
      const oldData = newState.learningPathData;
      if (data && Object.keys(data).length > 0) {
        Object.keys(data).forEach(dataTag => {
          if (oldData[dataTag]) {
            data[dataTag].forEach(dataVal => {
              oldData[dataTag].push(dataVal);
            });
          } else {
            oldData[dataTag] = data[dataTag];
          }
        });
        newState.learningPathData = { ...oldData };
        newState.totalItems = totalItems;
        newState.noMoreRecords = Object.keys(data).length === 0;
        newState.pageNo = Object.keys(data).length > 0 ? newState.pageNo + 1 : 1;
      } else {
        newState.hideViewMore = true;
      }
      newState.learningPathFetched = true;
      // newState.courseFetched = false;
      // newState.lessonDetailFetched = false;
      // newState.lessonFetched = false;
      newState.rendered = true;

      return {
        ...newState,
      };
    }
    case "LEARNING_PATH_SHOW_LOADER": {
      const newState = { ...state };
      newState.learningPathFetched = false;
      return {
        ...newState,
      };
    }

    case "LEARNING_PATH_ERR": {
      const newState = { ...state };
      newState.hasError = true;
      return {
        ...newState,
      };
    }
    case "RENDERED":
      const newState = { ...state };
      return { ...newState, rendered: true };

    case "RESET_LEARNING_PATH": {
      const newState = { ...state };
      newState.pageNo = 1;
      newState.learningPathFetched = false;
      newState.learningPathData = {};
      newState.rendered = false;
      newState.totalItems = 0;
      return {
        ...newState,
      };
    }

    case DASHBOARD_TYPES.COURSE_SUCCESS: {
      const newState = { ...state };
      const { data, courseFetching } = payload;
      newState.course = data;
      newState.courseFetched = courseFetching;
      newState.lessonFetched = false;
      newState.lessonDetailFetched = false;
      return {
        ...newState,
      };
    }
    case DASHBOARD_TYPES.RESET_FETCH_TRAININGS: {
      const newState = { ...state };
      newState.course = {};
      newState.courseFetched = false;
      return {
        ...newState,
      };
    }
    case DASHBOARD_TYPES.LESSON_SUCCESS: {
      const newState = { ...state };
      const { data, lessonFetching } = payload;
      newState.lesson = data;
      newState.lessonFetched = lessonFetching;
      return {
        ...newState,
      };
    }

    case DASHBOARD_TYPES.LESSON_DETAIL_SUCCESS: {
      const newState = { ...state };
      // const { lessonDetailFetching, data } = payload;
      const { lessonDetailFetching, data, assessments } = payload;

      newState.lessonDetail = data;
      newState.lessonDetailFetched = lessonDetailFetching;
      newState.currentLessonAssessments = assessments;
      newState.lessonFetched = false;
      newState.courseFetched = false;
      newState.learningPathFetched = false;
      return {
        ...newState,
      };
    }

    case DASHBOARD_TYPES.RESET_FLDS: {
      const newState = { ...state };
      newState.learningPathFetched = false;
      return {
        ...newState,
      };
    }
    case DASHBOARD_TYPES.UPDATE_LEARNING_PATH: {
      const newState = { ...state };
      const data = JSON.parse(JSON.stringify(newState.learningPathData));
      const LEARNING_PATH_KEY = "LEARNING_PATH";
      const { key, hash } = payload;
      if (!data[LEARNING_PATH_KEY]) {
        const learningPath = data[key];
        const filteredData = learningPath.filter((item, index) => item.hash !== hash);
        if (learningPath) {
          data[key] = filteredData;
          newState.learningPathData = data;
        }
      } else {
        const learningPath = data[LEARNING_PATH_KEY];
        const filteredData = learningPath.filter(item => item.hash !== hash);
        data[LEARNING_PATH_KEY] = filteredData;
      }
      return newState;
    }

    case DASHBOARD_TYPES.ENROLLMENT_LOADING: {
      const newState = { ...state };
      const data = JSON.parse(JSON.stringify(newState.learningPathData));
      const LEARNING_PATH_KEY = "LEARNING_PATH";
      const { key, hash, loading } = payload;
      if (!data[LEARNING_PATH_KEY]) {
        const learningPath = data[key];
        const filteredDataIndex = learningPath.findIndex(item => item.hash === hash);
        if (learningPath) {
          learningPath[filteredDataIndex].isLoading = loading;
          data[key] = learningPath;
        }
      } else {
        const learningPathArr = data[LEARNING_PATH_KEY];
        const filteredData = learningPathArr.find(obj => obj.hash === hash);
        if (filteredData) filteredData.isLoading = loading;
      }
      newState.learningPathData = data;
      return newState;
    }
    case DASHBOARD_TYPES.RESET_ATTACHED_ASSESSMENTS: {
      const newState = { ...state };
      return { ...newState, currentLessonAssessments: [] };
    }
    default:
      return state;
  }
};

export default reducer;
