import ADMIN_USERS_TYPES from './action-types';

const initState = {
  fetchingUsers: false,
  fetchingMoreUsers: false,
  page: 0,
  size: 20,
  content: [],
  totalElements: null,
  searchValue: '',
  fetchingProxyUserData: null,
};

const adminUsers = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADMIN_USERS_TYPES.FETCHING_USER:
      return {
        ...state,
        fetchingUsers: payload,
      };
    case ADMIN_USERS_TYPES.FETCHING_MORE_USERS:
      return {
        ...state,
        fetchingMoreUsers: payload,
      };
    case ADMIN_USERS_TYPES.FETCHED_USER: {
      const { content, totalElements, loadMore, number } = payload;
      return {
        ...state,
        content: loadMore ? [...state.content, ...content] : [...content],
        page: number,
        totalElements,
      };
    }
    case ADMIN_USERS_TYPES.UPDATE_SEARCH_VALUE: {
      return {
        ...state,
        searchValue: payload,
      };
    }
    case ADMIN_USERS_TYPES.CLEAR_SEARCH_VALUE: {
      return {
        ...initState,
      };
    }
    case ADMIN_USERS_TYPES.FETCHING_PROXY_USER_DATA: {
      return {
        ...state,
        fetchingProxyUserData: payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default adminUsers;
