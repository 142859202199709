import OrganizationActionTypes from "./actionTypes";

const initialState = {
  addLoader: false,
  list: [],
  hasError: false,
  listLoader: false,
  pageNo: 0,
  noMoreRecords: false,
  visitingEnd: false,
  orgSearchValue: "",
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  const newState = { ...state };
  switch (type) {
    case OrganizationActionTypes.UPDATE_LOADER_STATE:
      return {
        ...newState,
        [payload.loaderType]: payload.value,
      };
    case OrganizationActionTypes.SET_LIST: {
      const currentArr = JSON.parse(JSON.stringify(newState.list.concat(payload)));
      const returnArr = [];
      const ids = currentArr.map(item => item.id);
      const uniqueIds = [...new Set(ids)];

      uniqueIds.forEach(id => {
        const arr = currentArr.filter(dataItem => dataItem.id === id);
        returnArr.push(arr[0]);
      });
      return {
        ...newState,
        list: returnArr,
        hasError: false,
        listLoader: false,
        pageNo: payload.length > 0 ? newState.pageNo + 1 : 1,
        noMoreRecords: payload.length === 0,
        visitingEnd: false,
      };
    }
    case OrganizationActionTypes.HAS_ERROR:
      return {
        ...newState,
        hasError: true,
        listLoader: false,
        addLoader: false,
      };
    case OrganizationActionTypes.RESET_LIST:
      return {
        ...newState,
        list: [],
        hasError: false,
        listLoader: false,
        addLoader: false,
        noMoreRecords: false,
        pageNo: 0,
      };
    case OrganizationActionTypes.VISIT_END_LIST: {
      return { ...newState, visitingEnd: true };
    }
    case OrganizationActionTypes.ADD_ORGANIZATION: {
      // const currentArr = JSON.parse(JSON.stringify(newState.list));
      // currentArr.push(payload);
      return { ...newState };
    }
    case OrganizationActionTypes.UPDATE_ORG: {
      const index = newState.list.findIndex(item => item.id === payload.id);
      let item = newState.list[index];

      item = {
        ...item,
        name: payload.name,
        domain: payload.domain,
        url: payload.url,
      };
      newState.list[index] = item;
      return {
        ...newState,
      };
    }
    case OrganizationActionTypes.DELETE_ORG: {
      const index = newState.list.findIndex(item => item.id === payload);
      let item = newState.list[index];
      const { active } = item;

      item = {
        ...item,
        active: !active,
      };
      newState.list[index] = item;
      return {
        ...newState,
      };
    }

    case OrganizationActionTypes.ORG_SET_SEARCH: {
      const { value } = payload;
      return { ...newState, orgSearchValue: value };
    }
    case OrganizationActionTypes.ORG_RESET_REDIRECTION_DATA: {
      return { ...newState, orgSearchValue: "" };
    }
    default:
      return state;
  }
};
export default reducer;
