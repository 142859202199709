import {API_INFO} from "../util/app-constants";
import {handleUserLabel} from "../util/common";
import SEEKIFY_API from "../util/seekify-api-constants";
import services from "./index";

const validateEmail = (email) => {
  const regex = /\S+@\S+\.\S+/;
  return regex.test(email);
};

const validatePhone = (phone) => {
  return !isNaN(phone);
};

class AdminUser {
  fetch = async (params = {}) => {
    let phone;
    let userEmail;
    let name;
    const {
      pageNumber = 1,
      orgId = null,
      title = "",
      adminRolesUrlParam = '',
    } = params;
    if (title != "" && validatePhone(title)) {
      phone = title;
    } else if (title != "" && validateEmail(title)) {
      userEmail = title;
    } else if (title != "") {
      name = title;
    }
    if (phone && phone.length < 10) {
      return Promise.resolve();
    }
    const response = await services.getRequest(
      `${SEEKIFY_API.USERS.GET}?roles=${adminRolesUrlParam}`,
      {
        info: API_INFO.LMS_USER_SERVICE_WITHOUT_ORG,
        params: {
          name,
          userEmail,
          phone,
          pageNumber,
        },
        headers: orgId
          ? {
              "org-id": orgId,
            }
          : {},
      }
    );
    const { data: res = {} } = response || {};
    const { data = [] } = res;
    let newData = [];
    if (Array.isArray(data)) {
      newData = data.map((item) => {
        return {
          ...item,
          userLabel: handleUserLabel(item.name, item.userEmail, item.phone),
        };
      });
    }
    return {data: {...res, data: newData}};
  };
}

export default new AdminUser();
