import services from "services";
import {
  adminLoginCalls,
  saveUserDetails,
} from "../../../components/UserLogin/actions";
import AdminUser from "../../../services/AdminUser";
import { B2B_API } from "../../../util/api-constants";
import { API_INFO } from "../../../util/app-constants";
import { openLinkInNewTab } from "../../../util/common";
import { checkError } from "../../../util/error-handler";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const FETCHING_PROXY_USER_DATA = "FETCHING_PROXY_USER_DATA";

export const requestUserList = (params, loadMore) => ({
  type: USER_LIST_REQUEST,
  params,
  isLoadingMore: loadMore,
});

export const receiveUserList = (userList, loadMore) => ({
  type: USER_LIST_SUCCESS,
  payload: userList,
  isLoadingMore: loadMore,
});

export const fetchProxyUserData = (userHash, extraData = {}) => async (
  dispatch
) => {
  await dispatch({
    type: FETCHING_PROXY_USER_DATA,
    payload: { userHash, isFetchingProxyUserData: true },
  });
  return await services
    .getRequest(`${B2B_API.PROXY}/${userHash}`, {
      info: API_INFO.LMS_USER_SERVICE,
      headers:
        extraData && extraData.token
          ? {
              Authorization: `Bearer ${extraData.token}`,
            }
          : {},
    })
    .then(async (res) => {
      const data = (res && res.data) || {};
      const accessTokenMap = (data && data.accessTokenResponse) || {};
      if (accessTokenMap && Object.keys(accessTokenMap).length > 0) {
        if (extraData && extraData.isAdmin) {
          await dispatch(adminLoginCalls(accessTokenMap));
        } else {
          await dispatch(saveUserDetails(accessTokenMap));
          openLinkInNewTab(`/`);
        }
      }
    })
    .catch((err) => {
      checkError(err);
    })
    .finally(() => {
      dispatch({
        type: FETCHING_PROXY_USER_DATA,
        payload: { userId: null, isFetchingProxyUserData: false },
      });
    });
};

export function fetchUserList(params, loadMore = false) {
  return async (dispatch, getState) => {
    try {
      dispatch(requestUserList(params, loadMore));
      const response = await AdminUser.fetch(params);
      dispatch(receiveUserList((response && response.data) || {}, loadMore));

      return response;
    } catch (e) {
      return e;
    }
  };
}
