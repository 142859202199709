const ActionTypes = {
  LISTING_CHANGE_TAB: "LISTING_CHANGE_TAB",
  SET_ENTITIES: "SET_ENTITIES",
  SET_CURRENT_PREF: "SET_CURRENT_PREF",
  JOB_CANCEL_FILTER: "JOB_CANCEL_FILTER",
  JOB_TOGGLE_FILTERS: "JOB_TOGGLE_FILTERS",
  JOB_SET_FILTERS_VALUES: "JOB_SET_FILTERS_VALUES",
  JOB_SET_FILTERS: "JOB_SET_FILTERS",
  CAND_SET_JOB_LISTING: "CAND_SET_JOB_LISTING",
  CAND_JOB_LIST_LOADING: "CAND_JOB_LIST_LOADING",
  CAND_JOB_LIST_ERR: "CAND_JOB_LIST_ERR",
  CAND_JOB_LIST_END_LOADER: "CAND_JOB_LIST_END_LOADER",
  CAND_RESET_JOB_LIST: "CAND_RESET_JOB_LIST",
  CAND_JOB_RESET_FILTERS: "CAND_JOB_RESET_FILTERS",
  CAND_APPLY_FOR_JOB: "CAND_APPLY_FOR_JOB",
  CAND_RESET_PAGE_NO: "CAND_RESET_PAGE_NO",
  CAND_SET_VIEW_OTHER: "CAND_SET_VIEW_OTHER",
  RESET_CURRENT_PREF:"RESET_CURRENT_PREF",
};

export default ActionTypes;
