const LOGIN_API = {
  login: `/oauth/token`,
  getUserDetail: "/api/v1/users/contact-info",
  logout: `/api/v1/oauth/logout`,
  checkExist: `/api/v1/users/exist?login-id`,
  sendOtp: `/api/v1/users/otp/send-otp?login-id`,
  validateOtp: `/api/v1/users/otp/validate`,
  refreshLmsToken: `/api/v1/integrations/seekify_b2b/users/refresh`,
  validateAndSwapAccount: `/api/v1/users/otp/validate/swap-account`,
};

const USER_PROFILE = {
  USER_STRENGTH: "/api/v1/integrations/seekify_b2b/users/profile/strength",
  CHECK_PROFILE_COMPLETE: "/rest/admin/v1/users/profile/completeness",
};

const ASSESSMENT_API = {
  USER_HASH: `/api/v1/integrations/SEEKIFY_B2B/users`,
  ASSESSMENT_HASH: `/api/v1/integrations/SEEKIFY_B2B/assessments`,
  EVALUATE_ASSESSMENT: `/api/v1/integrations/seekify_b2b/assessments/evaluate`,
  ASSESSMENT_SCORE: `/api/v1/integrations/seekify_b2b/assessments/scores`,
  ASSESSMENT_HISTORY: `/api/v1/integrations/seekify_b2b/assessments/scores`,
  GENERATE_REPORT: `/api/v1/integrations/seekify_b2b/assessments/report`,
  DOWNLOAD_REPORT: `/api/v1/files?path`,
  EVALUATE: `/api/v1/integrations/seekify_b2b/assessments/evaluate?`,
  DETAIL: `/api/v1/integrations/seekify_b2b/assignments/assessment`,
  ASSESSMENT_HASH_1: `/api/v1/integrations/seekify_b2b/assessments?assessment-hash=`,
  ASSESSMENT_KEY_PUBLIC:
    "/api/v1/integrations/seekify_b2b/public/ASSESSMENT/ekey",
  ASSESSMENT_KEY: "/api/v1/integrations/seekify_b2b/ASSESSMENT/ekey",
};

export const AUTO_COMPLETE = `/public/api/content/autocomplete?title=`;

const MICRO_CV_API = {
  DEGREES: "/api/v1/degrees/",
  EXP: "/api/v1/options/EXPERIENCE",
  JOB_FUNC: "/api/v1/job-functions/",
  MICRO_CV: "/api/v1/microcv/",
  LOCATION: "/api/v1/locations/cities",
  CITY: "/public/api/job/master/city",
  JOB_ROLE: "/public/api/job/master/role",
  FILE_UPLOAD: "/api/v1/files",
  USERS: "/api/v1/users",
  UPLOAD_RESUME: "/rest/admin/v1/users/update/resume",
  USER_PROFILE_LMS: "/rest/admin/v1/users/profile",
};

export const LMS_PROFILE_API = {
  USER_RESUME_DETAILS: "/rest/admin/v1/users/resumes",
  USER_RESUME_DETAILS_PUBLIC: "/public/api/resume",
  CREATE_COMPANY: "/rest/admin/v1/company",
  COMPANY_LIST: "/rest/admin/v1/company/autocomplete",
  JOB_ROLE_LIST: "/public/api/job/master/role/autocomplete",
  BASIC_DETAILS: "/rest/admin/v1/users/update/profile",
  USER_CITY: "/rest/admin/v1/users/resumes/city",
  USER_SKILLS: "/rest/admin/v1/users/resumes/skills",
  USER_EDUCATION: "/rest/admin/v1/users/resumes/education",
  USER_CERTIFICATES: "/rest/admin/v1/users/resumes/certificates",
  USER_ACHIEVEMENTS: "/rest/admin/v1/users/resumes/achievements",
  USER_PROJECTS: "/rest/admin/v1/users/resumes/projects",
  USER_WORK_EXPERIENCE: "/rest/admin/v1/users/resumes/job/experience",
  USER_INTERESTS: "/rest/admin/v1/users/resumes/interests",
  USER_JOB_PREFERENCES: "/rest/admin/v1/users/resumes/job/preference",
  USER_HIGHEST_EDU: "/rest/admin/v1/users/update/highest/edu",
  USER_WORK_EXP_LATEST: "/rest/admin/v1/users/update/total/workexp",
};

const DASHBOARD_API = {
  LEARNING_PATH: "/api/v1/integrations/seekify_b2b/assignments/learning_path",
  COURSE: "/api/v1/integrations/seekify_b2b/assignments/course",
  LESSON: "/api/v1/integrations/seekify_b2b/assignments/lesson?id=",
  LESSON_DETAIL: "/api/v1/integrations/seekify_b2b/assignments/slide?id=",
  LESSON_PROGRESS: "/api/v1/integrations/seekify_b2b/assignments/progress",
  GET_CURRENT_LESSON_SLIDE:
    "/api/v1/integrations/seekify_b2b/assignments/progress",
  LEARNING_PATH_PUBLIC: "/api/v1/integrations/seekify_b2b/public/learning_path",
  LESSON_PUBLIC: "/api/v1/integrations/seekify_b2b/public/lesson?",
  LEARNING_PATH_SLUG_PUBLIC:
    "/api/v1/integrations/seekify_b2b/public/LEARNING_PATH/slug",
  LEARNING_PATH_SLUG: "/api/v1/integrations/seekify_b2b/LEARNING_PATH/slug",
  LEARNING_PATH_HASH: "/api/v1/integrations/seekify_b2b/LEARNING_PATH/hash",
  LEARNING_PATH_HASH_PUBLIC:
    "/api/v1/integrations/seekify_b2b/public/LEARNING_PATH/hash",
  LEARNING_PATH_KEY_PUBLIC:
    "/api/v1/integrations/seekify_b2b/public/LEARNING_PATH/ekey",
  LEARNING_PATH_KEY: "/api/v1/integrations/seekify_b2b/LEARNING_PATH/ekey",
};

const ADMIN_API = {
  GET_INDUSTRIES: "/api/v1/options/industry",
  SKILLS: "/api/v1/skills",
  DELETE_OPTION: "/api/v1/options/",
  JOB_ROLES: "/api/v1/job-roles/job-function",
  SKILL_LEVEL: "/api/v1/options/fetch/skill_level",
  EDIT_JOBROLE: "/api/v1/job-roles",
  PAYMENTS: "/api/v1/payments",
  UPDATE_INDUSTRY: "/api/v1/options",
  GET_INDUSTRIES_LIST: "/api/v1/options/fetch/industry",
  LMS_INDUSTRIES_LIST: "/rest/admin/v1/job/master/industry",
  LMS_PUBLIC_INDUSTRIES_LIST: "/public/api/job/master/industry",
  LMS_JOB_FUNCTIONS_LIST: "/rest/admin/v1/job/master/function",
  LMS_PUBLIC_JOB_FUNCTIONS_LIST: "/public/api/job/master/function",
  LMS_SKILLS_LIST: "/public/api/skills",
  LMS_PUBLIC_SKILLS_LIST: "/rest/admin/v1/skills",
  LMS_CITY_LIST: "/public/api/job/master/city",
  LMS_ORG_LIST: "/rest/admin/v1/org/filter",
  LMS_PUBLIC_ORG_LIST: "/rest/admin/v1/org/autocomplete",
  LMS_DEGREES_LIST: "/public/api/degree/list",
  GET_JOB_FUNCTIONS: "/api/v1/job-functions/fetch",
  GET_SKILLS_LIST: "/api/v1/skills/fetch",
  GET_JOB_ROLES: "/api/v1/job-roles/fetch",
  GET_ORGANIZATIONS: "/api/v1/organizations/fetch",
  ORGANIZATIONS: "/api/v1/organizations",
  GET_JOB_FUNCTIONS_BY_INDUSTRY: "/api/v1/job-functions/industry/",
  GET_LIVE_SESSION: "/api/v1/session/fetch",
  GET_LIVE_WEBINAR: "/api/v1/session/fetch/public",
  LIVESESSION: "/api/v1/session",
  GETHOST: "/api/v1/session/host",
  GETBATCH: "/api/v1/session/batch",
  JOIN_SESSION: "/api/v1/session/join",
  GET_CANDIDATE_COUNT: "/api/v1/candidates/count",
  USERS: "/api/v1/users",
  PROXY_USER: "/api/v1/oauth/proxy",
  SHORTLISTED_CANDIDATES: "/api/v1/candidates",
  LMS_SKILLS: "/rest/admin/v1/skills",
  ALL_JOB_ROLES: "/api/v1/job-roles/",
  ORGANIZATION_CREATE: "/rest/admin/v1/on-board/create-org",
  ORGANIZATION_EDIT: "/rest/admin/v1/org",
};

const PAYMENT_API = {
  DETAILS: "/api/v1/payments/seekify_b2b/",
  SAVE_PAYMENT_DETAILS: "/api/v1/payments/seekify_b2b",
  PAYMENT_CALLBACK: "/api/v1/payments/collect",
  CANCEL: "/api/v1/payments/cancel",
  STATUS: "/api/v1/payments/status",
  PAYMENT_STATUS:
    "/api/v1/integrations/seekify_b2b/LEARNING_PATH/payment-status",
  CREATE_ORDER: "/rest/admin/v1/orders/create",
  INITIATE_PAYMENT: "/rest/admin/v1/payment/initiate",
  ORDER: "/rest/admin/v1/orders",
};
const CANDIDATES = {
  FETCH: "/api/v1/candidates/fetch",
  UPDATE: "/rest/admin/v1/job/apply",
  INTERESTED_STATUS: "/rest/admin/v1/job/interest",
};

const LIVE_SESSON_API = {
  GET_LIVE_SESSION_DETAIL:
    "/api/v1/integrations/seekify_b2b/assignments/LIVE_SESSION",
  GET_SLOTS_DATA: "/api/v1/session/instance/",
  JOIN: "/api/v1/session/instance/join/",
  LIVE_SESSION_DETAIL_PUBLIC:
    "/api/v1/integrations/seekify_b2b/public/LIVE_SESSION",
  LIVE_SESSION_SLUG_PUBLIC:
    "/api/v1/integrations/seekify_b2b/public/LIVE_SESSION/slug",
  LIVE_SESSION_SLUG: "/api/v1/integrations/seekify_b2b/LIVE_SESSION/slug",
  LIVE_SESSION_KEY_PUBLIC:
    "/api/v1/integrations/seekify_b2b/public/LIVE_SESSION/ekey",
  LIVE_SESSION_KEY: "/api/v1/integrations/seekify_b2b/LIVE_SESSION/ekey",
  JOIN_NOW: `/live-content/join-now`,
};

const JOBS_API = {
  jobs: "/api/v1/jobs/template",
  GET_LIST: "/api/v1/jobs/template/fetch?",
  CREATE_JOBS: "/api/v1/jobs",
  GET_JOBS: "/api/v1/jobs/fetch?",
  DELETE_JOB_TEMPLATE: "/api/v1/jobs/template/delete",
  GET_JOB_DATA: "/api/v1/jobs/count",
  DELETE_JOB: "/api/v1/jobs/delete",
  GET_CANDIDATE_JOBS: "/rest/admin/v1/job/filter",
  GET_CANDIDATE_JOBS_PUBLIC: "/public/api/job/filter",
  JOB_DETAIL: "/rest/admin/v1/job",
  JOB_DETAIL_PUBLIC: "/public/api/job",
};

const CONTACT_US_API = {
  saveData: "/api/v1/contact/us",
};

const CERTIFICATE_API = {
  GENERATE: `/api/v1/integrations/seekify_b2b/certificate`,
  DOWNLOAD: "/api/v1/files",
};

const LEARNING_API = {
  LMS_TOKEN: `/api/v1/integrations/seekify_b2b/users/admin`,
  CONTENT: `/rest/admin/v1/content/filter/fetch`,
  COURSE: `/api/v1/integrations/seekify_b2b/assignments/course`,
  DESCRIPTION: `/rest/admin/v1/content`,
  ASSESSMENT_ENROLL: `/api/v1/payments/seekify_b2b`,
  ENROLLED_CONTENT: `/api/v1/integrations/seekify_b2b/assignments/user/`,
  RECOMMENDED_CONTENT: `/rest/admin/v1/recommendation/content`,
};

const CERTFICATE_API = {
  CHECK_IF_CERTIFICATE_AVAILABLE: `/api/v1/integrations/seekify_b2b/LEARNING_PATH/certificate`,
};

export const LEARNING_TYPES = {
  LEARNING_TAB: 1,
  ASSESSMENT_TAB: 2,
  WEBINAR_TAB: 3,
};

export const B2B_API = {
  CONTENT: "/rest/admin/v1/assignments/user",
  ASSESSMENT: "/rest/admin/v1/assessment",
  ASSESSMENTV2: "/rest/admin/v2/assessment",
  PUBLIC_CONTENT_EKEY: "/public/api/content/key",
  PRIVATE_CONTENT_EKEY: "/rest/admin/v1/content/key",
  PUBLIC_CONTENT_HASH: "/public/api/content/hash",
  PRIVATE_SLOTS_DATA: "/rest/admin/v1/live-content/fetch/instance",
  PUBLIC_SLOTS_DATA: "/public/api/live/content/fetch/instance",
  SLIDES: "/rest/admin/v1/slides",
  MARK_SLIDES_PROGRESS: "/rest/admin/v1/assignments/progress",
  PRIVATE_CONTENT_HASH: "/rest/admin/v1/content/hash",
  START_ASSESSMENT: "/rest/admin/v2/assessment",
  QUESTION_TIMEOUT: "/rest/admin/v2/assessment/question/timed-out",
  MARK_LIVE_SESSION_COMPLETE:
    "/rest/admin/v1/live-content/instance/progress/complete",
  NOTIFY_ME: "/rest/admin/v1/live-content/notify/me",
  SAVE_ASSESSMENT_OPTION: "/rest/admin/v2/assessment/save/option",
  SAVE_TIME_ELAPSED:
    "/rest/admin/v2/assessment/hash/save/time-elapsed?timeElapsed",
  ASSIGN_CONTENT: "/rest/admin/v1/assignments/enroll/",
  CHECK_ISSUED_CERTIFICATE: "/rest/admin/v1/assignments/issue/certificate/",
  DOWNLOAD_ISSUED_CERTIFICATE: "/rest/admin/v1/certificate/download/",
  USER_EXIST: "/rest/admin/v1/users/exists/phone?userPhone",
  USER_EXIST_IN_ORG: "/rest/admin/v1/users/org/exists/phone?userPhone",
  SUB_DOMAIN_EXIST: "/rest/admin/v1/org/domain/exists",
  CREATE_USER: "/rest/admin/v1/users/create/phone",
  SEND_OTP: "/rest/admin/v1/users/generate/otp?phone",
  GET_AUTH_TOKEN: "/oauth/otp/token",
  O_AUTH_TOKEN: "/oauth/token",
  WHO_AM_I: "/rest/admin/v1/users/whoami",
  PROXY: "/proxy",
  UPCOMING_WORKSHOP: "/rest/admin/v1/live-content/learner/upcoming",
  STAGE_LEAD: "/rest/admin/v1/program/lead/stage",
  REFERRAL_CHECK: "/rest/admin/v1/users/valid/referral",
  ENROLL_WORKSHOP: "/rest/admin/v1/live-content/enroll/instance",
  UN_ENROLL_WORKSHOP: "/rest/admin/v1/live-content/unenroll/instance",
  UPLOAD_VIDEO: "/rest/admin/v1/videos",
  UPLOAD_IMAGE: "/rest/admin/v1/images",
  UPDATE_VISUME: "/rest/admin/v1/users/update/visume",
  INSTRUCTOR_DETAIL: "/public/api/instructor",
  ORG_DETAILS_BY_ID: "/rest/admin/v1/org/id",
};

export const LMS_API = {
  TAG_FILTER: "/public/api/tag/filter?familySlug=",
};

export const XCEED_API = {
  CHECK_ELIGIBILITY: "/rest/admin/v1/lead",
  GET_BATCH_DETAIL: "/rest/admin/v1/batch/upcoming?xceedProgram=",
};
export const ASSESSMENT_RESULT_STATUS = {
  EVALUATION_PENDING: "EVALUATION_PENDING",
};

export const INTERVIEW_EXP_VIDEOS = {
  VIDEO_FETCH: "/public/api/video/filter",
};
export {
  LOGIN_API,
  ASSESSMENT_API,
  MICRO_CV_API,
  DASHBOARD_API,
  PAYMENT_API,
  ADMIN_API,
  JOBS_API,
  CANDIDATES,
  CONTACT_US_API,
  USER_PROFILE,
  CERTIFICATE_API,
  LEARNING_API,
  LIVE_SESSON_API,
  CERTFICATE_API,
};
