const DASHBOARD_TYPES = {
  LEARNING_PATH_SUCESS: "LEARNING_PATH_SUCESS",
  COURSE_SUCCESS: "COURSE_SUCCESS",
  LESSON_SUCCESS: "LESSON_SUCCESS",
  LESSON_DETAIL_SUCCESS: "LESSON_DETAIL_SUCCESS",
  RESET_FLDS: "RESET_FLDS",
  UPDATE_LEARNING_PATH: "UPDATE_LEARNING_PATH",
  ENROLLMENT_LOADING: "ENROLLMENT_LOADING",
  LEARNING_PATH_FETCH_RESET: "LEARNING_PATH_FETCH_RESET",
  LEARNING_PATH_FETCHED_AGAIN: "LEARNING_PATH_FETCHED_AGAIN",
  RESET_FETCH_TRAININGS: "RESET_FETCH_TRAININGS",
  RESET_ATTACHED_ASSESSMENTS:"RESET_ATTACHED_ASSESSMENTS",
};
Object.freeze(DASHBOARD_TYPES);

export default DASHBOARD_TYPES;
