/* eslint-disable react/jsx-fragments */
import React from 'react';
import Resources from 'assets/Resources';
import uuid from 'uuid';

export const ROLES = {
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_B2B_ADMIN: 'ROLE_B2B_ADMIN',
  ROLE_ORG_ADMIN: 'ROLE_ORG_ADMIN',
  ROLE_TRAINER: 'ROLE_TRAINER',
};

const MICRO_CV_CONSTANTS = {
  isEducationInfoCompleted: 'isEducationInfoCompleted',
  isJobPreferenceCompleted: 'isJobPreferenceCompleted',
  isUserInfoCompleted: 'isUserInfoCompleted',
  isPreviousJobsCompleted: 'isPreviousJobsCompleted',
  USER_INFO: 1,
  EDUCATION_INFO: 2,
  JOB_PREFERENCE: 3,
};

const REDIRECT_CONSTANTS = {
  DUMMY: '/dummy',
  DASHBOARD: '/home',
  HOME: '/',
  MICROCV: '/micro-cv',
  LOGIN: '/login',
  ASSESSMENT: '/assessment',
  CONTACT_US: '/contact-us',
  MY_TRAININGS: '/trainings',
  EMP_LOGIN: '/',
  ADMIN_LOGIN_URI: '/',
  ABOUT_US: '/about-us',
  EMP_HOME: '/emp/',
  RESUME: '/profile',
  LEARNING_ASSESSMENT: '/tests',
  LEARNING_LEARNINGPATH: '/courses',
  LEARNING_WEBINAR: '/workshops',
  LEARNINGS: '/browse',
  PAYMENT_HISTORY: '/payment-history',
  BROWSE: '/browse',
  JOBS: '/jobs',
  JOB_DESCRIPTION: '/jobs/description',
  JOB_APPLIED: '/jobs/applied',
  MY_ASSESSMENTS: '/my-assessments',
  PAYMENT_STATUS: '/collect',
  CHECKOUT_INFO: '/checkout/info',
  LIVE_SESSION: '/live-session',
  RETAKE_ASSESSMENT: '/retake-assessment',
  ASSESSMENT_RESULT: '/test/result',
  UPLOAD_VIDEO: '/upload-video',
  MY_COURSES: '/my-courses',
  EXPLORE: '/explore',
  TAG: '/tag',
  LESSON: '/lesson',
  USER_VERIFICATION: '/link-mobile-email',
  XCEED: '/xceed',
  XCEED_ANALYTICS: '/xceed-analytics',
  XCEED_LOGISTICS_SCM: '/xceed-logistics-scm',
  XCEED_INSIDE_SALES_OLD: '/xceed-inside-sales',
  XCEED_INSIDE_SALES: '/xceed-business-development', // Inside Sales in now Business Development
  XCEED_BUSINESS_DEVELOPMENT: '/xceed-business-development',
  XCEED_OPERATIONS: '/xceed-operations',
  XCEED_DIGITAL_MARKETING: '/xceed-digital-marketing',
  XCEED_INTERVIEW_PREP: '/xceed-career-essentials',
  PLACEMENT_BOARD: '/placement-board',
  TERMS_OF_SERVICE: '/terms-of-service',
  PRIVACY_POLICY: '/privacy-policy',
  ADMIN_LOGIN: '/admin-login',
  RECRUITER_PROXY: '/recruiter/proxy',
  ADMIN_HOME: '/admin',
  ADMIN_SKILLS: '/admin/skills',
  ADMIN_PAYMENTS: '/admin/payments',
  ADMIN_ORGANIZATIONS: '/admin/organizations',
  ADMIN_TEMPLATES: '/admin/templates',
  ADMIN_TEMPLATES_ADD: '/admin/templates/add',
  ADMIN_TEMPLATES_EDIT: '/admin/templates/edit',
  ADMIN_TEMPLATES_DETAIL: '/admin/templates/details',
  ADMIN_LIVE_SESSION: '/admin/livesession',
  ADMIN_USERS: '/admin/users',
  ADMIN_CHALLENGES: '/admin/challenges',
  ADMIN_CHALLENGES_ADD: '/admin/challenges/add',
  ADMIN_CHALLENGES_EDIT: '/admin/challenges/edit',
  ADMIN_CHALLENGES_DETAIL: '/admin/challenges/details',
  ADMIN_INDUSTRIES: '/admin/industries',
  EMP_DASHBOARD: '/emp/dashboard',
  EMP_JOBS: '/emp/jobs',
  EMP_ASSESSMENT: '/emp/assessment',
  EMP_ASSESSMENT_REPORT: '/emp/assessment/report',
  EMP_CANDIDATES: '/emp/candidates',
  EMP_JOB_CANDIDATES: '/emp/jobs/candidates',
  SEEKHO_URL: '/interview-prep',
  SEEKHO_OLD: '/seekho',
  FREE_COURSE: '/courses/?courseType=free',
  PREMIUM_COURSE: '/courses/?courseType=premium',
  JOB_APPLICATION: '/jobs',
  ADMIN_JOB_FUNCTIONS: '/admin/jobfunctions',
  ADMIN_JOB_ROLES: '/admin/jobroles',
  RESUME_BUILDER: '/resume-builder',
  SEEKHO_HOME: 'https://seekho.ai/',
  COLLEGE: '/college',
  COLLEGE_OVERVIEW: '/college/overview',
  COLLEGE_TRAINING: '/college/training',
  COLLEGE_TRAINING_CLASS: '/college/training/class',
  COLLEGE_TRAINING_ASSESSMENT: '/college/training/assessment',
  COLLEGE_TRAINING_ASSESSMENT_REPORT: '/college/training/assessment/report',
  COLLEGE_TRAINING_ATTENDANCE: '/college/training/class/attendance',
  COLLEGE_STUDENTS: '/college/students',
  COLLEGE_TRAINERS: '/college/trainers',
  COLLEGE_CURRICULUMS: '/college/curriculums',
  COLLEGE_LEADERBOARD: '/college/leaderboard',
  COLLEGE_PLACEMENT_CELL: '/college/placement-cell',
  COLLEGE_EXTRA_CURRICULAR_ACTIVITIES: '/college/extra-curricular',
  COLLEGE_CONTENT_LIBRARY: '/college/content-library',
  COLLEGE_COMMUNICATIONS: '/college/communications',
  COLLEGE_FINANCIALS: '/college/financials',
};

export const USER_ROLES = {
  ADMIN: 'admin',
  EMP: 'emp',
};

const ERR_CONSTANTS = {
  401: 'Your session has expired! Please log in again',
  404: 'Not found',
  500: 'Internal Server Error',
  ASSESSMENT: {
    409: 'You have already submitted your assessment',
    404: 'Assessment not found',
  },
  MICROCV: {},
  DASHBOARD: {},
};

const DASHBOARD_CONSTANTS = {
  NAVBAR_LINKS: [
    {
      id: '3',
      name: 'Learning',
      redirect: REDIRECT_CONSTANTS.LEARNING_LEARNINGPATH,
    },
    { id: '4', name: 'Jobs', redirect: '/jobs' },
    { id: '6', name: 'Profile', redirect: '/profile' },
  ],
  SIDE_BAR_LINKS: [
    { id: '6', name: 'Profile', redirect: '/profile' },
    { id: '7', name: 'Payment History', redirect: '/payment-history' },
    {
      id: '8',
      name: 'My Assessments',
      redirect: `${REDIRECT_CONSTANTS.LEARNING_ASSESSMENT}?q=enrolled`,
    },
    {
      id: '12',
      name: 'My Courses',
      redirect: `${REDIRECT_CONSTANTS.MY_COURSES}`,
    },
    {
      id: '14',
      name: 'My Workshops',
      redirect: `${REDIRECT_CONSTANTS.LEARNING_WEBINAR}?q=enrolled`,
    },
  ],
  TRAININGS: 'TRAININGS',
  LESSONS: 'LESSONS',
};

export const ADMIN_PERMISSIONS = {
  VIEW_INDUSTRIES: 'VIEW_INDUSTRIES',
  EDIT_INDUSTRIES: 'EDIT_INDUSTRIES',
  VIEW_JOB_FUNCTION: 'VIEW_JOB_FUNCTION',
  EDIT_JOB_FUNCTION: 'EDIT_JOB_FUNCTION',
  VIEW_JOB_ROLES: 'VIEW_JOB_ROLES',
  EDIT_JOB_ROLES: 'EDIT_JOB_ROLES',
  VIEW_SKILLS: 'VIEW_SKILLS',
  EDIT_SKILLS: 'EDIT_SKILLS',
  VIEW_PAYMENTS: 'VIEW_PAYMENTS',
  VIEW_ORGANIZATIONS: 'VIEW_ORGANIZATIONS',
  EDIT_ORGANIZATIONS: 'EDIT_ORGANIZATIONS',
  VIEW_JOB_TEMPLATES: 'VIEW_JOB_TEMPLATES',
  EDIT_JOB_TEMPLATES: 'EDIT_JOB_TEMPLATES',
  PROXY_LOGIN: 'PROXY_LOGIN',
  VIEW_JOBS: 'VIEW_JOBS',
  EDIT_JOBS: 'EDIT_JOBS',
  VIEW_CANDIDATE: 'VIEW_CANDIDATE',
  EDIT_CANDIDATE: 'EDIT_CANDIDATE',
  VIEW_DASHBOARD: 'VIEW_DASHBOARD',
};

const ADMIN_LINKS = [
  {
    id: '1',
    name: 'Organisations',
    redirect: REDIRECT_CONSTANTS.ADMIN_ORGANIZATIONS,
    access: ADMIN_PERMISSIONS.VIEW_ORGANIZATIONS,
  },
];

export const EMP_CONSTANTS = {
  ALL: 'All',
  IN_PROCESS: 'in_process',
  HIRED: 'OFFER_EXTENDED',
  SHORT_LISTED: 'SHORTLISTED',
  SELECTED_FOR_INTERVIEW: 'SELECTED_FOR_INTERVIEW',
  SELECTED_FOR_INTERVIEW1: 'SELECTED FOR INTERVIEW',
  FINAL_INTERVIEW_SCHEDULED: 'FINAL_INTERVIEW_SCHEDULED',
  OFFER_ACCEPTED: 'OFFER_ACCEPTED',
  JOINED: 'JOINED',
  REJECTED: 'REJECTED',
  APPLIED: 'APPLIED',
  WITHDRAWN: 'WITHDRAWN',
  INTERESTED: 'INTERESTED',
};

export const ORG_TYPE_CONSTANTS = {
  COMPANY: 'COMPANY',
  COLLEGE: 'COLLEGE',
};

export const REJECTION_REASON_CONSTANTS = {
  INCOMPLETE_APPLICATION: 'INCOMPLETE_APPLICATION',
  IRRELEVANT_EDUCATION: 'IRRELEVANT_EDUCATION',
  IRRELEVANT_WORK_EXPERIENCE: 'IRRELEVANT_WORK_EXPERIENCE',
  INAPPROPRIATE_VIDEO: 'INAPPROPRIATE_VIDEO',
  PROCESS_VIOLATION: 'PROCESS_VIOLATION',
  OFFER_LETTER_REJECTED: 'OFFER_LETTER_REJECTED',
  OTHER: 'OTHER',
};

export const statusMenu = [
  { label: 'Interested', value: EMP_CONSTANTS.INTERESTED },
  { label: 'Applied', value: EMP_CONSTANTS.APPLIED },
  { label: 'Shortlisted', value: EMP_CONSTANTS.SHORT_LISTED },
  {
    label: 'Final Interview Scheduled',
    value: EMP_CONSTANTS.FINAL_INTERVIEW_SCHEDULED,
  },
  { label: 'Hired', value: EMP_CONSTANTS.HIRED },
  { label: 'Offer Accepted', value: EMP_CONSTANTS.OFFER_ACCEPTED },
  { label: 'Joined', value: EMP_CONSTANTS.JOINED },
  { label: 'Rejected', value: EMP_CONSTANTS.REJECTED },
];

export const individualRejectionReasons = [
  {
    label: 'Incomplete Application',
    value: REJECTION_REASON_CONSTANTS.INCOMPLETE_APPLICATION,
  },
  {
    label: 'Irrelevant Education',
    value: REJECTION_REASON_CONSTANTS.IRRELEVANT_EDUCATION,
  },
  {
    label: 'Irrelevant Work Experience',
    value: REJECTION_REASON_CONSTANTS.IRRELEVANT_WORK_EXPERIENCE,
  },
  {
    label: 'Process Violation',
    value: REJECTION_REASON_CONSTANTS.PROCESS_VIOLATION,
  },
  {
    label: 'Offer Letter Rejected',
    value: REJECTION_REASON_CONSTANTS.PROCESS_VIOLATION,
  },
  {
    label: 'Uploaded Video is inappropriate',
    value: REJECTION_REASON_CONSTANTS.OFFER_LETTER_REJECTED,
  },
  { label: 'Other Reason', value: REJECTION_REASON_CONSTANTS.OTHER },
];

export const bulkRejectionReasons = [
  {
    label: 'Incomplete Application',
    value: REJECTION_REASON_CONSTANTS.INCOMPLETE_APPLICATION,
  },
  {
    label: 'Irrelevant Education',
    value: REJECTION_REASON_CONSTANTS.IRRELEVANT_EDUCATION,
  },
  {
    label: 'Irrelevant Work Experience',
    value: REJECTION_REASON_CONSTANTS.IRRELEVANT_WORK_EXPERIENCE,
  },
  {
    label: 'Process Violation',
    value: REJECTION_REASON_CONSTANTS.PROCESS_VIOLATION,
  },
  {
    label: 'Uploaded Video is inappropriate',
    value: REJECTION_REASON_CONSTANTS.OFFER_LETTER_REJECTED,
  },
  { label: 'Other Reason', value: REJECTION_REASON_CONSTANTS.OTHER },
];

export const rejectStatusMenu = [
  { label: 'Applied', value: EMP_CONSTANTS.APPLIED },
  { label: 'Shortlisted', value: EMP_CONSTANTS.SHORT_LISTED },
];

export const fitmentResultMenu = [
  { label: 'Passed', value: 'true' },
  { label: 'Failed', value: 'false' },
];

export const jobStatsMenu = [
  { label: 'Live', value: 'LIVE' },
  { label: 'Expired', value: 'EXPIRED' },
  { label: 'Draft', value: 'DRAFT' },
  { label: 'Closed', value: 'CLOSED' },
];

export const CANDIDATE_STATUS_LIST = [
  { label: 'Applied', value: EMP_CONSTANTS.APPLIED },
  { label: 'Shortlisted', value: EMP_CONSTANTS.SHORT_LISTED },
  {
    label: 'Selected For Interview',
    value: EMP_CONSTANTS.SELECTED_FOR_INTERVIEW,
  },
  {
    label: 'Selected For Interview',
    value: EMP_CONSTANTS.SELECTED_FOR_INTERVIEW1,
    hideInMenu: true,
  },
  { label: 'Hired', value: EMP_CONSTANTS.HIRED },
  { label: 'Rejected', value: EMP_CONSTANTS.REJECTED },
];

const COMMON_NAVBAR_LINKS = [
  {
    id: '10',
    name: 'Recruiter Home',
    redirect: 'https://learn.safejob.in/recruitment/',
    isExternal: true,
  },
  {
    id: '11',
    name: 'Blog',
    redirect: 'https://safejob.in/blog',
    className: '',
    isExternal: true,
    doNotPassSsoToken: true,
  },
  {
    id: '13',
    name: 'About Us',
    redirect: '/about-us',
    className: '',
    hideInWeb: true,
  },
  {
    id: '14',
    name: 'Resume Builder',
    redirect: '/resume-builder',
    className: '',
    hideInWeb: true,
  },
];

const PAYMENT_CONSTANTS = {
  PAYMENT_REDIRECT: '/payment',
  BUY_NOW: 'BUY_NOW',
  BOOK_SEAT: 'BOOK_SEAT',
  MAIN_ORDER: 'MAIN_ORDER',
  INITIATED: 'INITIATED',
  PAID: 'PAID',
  PREPAY: 'PREPAY',
};

const PAYMENT_STATUS = {
  AWAITING: 'AWAITING_APPROVAL',
  IN_PROGRESS: 'IN_PROGRESS',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
  PENDING: 'PENDING',
  CANCEL: 'CANCEL',
  PAID: 'PAID',
};

export const LEARNING_CONST = {
  ASSESSMENT_TYPE: 'ASSESSMENT',
  WEBINAR_TYPE: 'LIVE_SESSION',
  LP_TYPE: 'LEARNING_PATH',
  ABOUT: 'About',
  CERTIFICATION: 'Certification',
  INSTRUCTOR: 'Instructor',
  FAQ: 'FAQs',
  SYLLABUS: 'Syllabus',
  COURSE_DETAILS: 'Course Details',
  CERTIFICATE_ID: 'lp-detail__certification',
  ABOUT_ID: 'lp-detail__about',
  SYLLABUS_ID: 'lp-detail__syllabus',
  COURSE_DETAILS_ID: 'lp-detail__course__details',
  INSTRUCTOR_ID: 'lp-detail__instructor',
  FAQ_ID: 'lp-detail__faq', // to be modified
  SKILLS: 'Skills',
  SKILL_ID: 'lp-detail__skills',
  HIGHLIGHTS: 'Highlights',
  HIGHLIGHTS_ID: 'lp-detail__highlights',
  LEARNING_PATHS: 'Learning Path',
  COURSES: 'Courses',
  LEARNING_PATHS_ID: 1,
  ASSESSMENT: 'Tests',
  ASSESSMENT_ID: 2,
  LIVE_CLASSES: 'Workshops',
  LIVE_CLASSES_ID: 3,
  UPCOMING_SESSIONS: 'Upcoming Session(s)',
  PAST_SESSIONS: 'Recorded Session(s)',
  BOOKED_SESSIONS: 'Booked Session(s)',
  UPCOMING_SESSIONS_ID: 'workshop_upcoming_session',
  PAST_SESSIONS_ID: 'workshop_past_session',
  BOOKED_SESSIONS_ID: 'workshop_booked_session',
  OPEN_ASSESSMENT: 'open-assessment',
};

const STRINGS = {
  ABOUT_US_1:
    'Customer experience is the only true differentiator in today’s world for consumers that are discerning and selective about products & services that they use.',
  ABOUT_US_2:
    'It’s only a ‘Wow’ customer experience that can help keep customers loyal to the product or service. But at the same time it is the hardest thing to do for businesses. This is because of multiple touch points for consumers both online and offline and across multiple devices, on one side and disparate software and different internal teams to deliver the right customer experience for businesses on the other. The complexity is furthered as these are spread across different points in time in the customer lifecycle.',
  ABOUT_US_3: (
    <React.Fragment>
      <strong>Seekify is a Customer Experience (CX)Automation platform </strong>{' '}
      with a human-touch to help your business grow. Seekify’s ‘Supernova’
      allows businesses to set customer experience goals such as Net Promoter
      Score (NPS), integrate into multiple CX softwares like Help-desk, Chat,
      Survey tools etc to aggregate all data points from silos.{' '}
      <strong>
        Seekify provides actionable insights through ‘Spotlight’ that are
        personalised and contextual for customer service agents to deliver
        superb customer experience.
      </strong>{' '}
      Seekify’s machine learning and artificial intelligence based engine
      ‘Supernova’ even automates the actions needed to improve their CX - hence
      closing the loop for the end customers.
    </React.Fragment>
  ),
  ABOUT_US_4: (
    <React.Fragment>
      Seekify is the only Customer Experience Automation platform that brings
      the customer experience together. We do this by integrating across all
      your softwares &amp; teams to provide contextual actionable insights. We
      enable CX agents to deliver that ‘Wow’ customer experience and smartly
      automate redundant tasks.
    </React.Fragment>
  ),
  ABOUT_US_5: (
    <React.Fragment>
      We are passionate about customer experience. We want to use automation
      technology to aid CX agents and leverage data for actionable insights to
      deliver that ‘wow’ customer experience to your customers so they convert
      into loyal customers and evangelise for your product or service.
    </React.Fragment>
  ),
  ABOUT_US_6: (
    <React.Fragment>
      <p>
        Young India today demands personalised job-readiness and placement
        experiences. Unfortunately, job portals aren’t good enough to solve this
        effectively. This is where Seekho comes in. We are a personalised job
        readiness and placement platform. We understand your needs and tailor a
        personalised plan that makes you ready for the jobs of today and
        tomorrow.
      </p>
      <br />
      <p>
        To ensure we provide you with a holistic experience, we equip you with
        expertly crafted training modules, personalised advice from our career
        counsellors, interview preparations and a power-packed resume that is
        sure to leave your recruiters impressed. If that wasn’t enough, we even
        work with you to get you that job you’ve dreamt of.
      </p>
      <br />
      <p>
        Developed by some of the best minds from IIT, Cambridge and Stanford,
        Seekho is on a mission to solve the job readiness and placement
        challenges faced by Indian youth. Our A.I. based platform is designed to
        understand individual requirements and provide a personalised experience
        for every candidate. We believe that India’s youth has the will and
        capacity to drive our nation towards a beautiful future. We want to do
        our bit by giving them a chance to become a contributing member of our
        society and the workforce. For businesses and recruiters, we help you
        hire individuals that are job-ready and more likely to succeed.
      </p>
    </React.Fragment>
  ),
  CAREERS_1: (
    <React.Fragment>
      <p>
        Today the onus of driving the Indian economy lies on the vast ocean of
        subcontinental millennials. This is the generation in their 20 something
        which is eager to grab a job, realise their dreams and keep the engine
        of the economy running. But can they? Do they have the means to keep the
        engine running? It’s not easy! Unfortunately, the appalling state of
        education and severe lack of skilling opportunities has failed our
        millennials. Sadly, our youth remains vastly under-developed and
        unprepared for today’s jobs. As a result, they remain largely
        unemployed.
      </p>
      <p>
        We cannot sit back with our arms crossed and allow an entire generation
        of youth to get lost in the mire of hopelessness. At Seekho we are on a
        mission to solve the job-readiness and unemployment challenges the
        Indian youth faces today. We want to give the youth not just a
        revolutionary platform that enables them to learn, explore, improve and
        succeed. More than that, we want to give them hope. We need like-minded
        individuals like you with whom we can solve this problem together.
      </p>
    </React.Fragment>
  ),
  home: [
    {
      id: 1,
      img: `${process.env.REACT_APP_AMAZON_BASE}/public/website-content/Testimonial+1.jpg`,
      title: 'Why training is important.',
      name: 'Jatin Kumar',
      videoResumeLink: `${process.env.REACT_APP_AWS_TESTIMONIAL}public/website-content/JATINKUMAR(Testimonials).mp4`,
    },
    {
      id: 2,
      img: `${process.env.REACT_APP_AMAZON_BASE}/public/website-content/Testimonial+2.jpg`,
      title: 'How to get your dream job.',
      name: 'Harshal Sharma',
      videoResumeLink: `${process.env.REACT_APP_AWS_TESTIMONIAL}public/website-content/Harshal(Testimonials).mp4`,
    },
    {
      id: 3,
      img: `${process.env.REACT_APP_AMAZON_BASE}/public/website-content/Testimonial+3.jpg`,
      title: 'Guidance and Confidence is the key.',
      // desc: "Now a sales consultant with Infosys",
      name: 'Shivangi Sharma',
      videoResumeLink: `${process.env.REACT_APP_AWS_TESTIMONIAL}public/website-content/SHIVANGISHRMA.mp4`,
    },
    // {
    //   id: 4,
    //   img: Resources.images.test5,
    //   title: "Knowledge without action is meaningless",
    //   name: "Abhishek"
    // }
  ],
  FIELD_EMPTY_MSG: 'Field can not be empty.',
  ERR_MSG: 'Something went wrong.',
  UNAUTH_MSG: 'Unauthorized Access',
  WHAT_IS_SAFEJOB: (
    <React.Fragment>
      <p>
        <a
          href={process.env.REACT_APP_FRONTEND}
          className="text-decoration-underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          Seekho
        </a>{' '}
        is a digital platform that provides our youth their dream jobs. This is
        through a completely online training and placement program with a job
        guarantee. Our vision is to solve India&apos;s employability problem
        where the majority of our graduates are unemployable. We want to
        transform them through an engaged learning program and launch their
        careers.
      </p>
      <br />
      <p>
        Young India today demands personalised experiences - not just in their
        job search but in everything. Unfortunately, job portals aren’t good
        enough to solve this effectively. This is where Seekho comes in. We are
        a personalised job readiness and placement platform. We understand a
        candidate’s needs and tailor a personalised plan that makes them ready
        for the jobs of today and tomorrow.
      </p>
    </React.Fragment>
  ),
  WHAT_DO_WE_DO: (
    <React.Fragment>
      <p>
        Our flagship program for Job Readiness and Placement is called Xceed. We
        currently run programs on{' '}
        {/* <a
          href={`${process.env.REACT_APP_FRONTEND}${REDIRECT_CONSTANTS.XCEED_ANALYTICS}`}
          className="text-decoration-underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          Analytics
        </a>
        ,{' '} */}
        <a
          href={`${process.env.REACT_APP_FRONTEND}${REDIRECT_CONSTANTS.XCEED_BUSINESS_DEVELOPMENT}`}
          className="text-decoration-underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          Business Development
        </a>
        ,{' '}
        <a
          href={`${process.env.REACT_APP_FRONTEND}${REDIRECT_CONSTANTS.XCEED_OPERATIONS}`}
          className="text-decoration-underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          Operations
        </a>{' '}
        and{' '}
        <a
          href={`${process.env.REACT_APP_FRONTEND}${REDIRECT_CONSTANTS.XCEED_LOGISTICS_SCM}`}
          className="text-decoration-underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          Logistics and SCM
        </a>
        . Here, we provide a 3 month online training and then get our students
        placed with reputable employers. We guarantee placement. Please visit
        the pages to get a better understanding of the offering, commercials and
        FAQs.
      </p>
      <br />
      <p>
        Recently launched, we already have thousands of students doing online
        training with us from all parts of India. We conduct classes live as
        well as on demand/ recorded. You can see some of our courses available{' '}
        <a
          href={`${process.env.REACT_APP_FRONTEND}${REDIRECT_CONSTANTS.LEARNING_LEARNINGPATH}`}
          className="text-decoration-underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>{' '}
        for free.
      </p>
    </React.Fragment>
  ),
  OUR_STORY: (
    <React.Fragment>
      <p>
        Seekho was born in a Covid world, with the coming together of Safeducate
        and Seekify. The founders are{' '}
        <a
          href="https://www.linkedin.com/in/divya-jain-7aaba030/"
          className="text-decoration-underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          Divya
        </a>
        ,{' '}
        <a
          href="https://www.linkedin.com/in/ajeetsk/"
          className="text-decoration-underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ajeet
        </a>{' '}
        and{' '}
        <a
          href="https://www.linkedin.com/in/arihant-jain-15016b2/"
          className="text-decoration-underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          Arihant
        </a>
        . Divya has built a formidable business in Safeducate over the last 10
        years that provides skilling and training to over 50K students every
        year. Ajeet and Arihant have helped build multiple successful tech
        startups in the past decade including HealthKart, 1mg, Joe Hukum and
        Freshworks.
      </p>
      <br />
      <p>
        We believe that India’s youth has the will and capacity to drive our
        nation towards a beautiful future. We want to do our bit by giving them
        a chance to become a contributing member of our society and the
        workforce. For businesses and recruiters, we help you hire individuals
        that are job-ready and more likely to succeed.
      </p>
      <br />
      <p>
        We are joined by a team with amazing work experience and great
        education. Overall, we are a very motivated and driven team!
      </p>
    </React.Fragment>
  ),
};

const FILTERS = {
  ACTIVE: 0,
  InACTIVE: 1,
  ALL: 2,
};

const VIDEO_FORMATS = [
  'mp4',
  'wmv',
  'avi',
  'MPEG-4',
  'm4v',
  'mov',
  'quicktime',
];

const mediaData = {
  grp1: [
    {
      id: 2,
      title: Resources.images.buisnessStandard,
      text:
        "10 digital skills students need for today's education (Educational Notes)",
      link:
        'https://www.business-standard.com/article/news-ians/10-digital-skills-students-need-for-today-s-education-educational-notes-118121300346_1.html',
    },
    {
      id: 3,
      title: Resources.images.betterIndiaIcon,
      text:
        'Meet the Amazing Woman Turning Truck Containers Into Portable Solar Schools!',
      link:
        'https://www.thebetterindia.com/161911/truck-container-school-safeducate-news/',
      style: {
        position: 'relative',
        left: '-75px',
      },
    },
    {
      id: 4,
      title: Resources.images.indiaTodayIcon,
      text:
        'A foreign education can help expand horizons and sharpen business instincts. Five entrepreneurs on why going global works',
      link:
        'https://www.indiatoday.in/magazine/education/story/20190923-an-international-affair-1598306-2019-09-13',
      style: {
        position: 'relative',
        left: '-50px',
      },
    },
    {
      id: 1,
      title: Resources.images.pioneer,
      text: 'Eliminate Fear Factor',
      link:
        'https://www.dailypioneer.com/2019/vivacity/eliminate-fear-factor.html',
    },
    {
      id: 5,
      title: Resources.images.educationBizz,
      text:
        'Safeducate Is Transforming The Face Of Skill Development And Training In India',
      link:
        'https://www.franchiseindia.com/education/safeducate-is-transforming-the-face-of-skill-development-and-training-in-india.12337',
    },
  ],
  grp2: [
    {
      id: 1,
      title: Resources.images.startUpSuccess,
      text:
        'Safeducate organises Car-O-Job campaign, a tech-led branding the activity aimed at spreading awareness about its Seekho portal',
      link:
        'https://startupsuccessstories.in/safeducate-organises-car-o-job-campaign-a-tech-led-branding-the-activity-aimed-at-spreading-awareness-about-its-safe-job-portal/',
      style: {
        position: 'relative',
        left: '-50px',
      },
    },
    {
      id: 2,
      title: Resources.images.hindustanTimes,
      text: 'Steps to becoming a successful micro-preneur',
      link:
        'https://www.hindustantimes.com/education/steps-to-becoming-a-successful-micro-preneur/story-4kmw3AVxJSxxjSrl3CjZyI.html',
    },
    {
      id: 3,
      title: Resources.images.tribune,
      text: 'Spirited Sheos',
      link: 'https://www.tribuneindia.com/news/archive/spirited-sheos-686318',
    },
    {
      id: 4,
      title: Resources.images.tribune,
      text: 'Training Turf',
      link: 'https://www.tribuneindia.com/news/archive/training-turf-651289',
    },
    {
      id: 5,
      title: Resources.images.socialStoryIcon,
      text:
        'From a book on truck drivers to schools made of containers, Divya Jain is transforming the logistics industry',
      link: 'https://yourstory.com/2018/08/truck-drivers-divya-jain/',
    },
  ],
};

export const APP_STORAGE = {
  ACCESS_TOKEN: 'access_token',
  CAND_REFRESH_TOKEN: 'candidate_refreshToken',
  EXPIRE_TIME: 'expire_time',
  USER_LOGGED_IN: 'user_logged_in',
  CAND_EMAIL: 'candidate_email',
  USER_INFO: 'user_info',
  MICRO_CV_DATA: 'microCvData',
  ADMIN_ACCESS_TOKEN: 'admin_access_token',
  ADMIN_USER_INFO: 'ADMIN_USER_INFO',
  ADMIN_FIRST_NAME: 'admin_firstname',
  ADMIN_LAST_NAME: 'admin_lastname',
  ADMIN_EMAIL: 'admin_email',
  ADMIN_ROLES: 'roles',
  ADMIN_REFRESH_TOKEN: 'adminRefreshToken',
  ADMIN_USER_HASH: 'ADMIN_USER_HASH',
  ORIGIN: 'origin',
  MEDIUM: 'signin_medium',
  ISUSEREXIST: 'is_user_exist',
  TAKE_ASSESSMENT_CLICKED: 'TAKE_ASSESSMENT_CLICKED',
  TAKE_SAT_CLICKED: 'TAKE_SAT_CLICKED',
  SELECTED_LP_HASH: 'selectedLearningPathHash',
  SELECTED_LP_SLUG: 'selectedLearningPathSlug',
  B2B_ACCESS_TOKEN: 'B2B_ACCESS_TOKEN',
  B2B_REFRESH_TOKEN: 'B2B_REFRESH_TOKEN',
  B2B_USER_HASH: 'B2B_USER_HASH',
  PARENT_HASH: 'PARENT_HASH',
  RECORDED_VIDEO: 'RECORDED_VIDEO',
  TARGET: 'target',
  CONTENT_HASH_FOR_LIVE_SESSION: 'CONTENT_HASH_FOR_LIVE_SESSION',
  USER_HASH: 'USER_HASH',
  AUTO_APPLY_JOB: 'AUTO_APPLY_JOB',
  USER_TOKEN_EXPIRE_TIME: 'USER_TOKEN_EXPIRE_TIME',
  ADMIN_TOKEN_EXPIRE_TIME: 'ADMIN_TOKEN_EXPIRE_TIME',
  ORG_ID: 'ORG_ID',
  IS_SEEKHO_ADMIN: 'IS_SEEKHO_ADMIN',
  ORG_TYPE: 'ORG_TYPE',
};

export const TAGS = {
  CERTIFICATE: 'certificate',
  PREMIUM: 'premium',
  FAST_TRACK: 'fasttrack_course',
  MASTER_CLASS: 'masterclass_course',
  MASTER_CERTIFICATE: 'master_course',
  SURAKSHA_COURSE: 'suraksha_course',
  PAID_CONTENT: 'paid content',
  CERTIFICATE_V2: 'certification',
  CONTENT_LABELS: 'content labels',
  ALL: 'all',
  JOB_FUNCTION: 'job function',
  SKILLS: 'Skills',
  COMPETENCY: 'competency',
  CUSTOM_FILTERS: 'custom filters',
  PRIVATE: 'private',
  ACCESS: 'access',
  CONTENT_TYPE: 'content type',
  TRENDING_COURSES: 'trending_courses',
  FREE_COURSES: 'free courses',
  SEQUENTIAL: 'sequential',
  BEGINNER: 'Beginner',
  ADVANCED: 'Advanced',
  DIFFICULT: 'Difficult',
  INTERMEDIATE: 'Intermediate',
  EXPLORE: 'explore',
  DIFFICULITY_LEVEL: 'Difficulty Level',
  CONTENT_TYPE_1: 'content-type',
  XCEED: 'Xceed',
  LIVE_CLASS: 'Live Class',
};

export const PAYMENT_CHECKOUT = {
  LEARNING_PATH: {
    name: 'LEARNING_PATH',
    id: 1,
    url: '/api/v1/integrations/seekify_b2b/public/learning_path?id',
    successUrl: `${REDIRECT_CONSTANTS.LEARNING_LEARNINGPATH}/`,
    getSuccessUrl(hash, contentUrl) {
      return `${this.successUrl}${contentUrl}`;
    },
  },
};

export const ENROLLMENT_CONSTANTS = {
  VIEW_MY_TRAININGS: 'View in my trainings',
  AWAITING_APPROVAL: 'Awaiting Approval',
  ENROLL: 'ENROLL',
  AWAITING_ASSIGNMENT: 'Processing order',
  PAYMENT_PENDING: 'Payment pending',
};

export const TRACKER_STATS_CONSTANTS = {
  ENROLLED: 'ENROLLED',
  FINISHED: 'FINISHED',
  IN_PROGRESS: 'IN_PROGRESS',
  NOT_YET_STARTED: 'NOT_YET_STARTED',
};

export const PAYMENT_MODE = {
  PAYTM: 'PAYTM',
  RAZORPAY: 'RAZORPAY',
  PAYU: 'PAYU',
};

export const EVENTS = {
  SIGN_UP: 'signUp',
  LOGIN: 'login',
  LP_ENROLL_SUCCESS: 'LP enroll',
  LP_DOWNLOAD_CERTIFICATE: 'LP download certificate',
  LP_PROCEED_TO_PAY: 'LP proceed to pay',
  SAT_COMPLETION: 'SAT completion',
  LP_ENROLL_TAP: 'LP Enroll Tap',
  RESUME_COMPLETE: 'Resume Complete',
  CT_SIGNUP: 'Signup User',
  CT_LOGIN: 'Login User',
  PAGE_VIEW: 'Page Views',
  ASSESSMENT_COMPLETED: 'ASSESSMENT_COMPLETED',
  ASSESSMENT_STARTED: 'ASSESSMENT_STARTED',
  LEAD_CAPTURE: 'Lead Capture',
  PAYMENT_START: 'Payment Start',
  JOB_APP_STATUS: 'Job Application Status',
};
export const ASSESSMENT = {
  FAILED: 1,
  PASSED: 0,
};

export const LOGGING_CONS = {
  ERROR_TYPE: 'error',
  INFO_TYPE: 'info',
};

export const seoTags = {
  root: {
    title: 'Seekho',
    description: 'Seekho!',
    url: process.env.REACT_APP_FRONTEND,
    imgUrl: '',
  },
  jobs: {
    title: 'Apply For Jobs Online - Best Online Jobs in India | Seekho',
    description:
      'Find jobs online for freshers from our online job listings. Find the job that fits your profile perfectly. Get a job online as per your skills & preferred role. ✓ Placement Training ✓ Online Job Search',
    url: 'https://safejob.in/jobs',
    imgUrl: '',
  },
  aboutUs: {
    title: 'About Seekho : What We Do and How We Got Here.',
    description:
      "Visit the Seekho About us page to know more about Seekho. Know the brand story, the team, and the objectives of India's biggest Job readiness platform.",
    url: 'About Seekho : What We Do and How We Got Here.',
    imgUrl: '',
  },
  careers: {
    title: 'Seekho- Career Explorer - Find careers of your choice',
    description:
      "Work with Seekho. Check the Safejob Careers page and become a part of India's largest online job readiness platform & remove the skill gap problem.",
    url: 'https://safejob.in/careers',
    imgUrl: '',
  },
  media: {
    title: 'Media - Get Official News of Safejob Powered by Safeducate ',
    description:
      'Visit the Safejob Media page and stay updated with the Industry updates, News and analysis in the job Industry. Get official News of Safejob I Safeducate here.',
    url: 'https://safejob.in/media',
    imgUrl: '',
  },
  contactUs: {
    title: 'Contact Us - Safejob Powered by Safeducate',
    description:
      'Contact Safejob and learn more about your career. Click on the Safejob Contact Us Page and connect with Safejob for a bright career. Contact now by clicking here.',
    url: 'https://safejob.in/contact-us',
    imgUrl: '',
  },
  learning: {
    title:
      'Online Courses & Job Placement - Best Free Online Courses With Certificates | Safejob',
    description:
      'Adapt job readiness skills with the best online certificate courses at Safejob. Get access to various online job courses. ✓ Placement Assistance ✓ Free Certificate Courses ✓ Best Job Training',
    url: `${process.env.REACT_APP_FRONTEND}/${REDIRECT_CONSTANTS.LEARNING_LEARNINGPATH}`,
    imgUrl: '',
  },
  xceedAnalytics: {
    title: 'Xceed Analytics - Get Your Dream Job',
    description:
      'Learn fundamental data skills from industry experts and get guaranteed placement',
    url: `${process.env.REACT_APP_FRONTEND}${REDIRECT_CONSTANTS.XCEED_ANALYTICS}`,
    imgUrl: '',
  },
  xceedLogistics: {
    title:
      'Supply Chain Jobs - Logistics & Supply Chain Management Jobs Online',
    description:
      'Make your career in logistics & get the best supply chain management jobs in the top companies with Safejob. ✓ Logistics Jobs ✓ Interview Preparation  ✓ Certified Training ✓ Placement Training',
    url: `${process.env.REACT_APP_FRONTEND}${REDIRECT_CONSTANTS.XCEED_LOGISTICS_SCM}`,
    imgUrl: '',
  },
  xceedBusinessDevelopment: {
    title:
      'Get Jobs In Sale Online - Best Sales Job Consultancy in India | SafeJob',
    description:
      'Grow your career in sales and get online sales jobs with Safejob. Learn from industry experts with the best sales job website. ✓ Sales Courses Online ✓ Placement Assistance ✓ Live Training Sessions',
    url: `${process.env.REACT_APP_FRONTEND}${REDIRECT_CONSTANTS.XCEED_BUSINESS_DEVELOPMENT}`,
    imgUrl: '',
  },
  xceedOperations: {
    title:
      'Operation Management Jobs - Get Jobs in Operations Management Online',
    description:
      'Get access to operations management online courses and avail jobs in operations department with Safejob. ✓ Best Online Training Platform ✓ Live Sessions ✓ Certified Training ✓ Placement Assistance',
    url: `${process.env.REACT_APP_FRONTEND}${REDIRECT_CONSTANTS.XCEED_OPERATIONS}`,
    imgUrl: '',
  },
  xceedDigitalMarketing: {
    title: 'Xceed Digital Marketing - Get Your Dream Job',
    description:
      'Master SEM, SMM, SEO & content marketing on digital platforms and get guaranteed placement',
    url: `${process.env.REACT_APP_FRONTEND}${REDIRECT_CONSTANTS.XCEED_DIGITAL_MARKETING}`,
    imgUrl: '',
  },
  placementBoard: {
    title: 'Safejob Placement Board',
    description:
      'This is a live placement board of Safejob’s job-readiness program Xceed.',
    url: `${process.env.REACT_APP_FRONTEND}${REDIRECT_CONSTANTS.PLACEMENT_BOARD}`,
    imgUrl: '',
  },
  seekho: {
    title: 'Seekho - Best Interview Preparation Platform',
    description:
      'Become job ready in 1 minute with by learning from exclusive interview experiences at Seekho',
    url: `${process.env.REACT_APP_FRONTEND}${REDIRECT_CONSTANTS.SEEKHO_URL}`,
    imgUrl: '',
  },
  resumeBuilder: {
    title: 'Safejob’s Resume Builder - Create A Perfect Resume Now',
    description:
      'Our Free Resume Builder has helped 80,000+ users create attractive, well-written resumes – and lightning fast! The era of stressing over resumes is finally over!',
    url: `${process.env.REACT_APP_FRONTEND}/${REDIRECT_CONSTANTS.RESUME_BUILDER}`,
    imgUrl: '',
  },
};

export const API_INFO = {
  LMS: 'lmsApi',
  LMS_AUTH: 'lmsAuthApi',
  LMS_USER_SERVICE: 'lmsUserService',
  LMS_AUTH_WITHOUT_ORG: 'lmsAuthApiWithoutOrg',
  LMS_USER_SERVICE_WITHOUT_ORG: 'lmsUserServiceWithoutOrg',
};

export const PayUTxnStatus = {
  NOT_STARTED: 'not started',
  CANCELLED_BY_USER: 'CancelledByUser',
  FAILED: 'Failed',
  INITIATED: 'Initiated',
};

export const CONTENT_STATUES = {
  DRAFTED: 'DRAFTED',
  UNDER_REVIEW: 'UNDER_REVIEW',
  PUBLISHED: 'PUBLISHED',
  UN_PUBLISHED: 'UN_PUBLISHED',
  DELETED: 'DELETED',
  DEPRECATED: 'DEPRECATED',
  EXPIRED: 'EXPIRED',
};

export const MORE_ROUTES = [
  { id: '4', name: 'About Us', redirect: '/about-us' },
  // { id: '5', name: 'Media', redirect: '/media' },
  { id: '6', name: 'Careers', redirect: '/careers' },
  { id: '7', name: 'Contact us', redirect: '/contact-us' },
  {
    id: '8',
    name: 'Blog',
    redirect: 'https://safejob.in/blog',
    className: '',
    isExternal: true,
  },
  { id: '9', name: 'Resume Builder', redirect: '/resume-builder' },
];

export const XCEED_NAMES = {
  ANALYTICS: 'Analytics',
  BUSINESS_DEVELOPMENT: 'Business Development',
  OPERATIONS: 'Operations',
  LOGISTICS_SCM: 'Logistics & Supply Chain',
  INTERVIEW_PREP: 'Career Essentials',
  DIGITAL_MARKETING: 'Digital Marketing',
  EMPLOYED: 'Employed',
  UN_EMPLOYED: 'Unemployed',
  NOT_SURE: 'Not Sure',
  CAREER_ESSENTIAL: 'Career Essentials',
};

export const LEARNING_NAME = {
  COURSE: 'Free Courses',
  PREMIUM: 'Premium Masterclass',
  SKILL: 'Skill Based Assessments',
  WEBINAR: 'Mentorships & Workshops',
};

export const DEGREE_TYPES_CONSTANTS = {
  NOT_APPLICABLE: 'NOT_APPLICABLE',
  TENTH_PASS: 'TENTH_PASS',
  TWELFTH_PASS: 'TWELFTH_PASS',
  DIPLOMA: 'DIPLOMA',
  GRAD: 'GRAD',
  POST_DIPLOMA: 'POST_DIPLOMA',
  POST_GRAD: 'POST_GRAD',
  DOCTORAL: 'DOCTORAL',
};

export const DEGREE_TYPES_LABELS = {
  NOT_APPLICABLE: 'Not Applicable',
  TENTH_PASS: '10th Pass',
  TWELFTH_PASS: '12th Pass',
  DIPLOMA: 'Diploma',
  GRAD: 'Graduation',
  POST_DIPLOMA: 'PG Diploma',
  POST_GRAD: 'Post Graduation',
  DOCTORAL: 'Doctoral',
};

export const DEGREE_TYPES = [
  {
    value: DEGREE_TYPES_CONSTANTS.NOT_APPLICABLE,
    label: DEGREE_TYPES_LABELS.NOT_APPLICABLE,
  },
  {
    value: DEGREE_TYPES_CONSTANTS.TENTH_PASS,
    label: DEGREE_TYPES_LABELS.TENTH_PASS,
  },
  {
    value: DEGREE_TYPES_CONSTANTS.TWELFTH_PASS,
    label: DEGREE_TYPES_LABELS.TWELFTH_PASS,
  },
  {
    value: DEGREE_TYPES_CONSTANTS.DIPLOMA,
    label: DEGREE_TYPES_LABELS.DIPLOMA,
  },
  {
    value: DEGREE_TYPES_CONSTANTS.GRAD,
    label: DEGREE_TYPES_LABELS.GRAD,
  },
  {
    value: DEGREE_TYPES_CONSTANTS.POST_DIPLOMA,
    label: DEGREE_TYPES_LABELS.POST_DIPLOMA,
  },
  {
    value: DEGREE_TYPES_CONSTANTS.POST_GRAD,
    label: DEGREE_TYPES_LABELS.POST_GRAD,
  },
  {
    value: DEGREE_TYPES_CONSTANTS.DOCTORAL,
    label: DEGREE_TYPES_LABELS.DOCTORAL,
  },
];

export const LEARNING_DROPDOWN = [
  {
    id: 1,
    redirect: '/courses',
    name: 'Courses',
    isExternal: true,
    doNotPassSsoToken: true,
  },
  {
    id: 2,
    redirect: '/tests',
    name: 'Tests',
    isExternal: true,
    doNotPassSsoToken: true,
  },
  {
    id: 3,
    redirect: '/workshops',
    name: 'Workshops',
    isExternal: true,
    doNotPassSsoToken: true,
  },
];
export const EXCEED_DROPDOWN = [
  {
    id: 1,
    redirect: REDIRECT_CONSTANTS.XCEED_LOGISTICS_SCM,
    name: XCEED_NAMES.LOGISTICS_SCM,
    isExternal: true,
    doNotPassSsoToken: true,
  },
  {
    id: 2,
    redirect: REDIRECT_CONSTANTS.XCEED_BUSINESS_DEVELOPMENT,
    name: XCEED_NAMES.BUSINESS_DEVELOPMENT,
    isExternal: true,
    doNotPassSsoToken: true,
  },
];
export const LEARNING_UPSKILLING = [
  {
    id: 1,
    redirect: REDIRECT_CONSTANTS.FREE_COURSE,
    name: LEARNING_NAME.COURSE,
    isExternal: true,
    doNotPassSsoToken: true,
  },
  {
    id: 2,
    redirect: REDIRECT_CONSTANTS.PREMIUM_COURSE,
    name: LEARNING_NAME.PREMIUM,
    isExternal: true,
    doNotPassSsoToken: true,
  },
  {
    id: 3,
    redirect: REDIRECT_CONSTANTS.LEARNING_ASSESSMENT,
    name: LEARNING_NAME.SKILL,
    isExternal: true,
    doNotPassSsoToken: true,
  },
  {
    id: 4,
    redirect: REDIRECT_CONSTANTS.LEARNING_WEBINAR,
    name: LEARNING_NAME.WEBINAR,
    isExternal: true,
    doNotPassSsoToken: true,
  },
];

export const GUEST_USER_ROUTES_LINKS = [
  {
    id: 1,
    name: 'Xceed',
    children: [...EXCEED_DROPDOWN],
    isDropDown: true,
  },
  {
    id: 2,
    name: 'Learning',
    children: [...LEARNING_DROPDOWN],
    isDropDown: true,
  },
  {
    id: 3,
    name: 'Jobs',
    redirect: REDIRECT_CONSTANTS.JOBS,
  },
  {
    id: 5,
    name: 'More',
    isDropDown: true,
    children: [...MORE_ROUTES],
  },
];

export const LOGGED_IN_PROFILE_DROPDOWN = {
  id: uuid.v4(),
  name: '',
  isDropDown: true,
  children: [
    { id: 1, redirect: REDIRECT_CONSTANTS.RESUME, name: 'View Profile' },
    { id: 2, redirect: REDIRECT_CONSTANTS.MY_COURSES, name: 'My Courses' },
    {
      id: 3,
      redirect: REDIRECT_CONSTANTS.LEARNING_ASSESSMENT,
      name: 'My Tests',
    },
    {
      id: 4,
      name: 'My Workshops',
      redirect: `${REDIRECT_CONSTANTS.LEARNING_WEBINAR}?q=enrolled`,
    },
    { id: 5, redirect: REDIRECT_CONSTANTS.JOBS, name: 'Job Applications' },
    {
      id: 6,
      redirect: REDIRECT_CONSTANTS.PAYMENT_HISTORY,
      name: 'Payment History',
    },
  ],
  showUserInfo: true,
  showLogoutBtn: true,
};

export const LOGGED_IN_USER_ROUTES = [
  {
    id: uuid.v4(),
    name: 'My Home',
    redirect: REDIRECT_CONSTANTS.DASHBOARD,
  },
  {
    id: uuid.v4(),
    name: 'Xceed',
    children: [...EXCEED_DROPDOWN],
    isDropDown: true,
  },
  {
    id: uuid.v4(),
    name: 'Learning',
    children: [...LEARNING_DROPDOWN],
    isDropDown: true,
  },
  {
    id: uuid.v4(),
    name: 'Jobs',
    redirect: REDIRECT_CONSTANTS.JOBS,
  },
  {
    id: uuid.v4(),
    name: 'Profile',
    redirect: REDIRECT_CONSTANTS.RESUME,
  },
  {
    id: uuid.v4(),
    name: 'More',
    isDropDown: true,
    children: [...MORE_ROUTES],
  },
  { ...LOGGED_IN_PROFILE_DROPDOWN },
];

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const COMPETENCY_LEVEL = {
  BEGINNER: 'BEGINNER',
  INTERMEDIATE: 'INTERMEDIATE',
  DIFFICULT: 'DIFFICULT',
  ADVANCED: 'ADVANCED',
};

export const COMPETENCY_LEVEL_LIST = [
  {
    label: 'Beginner',
    value: COMPETENCY_LEVEL.BEGINNER,
  },
  {
    label: 'Intermediate',
    value: COMPETENCY_LEVEL.INTERMEDIATE,
  },
  {
    label: 'Advanced',
    value: COMPETENCY_LEVEL.ADVANCED,
  },
];

export const MANDATORY_SKILL_LIST = [
  {
    label: 'Good To Have',
    value: false,
  },
  {
    label: 'Mandatory',
    value: true,
  },
];

export const ADMISSON_QUERY_CONTACT_US = {
  email: 'admissions@safejob.in',
  phone: '08061996108',
};

export const JOB_STATUS_CONSTANT = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const JOB_STATUS_LIST = [
  { label: 'Active', value: JOB_STATUS_CONSTANT.ACTIVE },
  { label: 'In-active', value: JOB_STATUS_CONSTANT.INACTIVE },
];

export const UPLOAD_TYPES = [
  'image/*',
  '.xlsx',
  '.xls',
  '.ppt',
  '.pptx',
  '.txt',
  '.pdf',
  '.doc',
  '.docx',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export const BANNER_KEYS = {
  HOME_BANNERS: 'HOME_BANNERS',
  JOBS_BANNERS: 'JOBS_BANNERS',
  SEEKHO_BANNERS: 'SEEKHO_BANNERS',
};

export const JOBS_TAB_KEYS = {
  ALL_JOBS: { name: 'All Jobs', id: 'all_jobs' },
  MY_JOBS: { name: 'My Jobs', id: 'my_jobs' },
  HIGHLIGHTS: { name: 'Highlights', id: 'highlights' },
  RESPONSIBILITIES: { name: 'Description', id: 'responsibilities' },
  PREREQUISITES: { name: 'Prerequisites', id: 'prerequisites' },
  ELIGIBILITY_TEST: { name: 'Eligibility Test', id: 'eligibility_test' },
};

export const EMAIL_VERIFICATION_STATUS = {
  VERIFIED: 'VERIFIED',
  NOT_VERIFIED: 'NOT_VERIFIED',
  VERIFICATION_PENDING: 'VERIFICATION_PENDING',
};

export const REFERRAL_TEXTS = {
  REFERRAL_INVALID:
    'Please use a valid referral code. This is an optional field.',
};

const PR_ARTICLES_HEADLINES = [
  {
    brand: Resources.images.businessworld,
    headline: 'Digital Transformation A Must For Indian Education',
    publication: 'Business World',
    date: '14 August, 2020',
    link:
      'http://bweducation.businessworld.in/article/Digital-Transformation-A-Must-For-Indian-Education-/14-08-2020-308639/#:~:text=Digital%20services%20have%20obliterated%20the,being%20posted%20to%20remote%20areas',
  },
  {
    brand: Resources.images.enterpreneur_india,
    headline: 'To Stay Relevant During the Pandemic Through Up-skilling',
    publication: 'Entrepreneur India',
    date: '21 August, 2020',
    link: 'https://www.entrepreneur.com/article/355126',
  },
  {
    brand: Resources.images.telecom_today,
    headline:
      'Here are The Best Digital Learning Platforms for Skill Development',
    publication: 'Telecom Today',
    date: '31 August, 2020',
    link:
      'http://www.telecomtoday.in/top-stories/here-are-the-best-digital-learning-platforms-for-skill-development.html/',
  },
  {
    brand: Resources.images.higher_edu_digest,
    headline:
      'Upskilling Through Online Education is the Best Way to Secure Jobs in the Future',
    publication: 'Higher Education Digest',
    date: '10th Sep, 2020',
    link:
      'https://www.highereducationdigest.com/upskilling-through-online-education-is-the-best-way-to-secure-jobs-in-the-future/',
  },
  {
    brand: Resources.images.indiatoday,
    headline:
      'Covid-19 exposes class divide in education: How can students learn without digital access?',
    publication: 'India Today',
    date: '14 August, 2020',
    link:
      'https://www.indiatoday.in/education-today/featurephilia/story/covid-19-online-classes-how-can-students-learn-without-digital-access-1711244-2020-08-14',
  },
  {
    brand: Resources.images.financialexpress,
    headline: 'Online education: Challenges faced by rural communities',
    publication: 'Financial Express',
    date: '12th Oct, 2020',
    link:
      'https://www.financialexpress.com/education-2/online-education-challenges-faced-by-rural-communities/2103301/',
  },
  {
    brand: Resources.images.indiatoday,
    headline: '6 quick tips for job seekers to upskill themselves from home',
    publication: 'India Today',
    date: '28th Oct, 2020',
    link:
      'https://www.indiatoday.in/education-today/jobs-and-careers/story/6-quick-tips-for-job-seekers-to-upskill-themselves-from-home-1736009-2020-10-28',
  },
  {
    brand: Resources.images.economictimes,
    headline: 'Sequoia-backed Seekify acquires upskilling platform SafeJob',
    publication: 'The Economic Times',
    date: '28th Nov, 2020',
    link:
      'https://economictimes.indiatimes.com/tech/startups/sequoia-backed-seekify-to-acquire-skilling-platform-safejob/articleshow/79460725.cms?from=mdr',
  },
  {
    brand: Resources.images.the_hindu,
    headline: 'Ready for a digital world?',
    publication: 'The Hindu',
    date: '5th Dec, 2020',
    link:
      'https://www.thehindu.com/education/tips-for-students-to-be-prepared-for-a-job-in-a-changing-world/article33258034.ece',
  },
];

const VALUES_SAFEJOB = [
  {
    title: Resources.images.value1,
    head: 'Talent Wins Games, Team Wins Championships',
    desc:
      'Individual commitment towards a group effort where none of us is as smart as all of us. We must direct individual accomplishments toward organizational objectives.',
  },
  {
    title: Resources.images.value2,
    head: 'It’s Kind Of Fun To Do The Impossible',
    desc:
      'We dream big. We believe in ourselves and the fire inside us to achieve these dreams. We have a growth mindset. And we have fun while doing it.',
  },
  {
    title: Resources.images.value3,
    head: 'Not All Superheroes Wear Capes',
    desc:
      'We’re all superheroes who are action-oriented and responsible. We believe in optimising for results and outcomes, not effort. In all of this - always remember the why.',
  },
  {
    title: Resources.images.value4,
    head: 'Be A Better Version Of Yourself',
    desc:
      'Don’t complain. Don’t come plain. Come in all colours. Celebrate the work you’re doing. Be authentic, be honest and be yourself. We believe in good people doing great things.',
  },
];

export const JOB_STATUS = {
  LIVE: 'LIVE',
  CLOSED: 'CLOSED',
  DELETED: 'DELETED',
  DRAFT: 'DRAFT',
  EXPIRED: 'EXPIRED',
  PENDING: 'PENDING',
};

export const VIDEO_UPLOAD_TYPES = [
  'video/mp4',
  'video/x-m4v',
  'video/quicktime',
  'video/x-quicktime',
];

export const JOB_APPLICANTS_STATUS = {
  INTERESTED: 'INTERESTED',
  APPLIED: 'APPLIED',
};

export const JOB_VISIBILITY_CONSTANTS = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
  APP: 'APP',
};

export const REMOTE_POLICY_CONSTANTS = {
  IN_OFFICE: 'IN_OFFICE',
  ONSITE_REMOTE: 'ONSITE_REMOTE',
  REMOTE_ONLY: 'REMOTE_ONLY',
};

export const remotePolicyMenuOptions = [
  { label: 'In Office', value: REMOTE_POLICY_CONSTANTS.IN_OFFICE },
  { label: 'Onsite or Remote', value: REMOTE_POLICY_CONSTANTS.ONSITE_REMOTE },
  { label: 'Remote Only', value: REMOTE_POLICY_CONSTANTS.REMOTE_ONLY },
];

export const REMOTE_POLICY_DISPLAY_MENU = {
  IN_OFFICE: 'In Office',
  ONSITE_REMOTE: 'Onsite or Remote',
  REMOTE_ONLY: 'Remote Only',
};

export const WFH_POLICY_CONSTANTS = {
  WFH_ALLOWED: 'WFH_ALLOWED',
  WFH_NOT_ALLOWED: 'WFH_NOT_ALLOWED',
};

export const wfhPolicyMenuOptions = [
  {
    label: 'WFH Allowed - few days a week.',
    value: WFH_POLICY_CONSTANTS.WFH_ALLOWED,
  },
  { label: 'WFH Not Allowed', value: WFH_POLICY_CONSTANTS.WFH_NOT_ALLOWED },
];

export const WFH_POLICY_DISPLAY_MENU = {
  WFH_ALLOWED: 'WFH Allowed - few days a week.',
  WFH_NOT_ALLOWED: 'WFH Not Allowed',
};

export const CHALLENGE_VIDEO_VISIBILITY_CONSTANTS = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
  PRIVATE_TILL_DUE_DATE: 'PRIVATE_TILL_DUE_DATE',
};

export const JOB_VISIBILITY = [
  { label: 'Public', value: JOB_VISIBILITY_CONSTANTS.PUBLIC },
  { label: 'Private', value: JOB_VISIBILITY_CONSTANTS.PRIVATE },
];

export const JOB_VISIBILITY_RESPONSE = {
  PUBLIC: 'Public',
  PRIVATE: 'Private',
};

export const CHALLENGE_VIDEO_VISIBILITY_RESPONSE = {
  PUBLIC: 'Public',
  PRIVATE: 'Private',
  PRIVATE_TILL_DUE_DATE: 'Private till due date',
};

export const CHALLENGE_TYPES = {
  PEOPLE_CHOICE_AWARD: 'PEOPLE_CHOICE_AWARD',
  CRITIC_AWARD: 'CRITIC_AWARD',
};

export const CHALLENGE_STATUS = {
  SCHEDULED: 'SCHEDULED',
  ONGOING: 'ONGOING',
  AWAITING_RESULT: 'AWAITING_RESULT',
  COMPLETED: 'COMPLETED',
  DELETED: 'DELETED',
};

export const APPLICATION_VISIBILITY_VALUE = {
  PUBLIC: 10,
  PRIVATE: 20,
};

export const APPLICATION_VISIBILITY = [
  { label: 'Private', value: JOB_VISIBILITY_CONSTANTS.PRIVATE },
  { label: 'Public', value: JOB_VISIBILITY_CONSTANTS.PUBLIC },
];

export const JOB_SOURCE_CONSTANTS = {
  SEEKHO: 'SEEKHO',
  EXTERNAL: 'EXTERNAL',
};

export const JOB_SOURCE = [
  { label: 'Seekho', value: JOB_SOURCE_CONSTANTS.SEEKHO },
  { label: 'External', value: JOB_SOURCE_CONSTANTS.EXTERNAL },
];

export const JOB_PROVIDER_CONSTANTS = {
  INTERNAl: 'INTERNAL',
  MONSTER: 'MONSTER',
  NAUKRI: 'NAUKRI',
  IIM_JOBS: 'IIM_JOBS',
  LINKEDIN: 'LINKEDIN',
  ANGELLIST: 'ANGELLIST',
  FORMS: 'FORMS',
  COMPANY_WEBSITE: 'COMPANY_WEBSITE',
  OTHERS: 'OTHERS',
};

export const JOB_PROVIDER = [
  { label: 'Monster', value: JOB_PROVIDER_CONSTANTS.MONSTER },
  { label: 'Naukri', value: JOB_PROVIDER_CONSTANTS.NAUKRI },
  { label: 'IIM Jobs', value: JOB_PROVIDER_CONSTANTS.IIM_JOBS },
  { label: 'Linked In', value: JOB_PROVIDER_CONSTANTS.LINKEDIN },
  { label: 'Angel List', value: JOB_PROVIDER_CONSTANTS.ANGELLIST },
  { label: 'Forms', value: JOB_PROVIDER_CONSTANTS.FORMS },
  { label: 'Company Website', value: JOB_PROVIDER_CONSTANTS.COMPANY_WEBSITE },
  { label: 'Others', value: JOB_PROVIDER_CONSTANTS.OTHERS },
];

export const JOB_TYPE = [
  { label: 'Basic', value: false },
  { label: 'Premium', value: true },
];

export const INPUT_TYPE = {
  TEXT: 'TEXT',
  SELECT: 'SELECT',
  MULTI_SELECT: 'MULTI_SELECT',
  ASYNC_SELECT: 'ASYNC_SELECT',
  ASYNC_MULTI_SELECT: 'ASYNC_MULTI_SELECT',
  DATE_RANGE: 'DATE_RANGE',
  CHECKBOX: 'CHECKBOX',
  RADIO: 'RADIO',
};

export {
  MICRO_CV_CONSTANTS,
  ERR_CONSTANTS,
  DASHBOARD_CONSTANTS,
  COMMON_NAVBAR_LINKS,
  ADMIN_LINKS,
  PAYMENT_CONSTANTS,
  PAYMENT_STATUS,
  STRINGS,
  REDIRECT_CONSTANTS,
  FILTERS,
  VIDEO_FORMATS,
  mediaData,
  PR_ARTICLES_HEADLINES,
  VALUES_SAFEJOB,
  responsive,
};
