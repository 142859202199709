import { toast } from "react-toastify";
import Toast from "components/Toast";
import React from "react";

const showToastMsg = (msg = "Something went wrong", type = "", timer = 4000) => {
  toast.dismiss();
  return toast(<Toast msg={msg} type={type} />, {
    position: toast.POSITION.TOP_CENTER,
    closeOnClick: true,
    closeButton: true,
    autoClose: timer,
  });
};

export default showToastMsg;
