import services from './index';
import SEEKIFY_API from '../util/seekify-api-constants';
import { API_INFO } from '../util/app-constants';
import CandidateAdapter from '../adapters/CandidateAdapter';

class Candidate extends CandidateAdapter {
  fetch = (
    data = {
      pageNumber: 1,
    }
  ) => {
    return services.postRequest(
      `${SEEKIFY_API.CANDIDATE.LIST}?pageNumber=${data.pageNumber ||
        1}&pageSize=10`,
      data,
      {
        info: API_INFO.LMS_AUTH,
        transformResponse: (data) =>
          this.transformResponse(data, this.candidateListResponseAdapter),
      }
    );
  };

  applicantStatusUpdate = (id, data) => {
    const rejectionReason = data.rejectionReason ? data.rejectionReason : '';
    const feedback = data.feedback ? data.feedback : '';
    return services.patchRequest(
      `${SEEKIFY_API.CANDIDATE.APPLICANT_STATUS}/${id}?applicantUserIds=${data.applicantUserIds}&status=${data.status}&rejectionReason=${rejectionReason}&feedback=${feedback}`,
      data,
      {
        info: API_INFO.LMS_AUTH,
      }
    );
  };

  applicantBulkReject = (id, sourceStatus) => {
    return services.patchRequest(
      `${SEEKIFY_API.CANDIDATE.BULK_REJECT}?destStatus=REJECTED&jobId=${id}&sourceStatus=${sourceStatus}`,
      null,
      {
        info: API_INFO.LMS_AUTH,
      }
    );
  };
}

export default new Candidate();
