import {
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  FETCHING_PROXY_USER_DATA,
} from "./action";

const initialState = {
  isFetching: false,
  isLoadingMore: false,
  userList: [],
  pageNumber: 1,
  pageSize: 20,
  totalItems: 0,
  totalPages: 0,
  didInvalidate: false,
  isFetchingProxyUserData: false,
};

const reducer = (state = initialState, action) => {
  const { type, payload = {} } = action;
  switch (type) {
    case USER_LIST_REQUEST:
      return {
        ...state,
        params: action.params || {},
        isFetching: !action.isLoadingMore,
        isLoadingMore: action.isLoadingMore,
        didInvalidate: false,
      };
    case USER_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isLoadingMore: action.isLoadingMore,
        userList: action.isLoadingMore
          ? state.userList.concat(action.payload.data || [])
          : payload.data || [],
        pageNumber: payload.pageNumber,
        pageSize: payload.pageSize,
        totalItems: payload.totalItems,
        totalPages: payload.totalPages,
        didInvalidate: false,
      };
    case FETCHING_PROXY_USER_DATA: {
      return {
        ...state,
        isFetchingProxyUserData: payload.isFetchingProxyUserData || false,
        proxyUserHash: payload.userHash || null,
      };
    }
    default:
      return state;
  }
};

export default reducer;
