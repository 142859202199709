import {deletePendingCall, lockScroll} from 'util/common';
import UserLoginActionTypes from './action-types';
import TokenStorage from '../../util/TokenStorage';
import { APP_STORAGE } from '../../util/app-constants';

const initialState = {
  openLoginPopup: false,
  userLoggedInWithPopup: false,
  isUserLoggedIn:
    !!TokenStorage.getItem(APP_STORAGE.ACCESS_TOKEN) &&
    !!TokenStorage.getItem(APP_STORAGE.USER_INFO),
  isAdminLoggedIn:
    !!TokenStorage.getItem(APP_STORAGE.ADMIN_ACCESS_TOKEN) &&
    !!TokenStorage.getItem(APP_STORAGE.ADMIN_USER_INFO),
  userInfo: TokenStorage.getItem(APP_STORAGE.USER_INFO)
    ? JSON.parse(TokenStorage.getItem(APP_STORAGE.USER_INFO))
    : {},
  adminUserInfo: TokenStorage.getItem(APP_STORAGE.ADMIN_USER_INFO)
    ? JSON.parse(TokenStorage.getItem(APP_STORAGE.ADMIN_USER_INFO))
    : {},
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  const newState = { ...state };
  switch (type) {
    case UserLoginActionTypes.TOGGLE_POPUP_LOGIN: {
      if (!payload.value) {
        lockScroll('');
        deletePendingCall('learning');
      }
      if (payload.value) {
        lockScroll('hidden');
      }
      return { ...newState, openLoginPopup: payload.value };
    }
    case UserLoginActionTypes.TOGGLE_POPUP_LOGIN_STATE:
      if (payload.value) {
        lockScroll('');
      }
      return { ...newState, userLoggedInWithPopup: payload.value, openLoginPopup: false };

    case UserLoginActionTypes.TOGGLE_STATE_USER_LOGGED_IN:
      return { ...newState, isUserLoggedIn: true, userInfo: { ...payload } };

    case UserLoginActionTypes.ADMIN_LOGGED_IN: {
      return {
        ...newState,
        isAdminLoggedIn: true,
        adminUserInfo: { ...payload },
      };
    }
    case UserLoginActionTypes.ADMIN_LOGGED_OUT:
      return {
        ...newState,
        isAdminLoggedIn: false,
        adminUserInfo: {},
      };

    case UserLoginActionTypes.USER_LOGGED_OUT:
      return {
        ...newState,
        isUserLoggedIn: false,
        userInfo: {},
      };

    default:
      return { ...state };
  }
};

export default reducer;
