import axios from "axios";
import { errorHandler } from "./error-handler";
import { B2B_API, INTERVIEW_EXP_VIDEOS } from "./api-constants";
import {
  API_INFO,
  APP_STORAGE,
  LOGGING_CONS,
  REDIRECT_CONSTANTS,
  USER_ROLES,
} from "./app-constants";
import LogService from "./LogService";
import TokenStorage from "./TokenStorage";
import { hasSubDomain } from "./common";

export default {
  setupInterceptors: (store) => {
    axios.interceptors.request.use((request) => {
      const { url, info } = request;
      const newUrl = getUrl(url, info);
      const isSubDomain = hasSubDomain();
      request.url = newUrl;
      window.currentPath = url;

      const ignoreReqsts = [
        B2B_API.O_AUTH_TOKEN,
        INTERVIEW_EXP_VIDEOS.VIDEO_FETCH,
      ];

      const accessToken = TokenStorage.getItem(APP_STORAGE.ACCESS_TOKEN);
      const adminAccessToken = TokenStorage.getItem(
        APP_STORAGE.ADMIN_ACCESS_TOKEN
      );
      const orgId = TokenStorage.getItem(APP_STORAGE.ORG_ID) || null;
      const pathName = window.location.pathname;

      let token = accessToken;
      if (document.cookie) {
        const cookies = document.cookie.split(";").reduce((cookies, cookie) => {
          const [name, val] = cookie.split("=").map((c) => c.trim());
          cookies[name] = val;
          return cookies;
        }, {});
        const utmHeaders = [];

        if (cookies.utm_campaign) {
          utmHeaders.push(`utm_campaign=${cookies.utm_campaign};`);
        }
        if (cookies.utm_medium) {
          utmHeaders.push(`utm_medium=${cookies.utm_medium};`);
        }
        if (cookies.utm_source) {
          utmHeaders.push(`utm_source=${cookies.utm_source};`);
        }
        if (cookies.utm_group) {
          utmHeaders.push(`utm_group=${cookies.utm_group};`);
        }
        if (cookies.utm_content) {
          utmHeaders.push(`utm_content=${cookies.utm_content};`);
        }
        if (cookies.referral_code) {
          utmHeaders.push(`referral_code=${cookies.referral_code};`);
        }
        if (utmHeaders.length > 0) {
          request.headers["utm-headers"] = utmHeaders.join("");
        }
      }

      if (pathName.includes(USER_ROLES.ADMIN) || isSubDomain) {
        token = adminAccessToken;
      }

      if (isSubDomain) {
        if (
          info &&
          orgId &&
          info !== API_INFO.LMS_AUTH_WITHOUT_ORG &&
            info !== API_INFO.LMS_USER_SERVICE_WITHOUT_ORG
        ) {
          request.headers["org-id"] = orgId;
        }
      }

      if (
        pathName.includes(REDIRECT_CONSTANTS.ADMIN_USERS) &&
        url.includes(B2B_API.WHO_AM_I)
      ) {
        token = accessToken;
      }

      if (token && !ignoreReqsts.includes(url)) {
        request.headers.Authorization = `Bearer ${token}`;
      }

      if (
        info === API_INFO.LMS ||
        info === API_INFO.LMS_USER_SERVICE ||
        info === API_INFO.LMS_AUTH ||
        info === API_INFO.LMS_AUTH_WITHOUT_ORG ||
        info === API_INFO.LMS_USER_SERVICE_WITHOUT_ORG
      ) {
        request.headers["account-hash"] = process.env.REACT_APP_LMS_HEADER;
        LogService.log({
          name: "LMS_API_REQUEST",
          type: LOGGING_CONS.INFO_TYPE,
          url: request.url,
          data: request.data,
          request,
        });
      }

      return request;
    });
    axios.interceptors.response.use(
      (response) => {
        if (
          response &&
          response.config &&
          (response.config.info === API_INFO.LMS ||
            response.config.info === API_INFO.LMS_AUTH ||
            response.config.info === API_INFO.LMS_USER_SERVICE ||
            response.config.info === API_INFO.LMS_AUTH_WITHOUT_ORG ||
            response.config.info === API_INFO.LMS_USER_SERVICE_WITHOUT_ORG)
        ) {
          LogService.log({
            name: "LMS_API_RESPONSE",
            data: response.data,
            type: LOGGING_CONS.INFO_TYPE,
            url: response && response.config && response.config.url,
            ...response,
          });
        }
        return response;
      },
      (err) => {
        return errorHandler(err);
      }
    );
  },
};

const getUrl = (url, data) => {
  switch (data) {
    case API_INFO.LMS:
      return `${process.env.REACT_APP_LMS_ENDPOINT}${url}`;
    case API_INFO.LMS_AUTH:
      return `${process.env.REACT_APP_LMS_ENDPOINT}${url}`;
    case API_INFO.LMS_AUTH_WITHOUT_ORG:
      return `${process.env.REACT_APP_LMS_ENDPOINT}${url}`;
    case API_INFO.LMS_USER_SERVICE:
      return `${process.env.REACT_APP_LMS_USER_SERVICE_ENDPOINT}${url}`;
    case API_INFO.LMS_USER_SERVICE_WITHOUT_ORG:
      return `${process.env.REACT_APP_LMS_USER_SERVICE_ENDPOINT}${url}`;
    default:
      return process.env.REACT_APP_BACKEND_BASEURL + url;
  }
};
