import Candidate from '../../services/Candidate';

export const CANDIDATES_LIST_REQUEST = 'CANDIDATES_LIST_REQUEST';
export const CANDIDATES_LIST_SUCCESS = 'CANDIDATES_LIST_SUCCESS';
export const CANDIDATES_LIST_FAILURE = 'CANDIDATES_LIST_FAILURE';
export const CANDIDATE_VIDEO_RESPONSE = 'CANDIDATE_VIDEO_RESPONSE';
export const UPDATE_APPLICANT_STATUS_REQUEST =
  'UPDATE_APPLICANT_STATUS_REQUEST';
export const UPDATE_APPLICANT_STATUS_SUCCESS =
  'UPDATE_APPLICANT_STATUS_SUCCESS';

export const requestCandidateList = (params, loadMore) => ({
  type: CANDIDATES_LIST_REQUEST,
  params,
  isLoadingMore: loadMore,
});

export const receiveCandidateList = (candidates, loadMore) => ({
  type: CANDIDATES_LIST_SUCCESS,
  payload: candidates,
  isLoadingMore: loadMore,
});

export const showCandidateVideoResponse = (payload) => ({
  type: CANDIDATE_VIDEO_RESPONSE,
  payload,
});

export function fetchCandidateList(params = {}, loadMore = false) {
  return async (dispatch, getState) => {
    try {
      dispatch(requestCandidateList(params, loadMore));
      const response = await Candidate.fetch(params);
      dispatch(receiveCandidateList(response.data, loadMore));
      return response;
    } catch (e) {
      return e;
    }
  };
}
