const initialState = { isMenuOpen: false };

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  const newState = { ...state };
  if (type === "change") {
    return { ...newState, isMenuOpen: payload };
  }
  return { ...newState };
};

export default reducer;
