import {
	ADMIN_API,
	B2B_API,
	CONTACT_US_API,
	DASHBOARD_API,
	LOGIN_API,
	MICRO_CV_API,
} from "util/api-constants";
import {API_INFO, FILTERS} from "util/app-constants";
import {getEntityStatusByFilters} from "util/common";
import BaseService from "./BaseService";

const accountId = 131;

class Service extends BaseService {
	fetchLocations = () => {
		return this.getRequest(MICRO_CV_API.LOCATION);
	};
	
	fetchSkills = (filter = FILTERS.ACTIVE) => {
		return this.postRequest(ADMIN_API.GET_SKILLS_LIST, {
			entityStatuses: getEntityStatusByFilters(filter),
		});
	};
	
	fetchJobFunctions = () => {
		return this.getRequest(MICRO_CV_API.JOB_FUNC);
	};
	
	fetchJobRoles = () => {
		return this.getRequest(ADMIN_API.ALL_JOB_ROLES);
	};
	
	saveMicroCvData = (obj) => {
		return this.postRequest(MICRO_CV_API.MICRO_CV, obj);
	};
	
	fetchLearningPath = ({pageNo = 1, size = 10}) => {
		return this.getRequest(
				`${DASHBOARD_API.LEARNING_PATH}?page=${pageNo}&size=${size}`
		);
	};
	
	joinSession = (id, timezone) => {
		return this.postRequest(
				`${ADMIN_API.LIVESESSION}/enroll/${id}?timeZone=${timezone}`
		);
	};
	
	fetchSkillLevel = (filter = FILTERS.ACTIVE) => {
		return this.postRequest(ADMIN_API.SKILL_LEVEL, {
			entityStatuses: getEntityStatusByFilters(filter),
		});
	};
	
	fetchIndustry = (filter = FILTERS.ACTIVE) => {
		return this.postRequest(ADMIN_API.GET_INDUSTRIES_LIST, {
			entityStatuses: getEntityStatusByFilters(filter),
		});
	};
	
	saveContactUsData = (data) => {
		return this.postRequest(CONTACT_US_API.saveData, data);
	};

	generateOtp = (userDetail) => {
		return this.getRequest(`${B2B_API.SEND_OTP}=${userDetail}&uc=1`, {
			info: API_INFO.LMS_USER_SERVICE,
		});
	};
	
	checkUserExistence = (userDetail) => {
		return this.getRequest(`${B2B_API.USER_EXIST}=${userDetail}`, {
			info: API_INFO.LMS_USER_SERVICE,
			headers: {
				"account-id": accountId,
			},
		});
	};
	
	checkUserExistenceInOrg = (userDetail) => {
		return this.getRequest(`${B2B_API.USER_EXIST_IN_ORG}=${userDetail}`, {
			info: API_INFO.LMS_USER_SERVICE,
			headers: {
				"account-id": accountId,
			},
		});
	};
	
	checkSubDomainExistence = (domain) => {
		return this.getRequest(`${B2B_API.SUB_DOMAIN_EXIST}?domain=${domain}`, {
			info: API_INFO.LMS_USER_SERVICE,
		});
	};
	
	getAuthToken = ({loginId, otp}) => {
		const formData = new FormData();
		formData.append("uc", 1);
		formData.append("grant_type", "password");
		formData.append("phone", loginId);
		formData.append("otp", otp);
		return this.postRequest(B2B_API.GET_AUTH_TOKEN, formData, {
			info: API_INFO.LMS_USER_SERVICE,
			headers: {
				"account-id": accountId,
			},
		});
	};
	
	getContactInfo = () => {
		return this.getRequest(LOGIN_API.getUserDetail);
	};
	
	createNewUser = (data) => {
		return this.postRequest(B2B_API.CREATE_USER, data, {
			info: API_INFO.LMS_USER_SERVICE,
			headers: {
				"account-id": accountId,
			},
		});
	};
	
	getUserInfo = () => {
		return this.getRequest(B2B_API.WHO_AM_I, {
			info: API_INFO.LMS_USER_SERVICE,
			headers: {
				"account-id": accountId,
			},
		});
	};
	
	getOrgDetailsById = (orgId) => {
		return this.getRequest(`${B2B_API.ORG_DETAILS_BY_ID}/${orgId}`, {
			info: API_INFO.LMS_USER_SERVICE,
			headers: {
				"account-id": accountId,
			},
		});
	};
}

export default new Service();
