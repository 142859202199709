import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles';
import Resources from 'assets/Resources';
import 'lazysizes';
import React, { Suspense, useEffect, lazy } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import 'react-multi-carousel/lib/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Provider } from 'react-redux';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { toast } from 'react-toastify';
import networkInterceptor from 'util/network-interceptors';
import './animation.css';
import './App.css';
import useFetchUserRefreshToken from './components/customHooks/useFetchUserRefreshToken';
import ErrorBoundary from './components/ErrorBoundary';
import PageLoader from './components/PageLoader';
import SideNavBar from './componentsV2/SideNavBar';
import store from './config/store';
import { APP_STORAGE, REDIRECT_CONSTANTS } from './util/app-constants';
import TokenStorage from './util/TokenStorage';
import UserState from './util/UserState';

const AppWithRoutes = lazy(() => import('./pages/Routing/AppWithRoutes'));
const ProxyLogin = lazy(() => import('./pages/AdminV2/Recruiter/ProxyLogin'));
const OpenRoutes = lazy(() => import('./pages/Routing/OpenRoutes'));
const AdminLogin = lazy(() => import('pages/Admin/AdminLogin'));

const theme = createTheme({
  palette: {
    primary: {
      main: '#8031A7',
    },
    secondary: {
      main: '#4AA571',
    },
    tertiary: {
      main: '#FAFCFE',
      contrastText: '#4A4A68',
      300: '#8C8CA1',
    },
    tertiary200: {
      main: '#ECF1F4',
    },
    tertiary300: {
      main: '#4A4A68',
    },
    danger: {
      main: '#E65E69',
    },
  },
  typography: {
    button: {
      fontSize: '0.7rem',
      textTransform: 'capitalize',
    },
    fontSize: 14,
    fontFamily: ['Rubik', 'Open Sans'].join(','),
    impact: {
      fontFamily: 'Rubik',
      fontSize: '24px',
      color: '#4A4A68',
      fontWeight: 600,
    },
    large: {
      fontFamily: 'Rubik',
      fontSize: '32px',
      color: '#4A4A68',
      fontWeight: 500,
    },
    h1: {
      fontFamily: 'Rubik',
      fontSize: '24px',
      color: '#4A4A68',
      fontWeight: 500,
    },
    h2: {
      fontFamily: 'Rubik',
      fontSize: '16px',
      color: '#4A4A68',
      fontWeight: 600,
    },
    menuHeading: {
      fontFamily: 'Open Sans',
      fontSize: '16px',
      color: '#4A4A68',
      fontWeight: 500,
    },
    menuHeadingSelected: {
      fontFamily: 'Open Sans',
      fontSize: '16px',
      color: '#4AA571',
      fontWeight: 700,
    },
    body: {
      fontFamily: 'Open Sans',
      fontSize: '18px',
      color: '#4A4A68',
      fontWeight: 400,
    },
    shortCopy: {
      fontFamily: 'Open Sans',
      fontSize: '12px',
      color: '#4A4A68',
      fontWeight: 400,
    },
    smallCta: {
      fontFamily: 'Open Sans',
      fontSize: '12px',
      color: '#4A4A68',
      fontWeight: 700,
    },
    subtitle: {
      fontFamily: 'Open Sans',
      fontSize: '10px',
      color: '#4A4A68',
      fontWeight: 500,
    },
  },
  components: {
    MuiLoadingButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.size === 'small' && {
            fontSize: '0.63rem',
          }),
          ...(ownerState.size === 'large' && {
            fontSize: '0.75rem',
          }),
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '0.7rem',
          fontFamily: 'Open Sans',
          color: '#4A4A68',
        },
        header: {
          fontSize: '0.7rem',
          fontWeight: 700,
          fontFamily: 'Open Sans',
          color: '#4A4A68',
        },
      },
    },
  },
});

const CloseButton = ({ closeToast }) => (
  <span className="toast-style" onClick={closeToast}>
    <img src={Resources.images.closeToast} alt="" />
  </span>
);

toast.configure({
  draggable: true,
  autoClose: 4000,
  bodyClassName: 'toast-body',
  hideProgressBar: true,
  closeButton: <CloseButton anyprop="root" />,
});

networkInterceptor.setupInterceptors(store);
UserState.updateAccessToken();

function App() {
  useEffect(() => {
    UserState.updateAccessToken();
    /* setUTMCookie('utm_source');
		setUTMCookie('utm_medium');
		setUTMCookie('utm_campaign');
		setUTMCookie('utm_group');
		setUTMCookie('utm_content');
		setUTMCookie('referral_code'); */
  }, []);

  const { userLogin } = store.getState() || {};
  const { isAdminLoggedIn } = userLogin || {};
  const userInfo = TokenStorage.getAdminUserInfo();

  const isRefreshAdminToken = useFetchUserRefreshToken({
    isUserLoggedIn: isAdminLoggedIn,
    fn: TokenStorage.refreshAdminToken,
    tokenExpiryTime: Number(
      TokenStorage.getItem(APP_STORAGE.ADMIN_TOKEN_EXPIRE_TIME)
    ),
  });

  if (isRefreshAdminToken) {
    return <PageLoader />;
  }

  return (
    <div className="App">
      <ErrorBoundary>
        <Provider store={store}>
          <Router>
            <Suspense fallback={<PageLoader />}>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                  <Route
                    exact
                    path={`${REDIRECT_CONSTANTS.RECRUITER_PROXY}/:userHash`}
                    component={ProxyLogin}
                  />
                  {Object.keys(userInfo).length > 0 ? (
                    <SideNavBar>
                      <AppWithRoutes />
                    </SideNavBar>
                  ) : (
                    <OpenRoutes path="/" component={AdminLogin} />
                  )}
                </ThemeProvider>
              </StyledEngineProvider>
            </Suspense>
          </Router>
        </Provider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
