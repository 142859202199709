export default class SessionStorage {
  static setItem = (name, item) => {
    sessionStorage.setItem(name, item);
  };

  static getItem = itemName => {
    try {
      return sessionStorage.getItem(itemName);
    } catch (e) {
      return null;
    }
  };

  static removeItem = itemName => {
    return sessionStorage.removeItem(itemName);
  };

  static clear = () => {
    sessionStorage.clear();
  };
}
