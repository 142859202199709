import React from 'react';
import { REDIRECT_CONSTANTS } from '../../util/app-constants';
import WorkRoundedIcon from '@mui/icons-material/WorkRounded';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import SupervisorAccountRoundedIcon from '@mui/icons-material/SupervisorAccountRounded';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import Person3Icon from '@mui/icons-material/Person3';

export const AdminMenuItems = [
  {
    text: 'Organizations',
    icon: <BusinessRoundedIcon fontSize={'small'} />,
    path: REDIRECT_CONSTANTS.ADMIN_ORGANIZATIONS,
  },
];

export const RecruiterMenuItems = [
  {
    text: 'Jobs',
    icon: <WorkRoundedIcon fontSize={'small'} />,
    path: REDIRECT_CONSTANTS.EMP_JOBS,
  },
  {
    text: 'Quizzes',
    icon: <BusinessRoundedIcon fontSize={'small'} />,
    path: REDIRECT_CONSTANTS.EMP_ASSESSMENT,
  },
];

export const CollegeMenuItems = [
  {
    text: 'Overview',
    icon: <SchoolRoundedIcon fontSize={'small'}/>,
    path: REDIRECT_CONSTANTS.COLLEGE_OVERVIEW,
  },
  {
    text: 'Training',
    icon: <BusinessRoundedIcon fontSize={'small'} />,
    path: REDIRECT_CONSTANTS.COLLEGE_TRAINING,

    // YOU CAN ADD SUB-MENU ITEMS LIKE THIS
    subItems: [
      {
        text: 'Trainers',
        icon: <BusinessRoundedIcon fontSize={'small'} />,
        path: REDIRECT_CONSTANTS.COLLEGE_TRAINING_CLASS,
      },
      {
        text: 'Quizzes',
        icon: <BusinessRoundedIcon fontSize={'small'} />,
        path: REDIRECT_CONSTANTS.COLLEGE_TRAINING_ASSESSMENT_REPORT,
      },
    ],
  },
  {
    text: 'Curriculums',
    icon: <LocalLibraryIcon fontSize={'small'} />,
    path: REDIRECT_CONSTANTS.COLLEGE_CURRICULUMS,
  },
  {
    text: 'Students',
    icon: <SupervisorAccountRoundedIcon fontSize={'small'} />,
    path: REDIRECT_CONSTANTS.COLLEGE_STUDENTS,
  },
  {
    text: 'Trainers',
    icon: <Person3Icon fontSize={'small'} />,
    path: REDIRECT_CONSTANTS.COLLEGE_TRAINERS,
  },
  /* {
    text: 'Leaderboard',
    icon: <EqualizerRoundedIcon fontSize={'small'} />,
    path: REDIRECT_CONSTANTS.COLLEGE_LEADERBOARD,
  },
  {
    text: 'Placement Cell',
    icon: <WorkRoundedIcon fontSize={'small'} />,
    path: REDIRECT_CONSTANTS.COLLEGE_PLACEMENT_CELL,
  },
  {
    text: 'Extra Curricular Activites',
    icon: <SportsHandballRoundedIcon fontSize={'small'} />,
    path: REDIRECT_CONSTANTS.COLLEGE_EXTRA_CURRICULAR_ACTIVITIES,
  },
  {
    text: 'Content Library',
    icon: <MenuBookRoundedIcon fontSize={'small'} />,
    path: REDIRECT_CONSTANTS.COLLEGE_CONTENT_LIBRARY,
  },
  {
    text: 'Communications',
    icon: <ForumRoundedIcon fontSize={'small'} />,
    path: REDIRECT_CONSTANTS.COLLEGE_COMMUNICATIONS,
  },
  {
    text: 'Financials',
    icon: <LocalAtmRoundedIcon fontSize={'small'} />,
    path: REDIRECT_CONSTANTS.COLLEGE_FINANCIALS,
  }, */
];
