const ADMIN_USERS_TYPES = {
  FETCHING_USER: 'FETCHING_USER',
  FETCHED_USER: 'FETCHED_USER',
  ERROR_WHILE_FETCHING_USER: 'ERROR_WHILE_FETCHING_USER',
  FETCHING_MORE_USERS: 'FETCHING_MORE_USERS',
  UPDATE_SEARCH_VALUE: 'UPDATE_SEARCH_VALUE',
  CLEAR_SEARCH_VALUE: 'CLEAR_SEARCH_VALUE',
  FETCHING_PROXY_USER_DATA: 'FETCHING_PROXY_USER_DATA',
};

export default ADMIN_USERS_TYPES;
