import React from "react";
import CommonPopup from "../../../components/CommonPopup";
import PrimaryButton from "../../../components/Buttons";
import StyleConstant from "../../../util/style-constants";
import LogoutWrapper from "./style";

export const LogoutPopup = ({ logoutClicked, onLogout, logout }) => {
  function onClickLoggedOut(e) {
    logout();
    onLogout(e, false);
    window.location.href = "/";
  }
  return (
    <CommonPopup
      open={logoutClicked}
      closeOnDocumentClick={false}
      modal
      closeOnEscape={false}
      onClose={(e) => onLogout(e, false)}
      lockScroll
      maxWidth="400px"
    >
      <LogoutWrapper>
        <div className="logout_container popup_pad">
          <div className="layout-row layout-align-center-center">
            <span className="do-you-want-to-text">Do you want to Logout?</span>
          </div>
          <div className="layout-row layout-align-center-center confrm_btns">
            <PrimaryButton
              text="Yes, Logout"
              onClickHandler={onClickLoggedOut}
              color={StyleConstant.WHITE}
            />
            <button
              type="button"
              className="no_btn"
              onClick={(e) => onLogout(e, false)}
            >
              No, Stay Logged In
            </button>
          </div>
        </div>
      </LogoutWrapper>
    </CommonPopup>
  );
};
