class JobModel {
  constructor({
    id,
    accountId,
    orgId,
    title,
    jobTypeId,
    assessmentId,
    description,
    positions,
    postDate,
    deadlineDate,
    minExpYears,
    maxExpYears,
    status,
    salaryMax,
    salaryMin,
    template,
    deleted,
    createdBy,
    provider,
    visibility,
    preReqEducationLevel,
    premium,
  }) {
    this.id = id;
    this.accountId = accountId;
    this.orgId = orgId;
    this.title = title;
    this.jobTypeId = jobTypeId;
    this.assessmentId = assessmentId;
    this.description = description;
    this.positions = positions;
    this.postDate = postDate;
    this.deadlineDate = deadlineDate;
    this.minExpYears = minExpYears;
    this.maxExpYears = maxExpYears;
    this.status = status;
    this.salaryMax = salaryMax;
    this.salaryMin = salaryMin;
    this.template = template;
    this.deleted = deleted;
    this.createdBy = createdBy;
    this.provider = provider;
    this.visibility = visibility;
    this.preReqEducationLevel = preReqEducationLevel;
    this.premium = premium;
  }
}

export default JobModel;
