import Axios from "axios";
import showToastMsg from "../util/show-toasts";

//const Axios = AxiosRequest.create({})

class BaseService {
  constructor(baseUrl = "") {
    this.baseUrl = baseUrl;
  }

  #apiUrl = (url) => {
    return this.baseUrl + url;
  };

  #errorToastMsg = (err) => {
    showToastMsg(
      err &&
        err.response &&
        err.response.data &&
        err.response.data.error &&
        err.response.data.error.message
    );
  };

  #commonResponseMap = (
    previousData,
    updatedResponseData,
    extraParams = {}
  ) => {
    return {
      ...previousData,
      data: updatedResponseData,
      ...extraParams,
    };
  };

  transformResponse = (data, adapter, extraParams = {}) => {
    const previousDataParsed = JSON.parse(data);
    const response = adapter(previousDataParsed?.data || [], extraParams);
    return this.#commonResponseMap(previousDataParsed, response, extraParams);
  };

  getRequest = (url, config = {}) => {
    return new Promise((resolve, reject) => {
      Axios.get(this.#apiUrl(url), { ...config })
        .then((res) => resolve(res))
        .catch((err) => {
          if (!config.disableToast) {
            this.#errorToastMsg(err);
          }
          reject(err);
        });
    });
  };

  postRequest = (url, data, config = {}) => {
    return new Promise((resolve, reject) => {
      Axios.post(this.#apiUrl(url), data, { ...config })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          if (!config.disableToast) {
            this.#errorToastMsg(err);
          }
          reject(err);
        });
    });
  };

  putRequest = (url, obj, config = {}) => {
    return new Promise((resolve, reject) => {
      Axios.put(this.#apiUrl(url), obj, { ...config })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          if (!config.disableToast) {
            this.#errorToastMsg(err);
          }
          reject(err);
        });
    });
  };

  patchRequest = (url, obj, config = {}) => {
    return new Promise((resolve, reject) => {
      Axios.patch(this.#apiUrl(url), obj, { ...config })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          if (!config.disableToast) {
            this.#errorToastMsg(err);
          }
          reject(err);
        });
    });
  };

  deleteRequest = (url, config = {}) => {
    return new Promise((resolve, reject) => {
      Axios.delete(this.#apiUrl(url), { ...config })
      .then((res) => resolve(res))
      .catch((err) => {
        if (!config.disableToast) {
          this.#errorToastMsg(err);
        }
        reject(err);
      });
    });
  };
}

export default BaseService;
