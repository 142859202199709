const StyleConstant = {
  WHITE: '#ffffff',
  LIGHT_GREY: '#EFF0F5',
  LIGHT_GREY1: '#AFA7BD',
  GREY: '#8B8497',
  GREY1: '#282232',
  GREY2: '#D2CCDC',
  GREY3: '#F5F6FA',
  GREY4: '#DDDEE9',
  GREY5: '#D3DAE5',
  GREY6: '#f1f2f8',
  GREY7: '#D0CFD7',
  GREY8: '#dadada',
  GREY9: '#635972',
  DARK_GREY: '#5A5A5A',
  RED: '#F4624D',
  LIGHT_BLUE: '#649AD1',
  LIGHT_BLUE1: '#4BB9D8',
  TEAL: '#51D7A9',
  YELLOW: '#FFCA1F',
  GREEN: '#06904c',
  GREEN_LIGHT: 'rgba(6, 144, 76, 0.16)',
  SEPERATOR_GREY: '#cccccc',
  NUMBERS_PURPLE: '#724ab2',
  LIGHT_PURPLE: '#6d657e',
  LIGHT_GREEN: '#00cf69',
  YELLOW2: '#fa1',
  GREEN2: '#00263C',
  GREEN3: '#F2F8EB',
  GREEN4: '#005D83',
  GREEN6: '#009ADC',
  GREEN7: '#00263E',
  GREEN8: '#3ac430',
  BLUE1: '#17afec',
  BLUE2: '#3B95CB',
  BLUE3: '#364DED',
  BLUE4: '#F0F8FF',
  BLUE5: '#E6E6FA',
  PINK: '#E85697',
  BLACK: '#131313',
  MAIN_BACKGROUND: 'rgba(0, 154, 220, 0.05)',
  BORDER_GREY: '#e8e8e8',
  BLUE_GRADIENT: 'linear-gradient(270deg, #413896 0%, #53A0FD 100%)',
  BLUE_GRADIENT_1: 'linear-gradient(90deg, #003270 0%, #06357A 100%)',
  DARK_BLUE: '#003270',
  GREY10: '#838383',
  BLUE10: '#0078FF',
  BLUE_THEME: '#364DED',
  BLUE_THEME_LIGHT: 'rgba(54,77,237,0.1)',
  LIGHT_GREY_TEXT: '#676d8a',
  GREEN5: '#0098D6',
  GREEN1: '#3B95CB',
  TRANSPARENT: 'transparent',
  BG_GREY: '#f8f9fd',
  THEME_GRAY: '#6A6A86',
  PRIMARY: '#8031A7',
  PRIMARY_LIGHT: '#F0E3F7',
  SECONDARY: '#4AA571',
  SECONDARY_LIGHT: '#DCF3E6',
  TERTIARY: '#FAFCFE',
  DARK_TEXT: '#4A4A68',
  LIGHT_TEXT: '#8C8CA1',
};

Object.freeze(StyleConstant);

export default StyleConstant;
