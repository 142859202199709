import React from 'react';
import App from './App';
import { createRoot } from 'react-dom/client';
// import * as serviceWorker from "./serviceWorker";

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

// serviceWorker.unregister();
