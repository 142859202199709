const SEEKIFY_API = {
  JOB: {
    LIST: '/rest/admin/v1/m/job/filter',
    GET: '/rest/admin/v1/m/job',
    POST: '/rest/admin/v1/m/job/create',
    PUT: '/rest/admin/v1/m/job',
    STATUS: '/rest/admin/v1/m/job/update/status',
    ORG: '/rest/admin/v1/m/job/job-org',
  },
  JOB_TEMPLATE: {
    LIST: '/rest/admin/v1/job/template/fetch',
    GET: '/rest/admin/v1/job/template',
    POST: '/rest/admin/v1/job/template/create',
    PUT: '/rest/admin/v1/job/template',
    TEMPLATE_ROLE: '/rest/admin/v1/job/template/role',
  },
  CHALLENGE: {
    LIST: '/rest/admin/v1/m/challenge/fetch',
    GET: '/rest/admin/v1/m/challenge',
    POST: '/rest/admin/v1/m/challenge/create',
    PUT: '/rest/admin/v1/m/challenge/update',
    STATUS: '/rest/admin/v1/m/challenge',
    DECLARE_WINNERS: '/rest/admin/v1/m/challenge',
  },
  JOB_INDUSTRY: {
    GET: '/public/api/job/master/industry',
    POST: '/rest/admin/v1/job/master/industry/create',
    PUT: '/rest/admin/v1/job/master/industry',
  },
  JOB_FUNCTION: {
    GET: '/public/api/job/master/function',
    POST: '/rest/admin/v1/job/master/function/create',
    PUT: '/rest/admin/v1/job/master/function',
  },
  JOB_ROLE: {
    GET: '/public/api/job/master/role',
    POST: '/rest/admin/v1/job/master/role/create',
    PUT: '/rest/admin/v1/job/master/role',
  },
  ORGANISATION: {
    GET: '/rest/admin/v1/org/filter',
    GET_DETAIL: '/rest/admin/v1/org',
    POST: '/rest/admin/v1/on-board/create-org',
    PUT: '/rest/admin/v1/org',
  },
  USERS: {
    GET: '/rest/admin/v1/users',
  },
  JOB_CITY: {
    GET: '/public/api/job/master/city',
  },
  SKILLS: {
    GET: '/rest/admin/v1/skills',
  },
  TAGS: {
    GET: '/rest/admin/v1/tags/fetch',
  },
  SKILL_TAGS: {
    GET: '/rest/admin/v1/skills/filter',
  },
  ASSESSMENTS: {
    GET: '/rest/admin/v1/m/content/filter/fetch',
  },
  CONTENTS: {
    GET: '/rest/admin/v1/m/content/filter/fetch',
  },
  JOB_TYPE: {
    GET: '/public/api/job/master/type/fetch',
  },
  CANDIDATE: {
    LIST: '/rest/admin/v1/m/job/fetch/applicant',
    APPLICANT_STATUS: '/rest/admin/v1/m/job/application/status',
    BULK_REJECT: '/rest/admin/v1/m/job/bulk/status',
  },
  CITY: {
    AUTOCOMPLETE: '/rest/admin/v1/job/master/cities/city/autocomplete',
    GET: '/rest/admin/v1/job/master/cities/city',
  },
};

export default SEEKIFY_API;
