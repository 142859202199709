import services from "services";
import { B2B_API } from "./api-constants";
import { API_INFO, APP_STORAGE } from "./app-constants";
import UserState from "./UserState";

const qs = require("querystring");

export default class TokenStorage {
  static refreshAdminToken = async () => {
    const fd = new FormData();
    if (!TokenStorage.getItem(APP_STORAGE.ADMIN_REFRESH_TOKEN)) {
      return Promise.reject();
    }
    return new Promise((resolve, reject) => {
      fd.append("grant_type", "refresh_token");
      fd.append(
        "refresh_token",
        TokenStorage.getItem(APP_STORAGE.ADMIN_REFRESH_TOKEN)
      );
      services
        .postRequest(B2B_API.O_AUTH_TOKEN, fd, {
          info: API_INFO.LMS_USER_SERVICE,
          headers: {
            Authorization: `Basic ${process.env.REACT_APP_BASIC_AUTH}`,
          },
        })
        .then((res) => {
          UserState.saveAdminAuthTokenInfo(res.data);
          const token = TokenStorage.getItem(APP_STORAGE.ADMIN_ACCESS_TOKEN);
          resolve(token);
        })
        .catch((err) => {
          UserState.clearAdminTokenStorage();
          window.location.reload(true);
          reject(err);
        });
    });
  };

  static refreshCandidateToken = () => {
    const fd = new FormData();
    if (!TokenStorage.getItem(APP_STORAGE.CAND_REFRESH_TOKEN)) {
      return Promise.reject();
    }
    return new Promise((resolve, reject) => {
      fd.append("grant_type", "refresh_token");
      fd.append(
        "refresh_token",
        TokenStorage.getItem(APP_STORAGE.CAND_REFRESH_TOKEN)
      );
      services
        .postRequest(B2B_API.O_AUTH_TOKEN, fd, {
          info: API_INFO.LMS_USER_SERVICE,
          headers: {
            Authorization: `Basic ${process.env.REACT_APP_BASIC_AUTH ||
              process.env.NEXT_PUBLIC_BASIC_AUTH} `,
          },
        })
        .then((res) => {
          if (res && res.data) {
            UserState.saveUserAuthTokenInfo(res.data);
            const token = TokenStorage.getItem(APP_STORAGE.ACCESS_TOKEN);
            resolve(token);
          } else {
            UserState.clearCandidateTokenStorage();
            window.location.reload(true);
            reject(res);
          }
        })
        .catch((err) => {
          UserState.clearCandidateTokenStorage();
          window.location.reload(true);
          reject(err);
        });
    });
  };

  static setItem = (name, item) => {
    localStorage.setItem(name, item);
  };

  static setItems = (items = []) => {
    items.forEach((item) => {
      localStorage.setItem(item.name, item.value);
    });
  };

  static getItem = (itemName) => {
    try {
      return localStorage.getItem(itemName);
    } catch (e) {
      return null;
    }
  };

  static removeItem = (itemName) => {
    return localStorage.removeItem(itemName);
  };

  static getUserInfo = () => {
    try {
      return JSON.parse(localStorage.getItem(APP_STORAGE.USER_INFO));
    } catch (err) {
      return {};
    }
  };
  
  static getAdminUserInfo = () => {
    try {
      return JSON.parse(TokenStorage.getItem(APP_STORAGE.ADMIN_USER_INFO)) || {};
    } catch (err) {
      return {};
    }
  };

  static clear = () => {
    localStorage.clear();
  };
}
