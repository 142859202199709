import moment from 'moment';
import JobModel from './JobModel';
import SparkUserModel from './SparkUserModel';

class ApplicantModel {
  constructor({
    jobId,
    jobDto = {},
    userDTO = {},
    status,
    statusText,
    rejectionReason,
    rejectionReasonText,
    applicationDate,
    contentCompletionPer,
    preRequisite,
    matchedSkills = [],
    marksScored,
  }) {
    Object.assign(this, new SparkUserModel(userDTO), new JobModel(jobDto));
    this.jobId = jobId;
    this.jobDto = new JobModel(jobDto);
    this.userDTO = new SparkUserModel(userDTO);
    this.status = status;
    this.statusText = statusText;
    this.rejectionReason = rejectionReason;
    this.rejectionReasonText = rejectionReasonText;
    this.applicationDate = applicationDate;
    this.contentCompletionPer = contentCompletionPer;
    this.preRequisite = preRequisite;
    this.matchedSkills = matchedSkills;
    this.marksScored = marksScored;
    this.verifiedSkills = this.getVerifiedSkillCount();
    this.appliedOn = this.getAppliedOnText();
    this.verifiedSkillsText = this.getVerifiedSkillsText();
    this.assessmentScore = this.getAssessmentScoreText();
  }

  getVerifiedSkillCount = () => {
    return this.matchedSkills?.filter((skill) => {
      return skill.second === 1;
    }).length;
  };

  getAppliedOnText = () => {
    return moment(this.applicationDate).format('DD MMM, YYYY');
  };

  getVerifiedSkillsText = () => {
    return `${this.verifiedSkills}/${this.matchedSkills.length}`;
  };

  getAssessmentScoreText = () => {
    if (this.marksScored) {
      return `${this.marksScored}%`;
    } else {
      return '-';
    }
  };
}

export default ApplicantModel;
