import {
  ORGANISATION_LIST_REQUEST,
  ORGANISATION_LIST_SUCCESS,
  ORGANISATION_PROXY_LOGIN,
} from './action';

const initialState = {
  isFetching: false,
  isLoadingMore: false,
  organisationList: [],
  pageNumber: 1,
  pageSize: 20,
  totalItems: 0,
  totalPages: 0,
  didInvalidate: false,
  proxyLoginOrgData: {},
};

const reducer = (state = initialState, action) => {
  const { type, payload = {} } = action;
  switch (type) {
    case ORGANISATION_PROXY_LOGIN:
      return {
        ...state,
        proxyLoginOrgData: payload,
      };
    case ORGANISATION_LIST_REQUEST:
      return {
        ...state,
        params: action.params || {},
        isFetching: !action.isLoadingMore,
        isLoadingMore: action.isLoadingMore,
        didInvalidate: false,
      };
    case ORGANISATION_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isLoadingMore: action.isLoadingMore,
        organisationList: action.isLoadingMore
          ? state.organisationList.concat(action.payload.data || [])
          : payload.data || [],
        pageNumber: payload.pageNumber,
        pageSize: payload.pageSize,
        totalItems: payload.totalItems,
        totalPages: payload.totalPages,
        didInvalidate: false,
      };
    default:
      return state;
  }
};

export default reducer;
