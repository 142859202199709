import { SKILL_TYPE } from "../AdminLogin/adminActionTypes";

const initialState = { list: [], skillsLoading: false, hasError: false, popupLoader: false };

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  const newState = { ...state };
  switch (type) {
    case SKILL_TYPE.SET_SKILLS:
      return {
        ...newState,
        list: payload.data,
        skillsLoading: false,
        popupLoader: false,
      };
    case SKILL_TYPE.SKILLS_LOADING:
      return { ...newState, skillsLoading: true };
    case SKILL_TYPE.SKILLS_ERR:
      return { ...newState, hasError: true };
    case SKILL_TYPE.UPDATING_SKILL:
      return { ...newState, popupLoader: true };
    case SKILL_TYPE.UPDATE_SKILL: {
      const index = newState.list.findIndex(skill => skill.id === payload.id);
      let item = newState.list[index];
      item = {
        ...item,
        name: payload.value,
      };
      newState.list[index] = item;
      return { ...newState, popupLoader: false };
    }

    case "CLOSE_POPUP":
      return { ...newState, popupLoader: false };
    case SKILL_TYPE.ADDING_SKILL: {
      return {
        ...newState,
        popupLoader: true,
      };
    }
    case SKILL_TYPE.DELETE_SKILL: {
      // const filteredState = newState.list.filter(item => item.id !== payload);
      // return { ...newState, list: filteredState };
      // const filteredState = newState.list.filter(item => item.id !== payload);
      const index = newState.list.findIndex(skill => skill.id === payload);
      let item = newState.list[index];
      const { active } = item;
      item = {
        ...item,
        active: !active,
      };
      newState.list[index] = item;
      return { ...newState };
    }
    default:
      return { ...state };
  }
};

export default reducer;
