import services from './index';
import SEEKIFY_API from '../util/seekify-api-constants';
import { API_INFO } from '../util/app-constants';

class Organisation {
  fetch = (params = {}) => {
    const { name = '', pageNumber = 1, type = '' } = params;
    return services.getRequest(
      `${
        SEEKIFY_API.ORGANISATION.GET
      }?name=${name}&pageNumber=${pageNumber}${type && '&type=' + type}`,
      { info: API_INFO.LMS_USER_SERVICE }
    );
  };

  create = (data) => {
    return services.postRequest(`${SEEKIFY_API.ORGANISATION.POST}`, data, {
      info: API_INFO.LMS_USER_SERVICE,
    });
  };

  getOrgDetails = (orgId) => {
    return services.getRequest(
      `${SEEKIFY_API.ORGANISATION.GET_DETAIL}/${orgId}`,
      {
        info: API_INFO.LMS_AUTH,
      }
    );
  };

  update = (mergeOrgRequest) => {
    return services.patchRequest(
      `${SEEKIFY_API.ORGANISATION.PUT}`,
      mergeOrgRequest,
      {
        info: API_INFO.LMS_USER_SERVICE,
      }
    );
  };
}

export default new Organisation();
