import React from 'react';

import LogService from 'util/LogService';
import { LOGGING_CONS } from 'util/app-constants';
// import logger from "../../util/logger";
import showToastMsg from '../../util/show-toasts';
import { semverGreaterThan } from '../../CacheMaintainer';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {}

  componentDidCatch(error, info) {
    // logger.push({ error, info });
    LogService.log({
      name: 'ERROR_FROM_BOUNDRY',
      type: LOGGING_CONS.ERROR_TYPE,
      error,
      additionalInfo: info,
    });
    fetch('/meta.json')
      .then(response => response.json())
      .then(meta => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;

        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
        if (shouldForceRefresh) {
          if (caches) {
            caches.keys().then(async function(names) {
              await Promise.all(names.map(name => caches.delete(name)));
            });
          }
          window.location.reload(true);
        } else {
          this.setState({ hasError: true });
        }
      });
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      showToastMsg('Oops! Something went wrong', 'error', 2000);
    } else return this.props.children;
    return <div />;
  }
}

export default ErrorBoundary;
