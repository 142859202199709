import TokenStorage from "util/TokenStorage";
import services from "../../services";
import {B2B_API} from "../../util/api-constants";
import {API_INFO, APP_STORAGE, USER_ROLES} from "../../util/app-constants";
import CleverEvents from "../../util/cleverEvents";
import {getArrayMap, getDomainName, getHost, hasSubDomain} from "../../util/common";
import showToastMsg from "../../util/show-toasts";
import UserState from "../../util/UserState";
import UserLoginActionTypes from "./action-types";

export const toggleLoginPopup = (value) => (dispatch) => {
	dispatch({
		type: UserLoginActionTypes.TOGGLE_POPUP_LOGIN,
		payload: {value},
	});
};

export const loginUser = (value) => async (dispatch) => {
	dispatch({
		type: UserLoginActionTypes.TOGGLE_POPUP_LOGIN_STATE,
		payload: {value},
	});
};

export const saveUserDetails = (data) => (dispatch) => {
	return new Promise((resolve, reject) => {
		try {
			UserState.saveUserAuthTokenInfo(data);
			services.getUserInfo().then((res) => {
				const {data: userInfo} = (res && res.data) || {};
				if (data && Object.keys(userInfo).length > 0) {
					CleverEvents.saveUserProfile();
					UserState.saveUserInfo(userInfo);
					dispatch({
						type: UserLoginActionTypes.TOGGLE_STATE_USER_LOGGED_IN,
						payload: {...userInfo},
					});
					resolve(1);
				}
			});
		} catch (err) {
			reject(err);
		}
	});
};

export const logOutUser = () => (dispatch) => {
	UserState.clearCandidateTokenStorage();
	dispatch({type: UserLoginActionTypes.USER_LOGGED_OUT});
	window.location.reload(true);
};

export const logOutAdmin = () => (dispatch) => {
	UserState.clearAdminTokenStorage();
	dispatch({type: UserLoginActionTypes.ADMIN_LOGGED_OUT});
};

export const adminLoginCalls = (data) => async (dispatch) => {
	await UserState.saveAdminAuthTokenInfo(data);
	const whoAmIResponse = await services.getUserInfo();
	const {data: whoAmIData} = (whoAmIResponse && whoAmIResponse.data) || {};
	
	if (whoAmIData && Object.keys(whoAmIData).length > 0) {
		if (whoAmIData?.orgId !== 0) {
			const orgDetailResponse = await services.getOrgDetailsById(
					whoAmIData?.orgId
			);
			const {data: orgDetail = {}} = (orgDetailResponse && orgDetailResponse.data) || {};
			TokenStorage.setItem(APP_STORAGE.ORG_TYPE, orgDetail?.type);
		}
		const productPermissionsMap = getArrayMap(whoAmIData?.productPermissions['spark-hash']);
		const productRolesMap = getArrayMap(whoAmIData?.productRoles['spark-hash']);
		
		UserState.saveUserInfo({
			...whoAmIData,
			productRolesMap,
			productPermissionsMap,
		}, APP_STORAGE.ADMIN_USER_INFO);
		
		dispatch({
			type: UserLoginActionTypes.ADMIN_LOGGED_IN,
			payload: {
				...whoAmIData,
				productRolesMap,
				productPermissionsMap
			},
		});
		//window.location.reload();
	}
};

export const saveAdminLoginDetails = (userData) => async (dispatch) => {
	const {contact, password, otp} = userData || {};
	const formData = new FormData();
	formData.append("username", contact);
	formData.append("password", password);
	formData.append("grant_type", "password");
	formData.append("client_id", "web");
	const isSubDomain = hasSubDomain();
	const domain = getDomainName();
	if (isSubDomain && domain !== USER_ROLES.ADMIN) {
		formData.append("org_domain", getHost());
	}
	try {
		let response = null;
		if (otp) {
			response = await services.getAuthToken({loginId: contact, otp});
		} else {
			response = await services.postRequest(B2B_API.O_AUTH_TOKEN, formData, {
				info: API_INFO.LMS_USER_SERVICE,
				headers: {
					Authorization: `Basic ${process.env.REACT_APP_BASIC_AUTH}`,
					"account-id": 131,
				},
			});
		}
		await dispatch(adminLoginCalls((response && response.data) || {}));
	} catch (e) {
		const {status, data = {}} = e && e.response;
		const {error = {}} = data;
		const {message = {}} = error;
		if (status === 400) {
			showToastMsg("User not found", "error");
		} else if (status === 403) {
			showToastMsg("Incorrect Password", "error");
		} else {
			showToastMsg(message, "error");
		}
		throw e;
	}
};
