import userLogin from 'components/UserLogin/reducer';
import adminHome from 'pages/Admin/AdminHome/reducer';
import admin from 'pages/Admin/AdminLogin/reducer';
import liveSession from 'pages/Admin/LiveSessions/reducer';
import organizations from 'pages/Admin/Organizations/reducer';
import adminPayments from 'pages/Admin/Payments/reducer';
import skills from 'pages/Admin/Skills/reducer';
import navbar from 'pages/Common/NavBarComponent/reducer';
import dashboard from 'pages/Dashboard/reducer';
import videoScriptReducer from 'pages/Dashboard/UploadMyVideo/VideoScript/reducer';
import userJobs from 'pages/UserJobListing/redux/reducer';
import { combineReducers } from 'redux';
import filtersReducer from '../components/Filters/reducer';
import uploadProgress from '../components/UploadButton/reducer';
import userFieldsReducer from '../components/UserFields/reducer';
import adminUsers from '../pages/Admin/AdminUsers/reducer';
import organisationListReducer from '../pages/AdminV2/Organization/reducer';
import candidatesReducer from '../features/candidates/reducer';
import jobsReducer from '../pages/AdminV2/Recruiter/reducer';
import adminUserListReducer from '../pages/AdminV2/Users/reducer';

const rootReducer = combineReducers({
  dashboard,
  navbar,
  admin,
  skills,
  adminPayments,
  adminHome,
  videoScriptReducer,
  organizations,
  liveSession,
  userJobs,
  userLogin,
  adminUsers,
  userFields: userFieldsReducer,
  filters: filtersReducer,
  uploadProgress,
  jobsReducer,
  candidatesReducer,
  organisationListReducer,
  adminUserListReducer,
});

export default rootReducer;
