class LogService {
  static log = ({
    name = 'log',
    type = 'info',
    pageUrl = window.location.href,
    ...additionalInfo
  }) => {
    try {
      // const { userId } = CleverEvents.getUserInfo();
      // const obj = { name, type, pageUrl, ...additionalInfo };
      // if (userId) obj.userId = userId;
      // process.env.REACT_APP_ENV !== 'local' && logger[type](`${type}`, { ...obj });
    } catch (err) {}
  };
}

export default LogService;
