import React from 'react';
import Popup from 'reactjs-popup';
import Resources from 'assets/Resources';
import Divider from '@mui/material/Divider';
import StyledPopup from './style';

const CommonPopup = ({
  open,
  children,
  onClose,
  maxWidth,
  bgColor,
  maxHeight,
  popupTitle = '',
  height,
}) => {

  function onPopupClose() {
    onClose(false);
  }
  return (
    <StyledPopup
      id="common-popup-contnr"
      maxWidth={maxWidth}
      bgColor={bgColor}
      maxHeight={maxHeight}
      height={height}
    >
      <Popup className="common-popup" open={open} closeOnDocumentClick={false} lockScroll>
        <React.Fragment>
          <div>
            {popupTitle && (
              <>
                <div
                  style={{
                    paddingBottom: '10px',
                    fontWeight: 600,
                    fontSize: '18px',
                    color: '#22223B',
                    lineHeight: '20px,',
                  }}
                >
                  {popupTitle}
                </div>
                <Divider style={{ width: '100%', margin: '10px 0px' }} />
              </>
            )}
            <div className="cross-btn" onClick={onPopupClose}>
              <img src={Resources.images.testCross} alt="cross" />
            </div>
          </div>
          <div className="common-popup-container">{children}</div>
        </React.Fragment>
      </Popup>
    </StyledPopup>
  );
};
export default CommonPopup;
