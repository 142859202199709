import React from 'react';
import StyleConstant from 'util/style-constants';
import Loader from 'react-loader-spinner';
import PageLoaderWrapper from './style';

const PageLoader = ({ size = 50, pageHeight, color = `${StyleConstant.DARK_BLUE}` }) => {
  return (
    <PageLoaderWrapper pageHeight={pageHeight}>
      <Loader type="ThreeDots" color={color} height={size} width={size} />
    </PageLoaderWrapper>
  );
};

export default PageLoader;
