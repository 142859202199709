import Cookie from "js-cookie";
import { APP_STORAGE } from "./app-constants";
import TokenStorage from "./TokenStorage";

export default class UserState {
  static isUserLoggedIn = () => {
    return !!TokenStorage.getItem(APP_STORAGE.USER_LOGGED_IN);
  };

  static saveUserAuthTokenInfo = (data) => {
    if (typeof data === "object" && Object.keys(data).length > 0) {
      const accessToken = data.access_token;
      const refreshToken = data.refresh_token;
      const userHash = data["usr-hash"];
      const tokenExpiresIn = data.expires_in;
      TokenStorage.setItem(APP_STORAGE.ACCESS_TOKEN, accessToken);
      TokenStorage.setItem(APP_STORAGE.CAND_REFRESH_TOKEN, refreshToken);
      TokenStorage.setItem(APP_STORAGE.USER_LOGGED_IN, true);
      TokenStorage.setItem(APP_STORAGE.USER_HASH, userHash);
      TokenStorage.setItem(
        APP_STORAGE.USER_TOKEN_EXPIRE_TIME,
        Date.now() + tokenExpiresIn * 1000
      );
      Cookie.set(APP_STORAGE.ACCESS_TOKEN, accessToken);
      Cookie.set(APP_STORAGE.CAND_REFRESH_TOKEN, refreshToken);
      Cookie.set(APP_STORAGE.EXPIRE_TIME, Date.now() + tokenExpiresIn * 1000);
    }
  };

  static updateAccessToken = () => {
    const accessToken = TokenStorage.getItem(APP_STORAGE.ACCESS_TOKEN);
    const refreshToken = TokenStorage.getItem(APP_STORAGE.CAND_REFRESH_TOKEN);
    if (accessToken) {
      Cookie.set(APP_STORAGE.ACCESS_TOKEN, accessToken);
      Cookie.set(APP_STORAGE.CAND_REFRESH_TOKEN, refreshToken);
    }
  };

  static saveUserInfo = (userData, key = APP_STORAGE.USER_INFO) => {
    if (typeof userData === "object" && Object.keys(userData).length > 0) {
      TokenStorage.setItem(key, JSON.stringify(userData));
    }
  };

  static clearCandidateTokenStorage = () => {
    TokenStorage.removeItem(APP_STORAGE.ACCESS_TOKEN);
    TokenStorage.removeItem(APP_STORAGE.USER_INFO);
    TokenStorage.removeItem(APP_STORAGE.CAND_REFRESH_TOKEN);
    TokenStorage.removeItem(APP_STORAGE.USER_LOGGED_IN);
    TokenStorage.removeItem(APP_STORAGE.MICRO_CV_DATA);
    TokenStorage.removeItem(APP_STORAGE.USER_HASH);
    TokenStorage.removeItem(APP_STORAGE.USER_TOKEN_EXPIRE_TIME);
    TokenStorage.removeItem(APP_STORAGE.ORG_ID);
    TokenStorage.removeItem(APP_STORAGE.ORG_TYPE);
    Cookie.remove(APP_STORAGE.ACCESS_TOKEN);
    Cookie.remove(APP_STORAGE.CAND_REFRESH_TOKEN);
    Cookie.remove(APP_STORAGE.EXPIRE_TIME);
  };

  static saveAdminAuthTokenInfo = (data) => {
    if (typeof data === "object" && Object.keys(data).length > 0) {
      const accessToken = data.access_token;
      const refreshToken = data.refresh_token;
      const userHash = data["usr-hash"];
      const tokenExpiresIn = data.expires_in;
      const orgId = data && data["org-id"] ? data["org-id"] : 0;
      TokenStorage.setItem(APP_STORAGE.ADMIN_ACCESS_TOKEN, accessToken);
      TokenStorage.setItem(APP_STORAGE.ADMIN_REFRESH_TOKEN, refreshToken);
      TokenStorage.setItem(APP_STORAGE.ADMIN_USER_HASH, userHash);
      TokenStorage.setItem(
        APP_STORAGE.ADMIN_TOKEN_EXPIRE_TIME,
        Date.now() + tokenExpiresIn * 1000
      );
      TokenStorage.setItem(APP_STORAGE.ORG_ID, orgId);
    }
  };

  static clearAdminTokenStorage = () => {
    TokenStorage.removeItem(APP_STORAGE.ADMIN_ACCESS_TOKEN);
    TokenStorage.removeItem(APP_STORAGE.ADMIN_REFRESH_TOKEN);
    TokenStorage.removeItem(APP_STORAGE.ADMIN_USER_INFO);
    TokenStorage.removeItem(APP_STORAGE.ADMIN_USER_HASH);
    TokenStorage.removeItem(APP_STORAGE.ADMIN_TOKEN_EXPIRE_TIME);
    TokenStorage.removeItem(APP_STORAGE.ORG_ID);
    TokenStorage.removeItem(APP_STORAGE.ORG_TYPE);
  };
}
